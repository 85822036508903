@import '@telia-front/sass-tokens/index.scss';

a.arrow-list__link {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .arrow-list__item--hover & {
    &.anchor:hover {
      color: var(--color-neutral-text);
    }
  }

  .arrow-list__item--active & {
    font-weight: unset;

    &.anchor:hover {
      color: var(--color-neutral-text);
    }

    &.anchor.anchor--employee:hover {
      color: var(--color-employee-text-hover);
    }
  }

  .arrow-list__content > .arrow-list__arrow-icon {
    align-self: center;
  }
}

.arrow-list__icon {
  transition: color $transition-default;

  .arrow-list__item > .list__item-inner > & {
    font-size: 24px;
    margin-right: 0.625em;

    @media (max-width: $media-width-xs-max) {
      .arrow-list > .list--icon:not(.list--icon-position-middle) & {
        margin-top: 5px;
      }
    }

    @media (min-width: $media-width-sm-min) {
      align-self: center;
      font-size: 48px;
      margin-right: 0.3334em;
    }
  }

  .arrow-list__item--employee & {
    color: var(--color-employee-text);
  }

  .arrow-list:not(.arrow-list--with-icon) & {
    display: none;
  }

  html[data-whatintent='mouse'] .arrow-list__item:hover:not(.arrow-list__item--hover) > .list__item-inner > & {
    color: var(--color-brand-primary-text-hover);
  }

  html[data-whatintent='mouse'] .arrow-list__item--employee:hover:not(.arrow-list__item--hover) > .list__item-inner > & {
    color: var(--color-employee-text-hover);
  }

  html[data-whatintent='mouse'] .arrow-list__item--active:hover:not(.arrow-list__item--hover) > .list__item-inner > & {
    color: var(--color-neutral-text);
  }
}

.arrow-list__content {
  display: inline-flex;
  width: 100%;
  min-height: 19px;

  @media (min-width: $media-width-xl-min) {
    min-height: 29px;
  }
}

.arrow-list__inner {
  width: 100%;
  flex: 0 1 auto;
  align-self: center;
}

.list__item-inner {
  .arrow-list--large > .list--bordered > .list__inner > .arrow-list__item > & {
    padding: 15px;

    @media (min-width: $media-width-sm-min) {
      padding: 24px;
    }
  }

  .arrow-list--large.arrow-list--padding-small > .list--bordered > .list__inner > .arrow-list__item > & {
    padding: 15px;

    @media (min-width: $media-width-sm-min) {
      padding: 16px;
    }
  }
}

.list__item.arrow-list__item {
  position: relative;

  .list--bordered > .list__inner > & {
    margin-top: 0 !important;
    padding: 13.5px 0;

    .arrow-list--large & {
      padding: 0;
      border: none;
    }

    &:first-child {
      padding: 13px 0;

      .arrow-list--large & {
        padding: 0;
        border: none;
      }
    }
  }

  .arrow-list--large & {
    html[data-whatintent='mouse'] &.bg-neutral-bg-level-1.arrow-list__item--hover:hover {
      background-color: var(--color-neutral-bg-hover);
    }

    html[data-whatintent='mouse'] &.bg-brand-secondary-bg-subtlest.arrow-list__item--hover:hover {
      background-color: var(--color-brand-secondary-bg-subtlest-hover);
    }

    html[data-whatintent='mouse'] &.bg-neutral-bg-level-2.arrow-list__item--hover:hover {
      background-color: var(--color-neutral-bg-hover);
    }
  }

  .arrow-list--large:not(.arrow-list--with-heading) > .list--bordered > .list__inner > &:first-child {
    border-radius: $card-border-radius-medium $card-border-radius-medium 0 0;
  }

  .arrow-list--large > .list--bordered > .list__inner > &:last-child {
    border-radius: 0 0 $card-border-radius-medium $card-border-radius-medium;
  }

  .arrow-list--large.arrow-list--margin-tiny > .list--bordered > .list__inner > & {
    margin: 0 0 1px;
  }

  .arrow-list--large.arrow-list--margin-xsmall > .list--bordered > .list__inner > & {
    margin: 0 0 2px;
  }

  .arrow-list--large.arrow-list--margin-small > .list--bordered > .list__inner > & {
    margin: 0 0 4px;
  }

  .arrow-list--large.arrow-list--margin-medium > .list--bordered > .list__inner > & {
    margin: 0 0 8px;
  }
}
