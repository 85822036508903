@use 'sass:map';

@import '@telia-front/sass-tokens/index.scss';

.product-comparison-bar {
  left: 0;
  right: 0;
  z-index: map.get($zindex, product-comparison-bar);
  transition: top 500ms cubic-bezier(0.86, 0, 0.07, 1);

  &.has-scrolled {
    position: fixed;
    top: 0;
  }
}

.product-comparison-bar__card {
  border-radius: 0;
}

.product-comparison-bar__grid {
  max-width: 1224px;
}

.product-comparison-bar-anchor {
  display: block;
}

.product-comparison-bar__slot {
  &:not(.product-comparison-bar__buttons) {
    @media (max-width: $media-width-md-max) {
      max-width: 25%;
    }
  }

  @media (min-width: $media-width-lg-min) {
    max-width: 20%;
  }
}

.product-comparison-bar__buttons {
  align-items: center;
}

.product-comparison-bar__product-name {
  @media (max-width: $media-width-md-max) {
    @include visually-hidden();
  }
}

.product-comparison-bar__close-button {
  @media (max-width: $media-width-md-max) {
    top: -22px;
    left: -28px;
  }
}

.product-comparison-bar__product-image-container {
  @media (max-width: $media-width-md-max) {
    display: inline-flex;
    position: relative;
    min-height: 48px;
  }
}

.product-comparison-bar__background {
  display: none;

  .product-comparison-bar.has-scrolled ~ & {
    display: block;
  }
}

.grid__col.product-comparison-bar__product-image-wrapper {
  @media (max-width: $media-width-md-max) {
    margin-bottom: -5px;
  }
}

.grid.product-comparison-bar__content-wrapper {
  @media (max-width: $media-width-md-max) {
    margin: 10px -48px -11px 0;
  }
}
