.header__search {
  .search__textfield {
    --textfield-horizontal-padding: 16px;
  }
}

.header__search-suggestion-button {
  display: block;
  margin: 0 auto;
}

.textfield__input {
  .header__search & {
    border-radius: 24px;
    background-color: var(--color-neutral-bg-level-3);
  }
}

.header__search-suggestion.suggestion {
  border: none;
  border-radius: 8px;
  box-shadow: $elevation-5;
  padding-bottom: 27px;
  max-height: none;
}
