@import '@telia-front/sass-tokens/index.scss';

$scroll-fade-color: rgba(0, 0, 0, 1);
$scroll-fade-transparent: transparent;
$scroll-fade-left: #{$scroll-fade-color} 40px;
$scroll-fade-right: #{$scroll-fade-color} calc(40px + var(--scroll-fade-right-scrollbar-offset));
$scroll-fade-arrow-left:
  #{$scroll-fade-transparent} 24px,
  #{$scroll-fade-color} 48px;
$scroll-fade-arrow-right:
  #{$scroll-fade-transparent} calc(24px + var(--scroll-fade-right-scrollbar-offset)),
  #{$scroll-fade-color} calc(48px + var(--scroll-fade-right-scrollbar-offset));

.scroll-fade {
  --scroll-fade-scrollbar-width: 0px;
  --scroll-fade-scrollbar-height: 0px;
  --scroll-fade-bottom-scrollbar-offset: 0px;
  --scroll-fade-right-scrollbar-offset: 0px;
  --scroll-fade-top: #{$scroll-fade-color};
  --scroll-fade-bottom: #{$scroll-fade-color};
  --scroll-fade-left: #{$scroll-fade-color};
  --scroll-fade-right: #{$scroll-fade-color};
  --scroll-fade-arrow-left: #{$scroll-fade-left};
  --scroll-fade-arrow-right: #{$scroll-fade-right};

  display: block;
  position: relative;
  max-height: inherit;

  &.has-scrolled-to-top {
    --scroll-fade-right-scrollbar-offset: var(--scroll-fade-scrollbar-width);
    --scroll-fade-bottom: transparent;
  }

  &.has-scrolled-to-right {
    --scroll-fade-bottom-scrollbar-offset: var(--scroll-fade-scrollbar-height);
    --scroll-fade-left: transparent;

    &.scroll-fade--has-arrows:not(.has-scrolled-to-top, .has-scrolled-to-bottom) {
      --scroll-fade-arrow-left: #{$scroll-fade-arrow-left};
    }
  }

  &.has-scrolled-to-bottom {
    --scroll-fade-right-scrollbar-offset: var(--scroll-fade-scrollbar-width);
    --scroll-fade-top: transparent;
  }

  &.has-scrolled-to-left {
    --scroll-fade-bottom-scrollbar-offset: var(--scroll-fade-scrollbar-height);
    --scroll-fade-right: transparent;

    &.scroll-fade--has-arrows:not(.has-scrolled-to-top, .has-scrolled-to-bottom) {
      --scroll-fade-arrow-right: #{$scroll-fade-arrow-right};
    }
  }

  &.scroll-fade--has-arrows.scroll-fade--gutter-md {
    &.has-scrolled-to-right {
      --scroll-fade-arrow-left: #{$scroll-fade-transparent} 30px, #{$scroll-fade-color} 30%;
    }

    &.has-scrolled-to-left {
      --scroll-fade-arrow-right: #{$scroll-fade-transparent} calc(30px + var(--scroll-fade-right-scrollbar-offset)),
        #{$scroll-fade-color} calc(30% + var(--scroll-fade-right-scrollbar-offset));
    }
  }
}

.scroll-fade__inner {
  display: block;
  max-height: inherit;
  overflow: auto;
  background-color: inherit;
  mask-image:
    linear-gradient(to left, $scroll-fade-color var(--scroll-fade-right-scrollbar-offset), transparent var(--scroll-fade-right-scrollbar-offset)),
    // left scrollbar
    linear-gradient(to top, $scroll-fade-color var(--scroll-fade-bottom-scrollbar-offset), transparent var(--scroll-fade-bottom-scrollbar-offset)),
    // bottom scrollbar
    linear-gradient(to left, var(--scroll-fade-right) var(--scroll-fade-right-scrollbar-offset), var(--scroll-fade-arrow-right)),
    // right gradient
    linear-gradient(to right, var(--scroll-fade-left), var(--scroll-fade-arrow-left)),
    // left gradient
    linear-gradient(
        to top,
        var(--scroll-fade-bottom) var(--scroll-fade-bottom-scrollbar-offset),
        #{$scroll-fade-color} calc(40px + var(--scroll-fade-bottom-scrollbar-offset))
      ),
    // bottom gradient
    linear-gradient(to bottom, var(--scroll-fade-top), #{$scroll-fade-color} 40px),
    // top gradient
;
  mask-size:
    100% 100%,
    100% 100%,
    100% calc(100% - var(--scroll-fade-bottom-scrollbar-offset)),
    100% calc(100% - var(--scroll-fade-bottom-scrollbar-offset)),
    calc(100% - var(--scroll-fade-right-scrollbar-offset)) 100%,
    calc(100% - var(--scroll-fade-right-scrollbar-offset)) 100%;
  mask-repeat: no-repeat;
  // Must be set the amount of times we have different gradients in mask, otherwise older webkit browsers will not render it due to how autoprefixer translates it to -webkit-mask-composite.
  mask-composite: add, add, intersect, intersect, intersect, intersect;
}

.scroll-fade__button {
  position: absolute;
  z-index: 2;

  &.scroll-fade__button-left,
  &.scroll-fade__button-right {
    top: calc((100% - var(--scroll-fade-bottom-scrollbar-offset)) / 2);
    transform: translateY(-50%);
  }

  &.scroll-fade__button-left {
    left: 0;

    .scroll-fade--gutter-md & {
      left: 10px;
    }
  }

  &.scroll-fade__button-right {
    right: var(--scroll-fade-right-scrollbar-offset);

    .scroll-fade--gutter-md & {
      right: calc(10px + var(--scroll-fade-right-scrollbar-offset));
    }
  }

  &.scroll-fade__button-down {
    right: var(--scroll-fade-right-scrollbar-offset);
    bottom: var(--scroll-fade-bottom-scrollbar-offset);
  }

  &.scroll-fade__button-up {
    right: var(--scroll-fade-right-scrollbar-offset);
    top: 0;
  }
}

.scroll-fade__content {
  .scroll-fade.has-scrolled-to-bottom &,
  .scroll-fade.has-scrolled-to-top & {
    padding-right: 10px;
    display: block;
  }

  .scroll-fade.scroll-fade--has-arrows.has-scrolled-to-bottom &,
  .scroll-fade.scroll-fade--has-arrows.has-scrolled-to-top & {
    padding-right: 14px;
    display: block;
  }
}
