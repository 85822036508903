@import '@telia-front/sass-tokens/index.scss';

.progress-bar {
  font-weight: 700;
}

.progress-bar__title {
  margin-bottom: 10px;
}

.progress-bar__inner {
  display: flex;
  gap: 16px;
  align-items: center;
}

.progress-bar__bar-wrapper {
  position: relative;
  height: 2.5rem;
  width: 100%;
  border-radius: 999px;
  overflow: hidden;
}

.progress-bar__bar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0 20px;
  line-height: 2.5rem;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
}

.progress-bar__bar--content {
  right: 0;
  background: var(--color-neutral-bg-subtle);
  color: var(--color-success-text);
  transition: color $transition-default;

  .progress-bar--fill-medium & {
    color: var(--color-info-text);
  }

  .progress-bar--fill-high & {
    color: var(--color-error-text);
  }

  .progress-bar--type-order & {
    color: var(--color-success-text);
  }
}

.progress-bar__bar--overlay {
  background: var(--color-success-bg-strong);
  color: var(--color-success-text-onbg-strong);
  transition: background-color $transition-default;

  .progress-bar--fill-medium & {
    background: var(--color-info-bg-strong);
    color: var(--color-info-text-onbg-strong);
  }

  .progress-bar--fill-high & {
    background: var(--color-error-bg-strong);
    color: var(--color-error-text-onbg-strong);
  }

  .progress-bar--type-order & {
    background: var(--color-success-bg-strong);
    color: var(--color-success-text-onbg-strong);
  }
}

.progress-bar__button {
  flex-shrink: 0;
}
