@import '@telia-front/sass-tokens/index.scss';

$pagination-link-size-mobile: 42px;
$pagination-link-size-mobile-tooltip: 30px;

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: var(--type-tiny-font-size);
  line-height: var(--type-tiny-line-height);

  @media (min-width: $media-width-sm-min) {
    padding: 0 4px;
  }

  &.is-tooltip {
    display: block;
    max-height: 150px;
  }
}

.pagination--left {
  justify-content: flex-start;
}

.pagination--right {
  justify-content: flex-end;
}

.pagination__item {
  @include transition(
    (
      'animation': short,
      'properties': all,
    )
  );

  @media (min-width: $media-width-sm-min) {
    margin: 4px;
  }

  .pagination > & {
    display: none;

    @media (min-width: $media-width-sm-min) {
      display: block;
    }
  }
}

.pagination__prev,
.pagination__next {
  @media (min-width: $media-width-sm-min) {
    margin: 4px;
  }

  &.is-disabled {
    pointer-events: none;

    @media (min-width: $media-width-sm-min) {
      opacity: 0;
    }
  }
}

.pagination__link,
.pagination__break {
  @include reset-button();
  @include transition(
    (
      'animation': short,
      'properties': all,
    )
  );

  display: block;
  padding: 9px;
  background: var(--color-neutral-bg-level-2);
  color: var(--color-neutral-text);
  font-weight: 700;
  text-align: center;

  @media (min-width: $media-width-sm-min) {
    padding: 5px;
    min-width: 1.625rem;
    border-radius: 999px;
    font-weight: 400;
    border: 1px solid transparent;
  }

  .pagination--inverse & {
    background: var(--color-neutral-bg-level-1);
  }

  .tooltip__content & {
    background: transparent;
  }
}

.pagination__link {
  cursor: pointer;

  html[data-whatintent='mouse'] &:hover {
    @media (min-width: $media-width-sm-min) {
      border-color: var(--color-brand-primary-border);
      color: var(--color-brand-primary-text);
    }
  }

  html[data-whatintent='mouse'] .pagination--employee & {
    &:hover {
      color: var(--color-employee-text);

      @media (min-width: $media-width-sm-min) {
        border-color: var(--color-employee-border);
        color: var(--color-employee-text);
      }
    }
  }

  .pagination__item.is-active & {
    color: var(--color-brand-primary-text);

    @media (min-width: $media-width-sm-min) {
      background: var(--color-brand-primary-bg-strong);
      color: var(--color-brand-primary-text-onbg-strong);
      font-weight: 700;
    }

    .pagination--employee & {
      color: var(--color-employee-text);

      @media (min-width: $media-width-sm-min) {
        background: var(--color-employee-bg-strong);
        color: var(--color-employee-text-onbg-strong);
      }
    }

    html[data-whatintent='mouse'] &:hover {
      @media (min-width: $media-width-sm-min) {
        background: var(--color-brand-primary-bg-strong);
        color: var(--color-brand-primary-text-onbg-strong);
      }
    }
  }

  html[data-whatintent='mouse'] .pagination--employee .pagination__item.is-active & {
    &:hover {
      @media (min-width: $media-width-sm-min) {
        background: var(--color-employee-bg-strong);
        color: var(--color-employee-text-onbg-strong);
      }
    }
  }

  .pagination__prev & {
    border-radius: 999px 0 0 999px;

    @media (max-width: $media-width-xs-max) {
      border-right: 1px solid var(--color-neutral-bg-level-1);
    }

    @media (min-width: $media-width-sm-min) {
      border-radius: 999px;
      padding: 4px;
    }
  }

  .pagination--inverse .pagination__prev & {
    @media (max-width: $media-width-xs-max) {
      border-right-color: var(--color-neutral-bg-level-2);
    }
  }

  .pagination__next & {
    border-radius: 0 999px 999px 0;

    @media (max-width: $media-width-xs-max) {
      border-left: 1px solid var(--color-neutral-bg-level-1);
    }

    @media (min-width: $media-width-sm-min) {
      border-radius: 999px;
      padding: 4px;
    }
  }

  .pagination--inverse .pagination__next & {
    @media (max-width: $media-width-xs-max) {
      border-left-color: var(--color-neutral-bg-level-2);
    }
  }
}

.pagination__status {
  display: block;
  min-width: 6.25rem;
  margin: 0;
  border-radius: 0;
  background: var(--color-neutral-bg-level-2);
  color: var(--color-neutral-text);
  font-weight: 700;
  text-align: center;

  @media (min-width: $media-width-sm-min) {
    display: none;
  }

  .pagination--inverse & {
    background: var(--color-neutral-bg-level-1);
  }
}

.pagination__status-button {
  @include reset-button();

  width: 100%;
  height: 100%;
}

.pagination__icon {
  color: var(--color-brand-primary-text);
  font-size: 1.5rem;
  vertical-align: middle;

  @media (min-width: $media-width-sm-min) {
    font-size: 1rem;
    vertical-align: top;
  }

  .pagination--employee & {
    color: var(--color-employee-text);
  }

  .pagination__prev.is-disabled &,
  .pagination__next.is-disabled & {
    color: var(--color-disabled-text);
  }
}

.pagination__tooltip {
  margin: 0;
  padding: 7px 0;
  list-style: none;
}
