@import '@telia-front/sass-tokens/index.scss';

.pagination-number {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: var(--type-small-font-size);
  line-height: var(--type-small-line-height);
  font-weight: 400;
  white-space: nowrap;

  @media (min-width: $media-width-sm-min) {
    font-size: var(--type-tiny-font-size);
    line-height: var(--type-tiny-line-height);
  }
}

.pagination-number--left {
  justify-content: flex-start;
}

.pagination-number--right {
  justify-content: flex-end;
}

.pagination-number__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  margin: 0;
  padding: 0;
  list-style: none;
}

.pagination-number__text,
.pagination-number__item {
  margin: 10px;

  @media (min-width: $media-width-sm-min) {
    margin: 4px;
  }
}

.pagination-number__link {
  position: relative;
  display: block;
  border: 1px solid transparent;
  border-radius: 999px;
  background: var(--color-neutral-bg-level-1);
  color: var(--color-neutral-text);
  font-weight: 400;
  text-align: center;
  padding: 10px 8px;
  min-width: 2.625rem;

  @include transition(
    (
      'animation': short,
      'properties': (
        color,
        border-color,
        background-color,
      ),
    )
  );

  @media (min-width: $media-width-sm-min) {
    padding: 4px 5px;
    min-width: 1.625rem;
  }

  .pagination-number--light & {
    background-color: var(--color-neutral-bg-level-2);
  }

  html[data-whatintent='mouse'] &:hover {
    border-color: var(--color-brand-primary-border);
    color: var(--color-brand-primary-text);
  }

  .pagination-number__item.is-active & {
    border-color: var(--color-brand-primary-bg-strong);
    background-color: var(--color-brand-primary-bg-strong);
    color: var(--color-brand-primary-text-onbg-strong);
    font-weight: 700;
  }

  html[data-whatintent='mouse'] .pagination-number__item.is-active & {
    &:hover {
      border-color: var(--color-brand-primary-bg-strong);
      background-color: var(--color-brand-primary-bg-strong);
      color: var(--color-brand-primary-text-onbg-strong);
    }
  }
}
