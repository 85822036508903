@import '@telia-front/sass-tokens/index.scss';

/**
 * General helpers
 */

body {
  display: block;
  overflow-y: scroll;
}

/* stylelint-disable-next-line selector-max-id */
#page-container {
  z-index: map_get($zindex, page-container);

  /* stylelint-disable-next-line selector-max-id */
  body.is-scroll-disabled & {
    overflow-y: hidden;
    position: fixed;
    left: 0;
    width: 100%;
  }
}

// Screen reader

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

/**
 * Positioning & alignment
 */

.clearfix {
  @include clear-fixer();
}

.position-relative {
  position: relative;
}

/**
 * Visibility helpers
 */

.hidden {
  display: none !important;
}

.visible {
  display: block !important;
}

.hidden-xs {
  @media (max-width: $media-width-xs-max) {
    display: none !important;
  }
}

.hidden-sm {
  @media (min-width: $media-width-sm-min) and (max-width: $media-width-sm-max) {
    display: none !important;
  }
}

.hidden-md {
  @media (min-width: $media-width-md-min) and (max-width: $media-width-md-max) {
    display: none !important;
  }
}

.hidden-lg {
  @media (min-width: $media-width-lg-min) and (max-width: $media-width-lg-max) {
    display: none !important;
  }
}

.hidden-xl {
  @media (min-width: $media-width-xl-min) {
    display: none !important;
  }
}

/**
 * Printing helpers
 */

@media print {
  .hidden-print {
    display: none !important;
  }
}

.visible-print {
  @media print {
    display: block !important;
  }
}

.visible-only-print {
  display: none;

  @media print {
    display: block !important;
  }
}
