@import '@telia-front/sass-tokens/index.scss';

.status {
  display: flex;
  align-items: baseline;
  font-size: var(--type-small-font-size);
  line-height: var(--type-small-line-height);
}

.status__circle {
  flex-shrink: 0;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 999px;
  margin-right: 8px;

  .status--error & {
    background-color: var(--color-error-graphic);
  }

  .status--repair & {
    background-color: var(--color-info-graphic);
  }

  .status--attention & {
    background-color: var(--color-attention-graphic);
  }

  .status--success & {
    background-color: var(--color-success-graphic);
  }

  .status--inactive & {
    background-color: var(--color-disabled-text);
  }
}
