@use 'sass:math';

@import '@telia-front/sass-tokens/index.scss';

$grid-gutter-small: 8px;
$grid-gutter-smedium: 16px;
$grid-gutter-medium: 48px;
$grid-gutter-medium-xs: 32px;
$grid-gutter-xs: 16px;
$grid-gutter-xsmall: 4px;

@mixin make-grid($name) {
  @for $i from 1 through $grid-columns {
    .grid__col--#{$name}-#{$i} {
      order: auto;
      flex-basis: math.div(100%, $grid-columns) * $i;
      max-width: math.div(100%, $grid-columns) * $i;
    }
  }

  @for $i from 0 through $grid-columns {
    .grid__col--offset-#{$name}-#{$i} {
      margin-left: math.div(100%, $grid-columns) * $i;
    }
  }

  .grid__col--#{$name} {
    flex-grow: 0;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }

  .grid--no-wrap-#{$name} {
    flex-wrap: nowrap;
  }

  .grid--wrap-#{$name} {
    flex-wrap: wrap;
  }

  .grid__col--min-#{$name} {
    flex-grow: 0;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }

  .grid__col--max-#{$name} {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }

  .grid__col--minmax-#{$name} {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    width: auto;
  }

  .grid__col--top-#{$name} {
    align-self: flex-start;
  }

  .grid__col--middle-#{$name} {
    align-self: center;
  }

  .grid__col--bottom-#{$name} {
    align-self: flex-end;
  }

  .grid--reverse-#{$name} {
    flex-direction: row-reverse;
  }

  .grid--start-#{$name} {
    justify-content: flex-start;
  }

  .grid--center-#{$name} {
    justify-content: center;
  }

  .grid--end-#{$name} {
    justify-content: flex-end;
  }

  .grid--top-#{$name} {
    align-items: flex-start;
  }

  .grid--middle-#{$name} {
    align-items: center;
  }

  .grid--bottom-#{$name} {
    align-items: flex-end;
  }

  .grid--around-#{$name} {
    justify-content: space-around;
  }

  .grid--between-#{$name} {
    justify-content: space-between;
  }

  .grid__col--original-#{$name} {
    order: 0;
  }

  .grid__col--first-#{$name} {
    order: -1;
  }

  .grid__col--last-#{$name} {
    order: 1;
  }

  .grid__col--border-none-#{$name} {
    &::before,
    &::after {
      display: none;
    }
  }

  .grid__col--border-bottom-none-#{$name} {
    &::after {
      display: none;
    }
  }

  .grid__col--border-bottom-#{$name} {
    &::after {
      display: block;
    }
  }

  .grid__col--border-left-none-#{$name} {
    &::before {
      display: none;
    }
  }

  .grid__col--border-left-#{$name} {
    &::before {
      display: block;
    }
  }
}

@mixin grid-gutter($name, $gutter-size, $gutter-xs-size: null) {
  .grid--gutter-#{$name} {
    margin-right: $gutter-size * 0.5 * -1;
    margin-left: $gutter-size * 0.5 * -1;
    margin-bottom: $gutter-size * -1;

    @if $gutter-xs-size {
      @media (max-width: $media-width-xs-max) {
        margin-right: $gutter-xs-size * 0.5 * -1;
        margin-left: $gutter-xs-size * 0.5 * -1;
        margin-bottom: $gutter-xs-size * -1;
      }
    }
  }

  .grid.grid--horizontal-gutter-#{$name} {
    margin-right: $gutter-size * 0.5 * -1;
    margin-left: $gutter-size * 0.5 * -1;

    @if $gutter-xs-size {
      @media (max-width: $media-width-xs-max) {
        margin-right: $gutter-xs-size * 0.5 * -1;
        margin-left: $gutter-xs-size * 0.5 * -1;
      }
    }
  }

  .grid.grid--vertical-gutter-#{$name} {
    margin-bottom: $gutter-size * -1;

    @if $gutter-xs-size {
      @media (max-width: $media-width-xs-max) {
        margin-bottom: $gutter-xs-size * -1;
      }
    }
  }

  .grid__col {
    .grid--gutter-#{$name} > & {
      padding-right: $gutter-size * 0.5;
      padding-left: $gutter-size * 0.5;
      margin-bottom: $gutter-size;

      @if $gutter-xs-size {
        @media (max-width: $media-width-xs-max) {
          padding-right: $gutter-xs-size * 0.5;
          padding-left: $gutter-xs-size * 0.5;
          margin-bottom: $gutter-xs-size;
        }
      }
    }

    .grid.grid--horizontal-gutter-#{$name} > & {
      padding-right: $gutter-size * 0.5;
      padding-left: $gutter-size * 0.5;

      @if $gutter-xs-size {
        @media (max-width: $media-width-xs-max) {
          padding-right: $gutter-xs-size * 0.5;
          padding-left: $gutter-xs-size * 0.5;
        }
      }
    }

    .grid.grid--vertical-gutter-#{$name} > & {
      margin-bottom: $gutter-size;

      @if $gutter-xs-size {
        @media (max-width: $media-width-xs-max) {
          margin-bottom: $gutter-xs-size;
        }
      }
    }

    &::after {
      .grid--gutter-#{$name} > & {
        bottom: $gutter-size * 0.5 * -1;
        left: $gutter-size * 0.5;
        right: $gutter-size * 0.5;

        @if $gutter-xs-size {
          @media (max-width: $media-width-xs-max) {
            bottom: $gutter-xs-size * 0.5 * -1;
            left: $gutter-xs-size * 0.5;
            right: $gutter-xs-size * 0.5;
          }
        }
      }

      .grid.grid--horizontal-gutter-#{$name} > & {
        left: $gutter-size * 0.5;
        right: $gutter-size * 0.5;

        @if $gutter-xs-size {
          @media (max-width: $media-width-xs-max) {
            left: $gutter-xs-size * 0.5;
            right: $gutter-xs-size * 0.5;
          }
        }
      }

      .grid.grid--vertical-gutter-#{$name} > & {
        bottom: $gutter-size * 0.5 * -1;

        @if $gutter-xs-size {
          @media (max-width: $media-width-xs-max) {
            bottom: $gutter-xs-size * 0.5 * -1;
          }
        }
      }
    }
  }
}

.grid {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  // reset padding when used as ul/ol
  padding-left: 0;
  // fixes Truncate usecases
  min-width: 0;

  @media print {
    display: block;
  }
}

@include grid-gutter(default, $grid-gutter, $grid-gutter-xs);
@include grid-gutter(xsmall, $grid-gutter-xsmall);
@include grid-gutter(small, $grid-gutter-small);
@include grid-gutter(medium, $grid-gutter-medium, $grid-gutter-medium-xs);
@include grid-gutter(smedium, $grid-gutter-smedium);
@include grid-gutter(none, 0);

.grid__col {
  position: relative;
  display: inline-block;
  flex: 0 0 auto;
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
  outline: none;

  .grid--equalheight > & {
    display: flex;
  }

  @media print {
    display: block;
  }

  &::before,
  &::after {
    content: '';
    display: none;
    position: absolute;
    background: var(--color-neutral-border-subtle);

    .grid--border-light > & {
      background-color: var(--color-neutral-border-subtlest);
    }

    .grid--border-dark > & {
      background-color: var(--color-neutral-border);
    }

    .grid--border-on-dark > & {
      background-color: var(--color-neutral-border-subtle);
    }
  }

  &::after {
    height: 2px;

    .grid--border-thin > & {
      height: 1px;
    }
  }

  &::before {
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;

    .grid--border-thin > & {
      width: 1px;
    }
  }
}

.grid > .grid__col--no-vertical-gutter {
  margin-bottom: 0;
}

.grid__col--min {
  flex-grow: 0;
  flex-basis: auto;
  width: auto;
  max-width: 100%;
}

.grid__col--max {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.grid__col--minmax {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  width: auto;
}

@include make-grid(xs);

@media (min-width: $media-width-sm-min) {
  @include make-grid(sm);
}

@media (min-width: $media-width-md-min) {
  @include make-grid(md);
}

@media (min-width: $media-width-lg-min) {
  @include make-grid(lg);
}

@media (min-width: $media-width-xl-min) {
  @include make-grid(xl);
}

@media (min-width: $media-width-xxl-min) {
  @include make-grid(xxl);
}
