@import '@telia-front/sass-tokens/index.scss';

.selector {
  --selector-indicator-font-size: var(--type-font-size);
  --selector-indicator-line-height: var(--type-line-height);
  --selector-border-color: var(--color-neutral-border);
  --selector-box-shaddow: none;
  --selector-color: var(--color-neutral-text);
  --selector-background-color: transparent;
  --selector-background-color-after: var(--color-neutral-text-subtle);
  --selector-padding: 10px 16px;
  --selector-min-height: 48px;
  --selector-width: 100%;
  width: 100%;
}

.selector__list {
  font-size: var(--type-font-size);
  line-height: var(--type-line-height);
  font-weight: 700;
}

.selector__label {
  width: 100%;
  margin-bottom: 8px;

  .selector--hide-label & {
    @include visually-hidden();
  }

  .selector--center & {
    text-align: center;
  }

  .selector--right & {
    text-align: right;
  }

  .selector--small:not(.selector--custom-label-size) & {
    font-size: var(--type-tiny-font-size);
    line-height: var(--type-tiny-line-height);
  }
}

.selector__item {
  position: relative;
  flex: 0 1 auto;

  &.selector__item--custom-color {
    border-radius: 5px;
  }
}

.selector__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

.selector__indicator {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--selector-padding);
  cursor: pointer;
  border-radius: 4px;
  background: transparent;
  border: 1px solid var(--color-neutral-border);
  text-align: center; // for multiline labels
  transition: 100ms ease-out;
  transition-property: box-shadow, background-color, border-color, color;
  width: var(--selector-width);
  min-height: var(--selector-min-height);
  font-size: var(--selector-indicator-font-size);
  line-height: var(--selector-indicator-line-height);
  border-color: var(--selector-border-color);
  box-shadow: var(--selector-box-shaddow);
  color: var(--selector-color);
  background-color: var(--selector-background-color);

  html[data-whatintent='mouse'] .selector__input:hover ~ & {
    --selector-border-color: var(--color-brand-primary-border);
    --selector-box-shaddow: inset 0 0 0 1px var(--color-brand-primary-border);
  }

  .selector__input:focus-visible ~ & {
    @include keyboard-focus();
  }

  html[data-whatintent='mouse'] .selector__item--employee .selector__input:hover ~ & {
    --selector-border-color: var(--color-employee-border);
    --selector-box-shaddow: inset 0 0 0 1px var(--color-employee-border);
  }

  .selector__item--inactive & .selector__item-label-strikethrough,
  .selector__item--inactive & .selector__input:disabled .selector__item-label-strikethrough {
    --selector-color: var(--color-neutral-text);
  }

  .selector__input:checked ~ & {
    --selector-border-color: var(--color-brand-primary-border);
    --selector-color: var(--color-brand-primary-text-onbg-strong);
    --selector-background-color: var(--color-brand-primary-bg-strong);

    .selector__item--inactive & .selector__item-label-strikethrough {
      --selector-color: var(--color-brand-primary-text-onbg-strong);
    }

    .selector__item--inactive.selector__item--employee & .selector__item-label-strikethrough {
      --selector-color: var(--color-employee-text-onbg-strong);
    }

    .selector__item--employee & {
      --selector-border-color: var(--color-employee-border);
      --selector-color: var(--color-employee-text-onbg-strong);
      --selector-background-color: var(--color-employee-bg-strong);
    }
  }

  html[data-whatintent='mouse'] .selector__input:checked:hover ~ & {
    --selector-border-color: var(--color-brand-primary-border);
    --selector-box-shaddow: none;
    --selector-color: var(--color-brand-primary-text-onbg-strong);
    --selector-background-color: var(--color-brand-primary-bg-strong-hover);
  }

  .selector__input:disabled ~ & {
    --selector-border-color: var(--color-disabled-border);
    --selector-color: var(--color-disabled-text);
    --selector-background-color: transparent;
    cursor: default;
  }

  .selector__input:disabled:checked ~ & {
    --selector-border-color: var(--color-disabled-border);
    --selector-color: var(--color-disabled-text);
    --selector-background-color: var(--color-disabled-bg);
  }

  html[data-whatintent='mouse'] .selector__input:disabled:hover ~ & {
    --selector-border-color: var(--color-disabled-border);
    --selector-box-shaddow: none;
  }

  html[data-whatintent='mouse'] .selector__input:disabled:checked:hover ~ & {
    --selector-border-color: var(--color-disabled-border);
    --selector-color: var(--color-disabled-text);
    --selector-background-color: var(--color-disabled-bg);
  }

  html[data-whatintent='mouse'] .selector__item--employee .selector__input:checked:enabled:hover ~ & {
    --selector-border-color: var(--color-employee-border);
    --selector-color: var(--color-employee-text-onbg-strong);
    --selector-background-color: var(--color-employee-bg-strong-hover);
  }

  .selector--small & {
    --selector-indicator-font-size: var(--type-small-font-size);
    --selector-indicator-line-height: var(--type-small-line-height);
    --selector-padding: 4px 8px;
    --selector-min-height: 32px;
  }

  .selector--large & {
    --selector-indicator-font-size: 1.125rem;
    --selector-indicator-line-height: 1.5rem;
    --selector-padding: 10px 16px;
    --selector-min-height: 60px;
  }

  .selector--xlarge & {
    --selector-indicator-font-size: 1.25rem;
    --selector-indicator-line-height: 1.625rem;
    --selector-padding: 26px 20px;
    --selector-min-height: 80px;
    min-width: 80px;
  }

  .selector__item--employee &,
  .selector__item--employee.selector__item--inactive &,
  .selector__item--employee.selector__item--inactive & .selector__item-label-strikethrough {
    --selector-color: var(--color-employee-text);
  }

  .selector__item--custom-color & {
    --selector-padding: 5px;
    --selector-min-height: auto;
    --selector-width: 36px;
    border-radius: 50%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;

    .selector--small & {
      --selector-padding: 3px;
      --selector-width: 24px;
      height: 24px;
    }
  }

  .selector__item--inactive.selector__item--custom-color &::after {
    display: block;
    content: '';
    background-color: var(--selector-background-color-after);
    width: 35px;
    height: 1px;
    position: absolute;
    transform: rotateY(0deg) rotate(-45deg);
    transition: 100ms ease-in-out;
    transition-property: background-color;

    .selector--small & {
      width: 23px;
    }
  }

  .selector__item--inactive.selector__item--custom-color .selector__input:disabled ~ &::after {
    --selector-background-color-after: var(--color-disabled-border);
  }

  html[data-whatintent='mouse'] .selector__input:hover ~ &::after,
  .selector__item--custom-color .selector__input:checked ~ &::after,
  html[data-whatintent='mouse'] .selector__item--custom-color .selector__input:checked:hover ~ &::after {
    --selector-background-color-after: var(--color-brand-primary-border);
  }

  html[data-whatintent='mouse'] .selector__item--custom-color .selector__input:enabled:hover ~ &,
  html[data-whatintent='mouse'] .selector--small .selector__item--custom-color .selector__input:enabled:hover ~ & {
    --selector-border-color: var(--color-brand-primary-border);
    --selector-box-shaddow: inset 0 0 0 1px var(--color-brand-primary-border);
  }

  .selector__item--custom-color .selector__input:checked ~ &,
  html[data-whatintent='mouse'] .selector__item--custom-color .selector__input:checked:hover ~ &,
  .selector__item--custom-color .selector--small .selector__input:checked ~ &,
  html[data-whatintent='mouse'] .selector--small .selector__item--custom-color .selector__input:checked:hover ~ & {
    --selector-border-color: var(--color-brand-primary-border);
    --selector-box-shaddow: inset 0 0 0 1px var(--color-brand-primary-border);
    --selector-background-color: transparent;
  }

  html[data-whatintent='mouse'] & .selector__item--custom-color .selector__input:disabled:hover ~ & {
    --selector-border-color: var(--color-brand-primary-border);
    --selector-box-shaddow: $elevation-0;
    --selector-background-color: transparent;
  }

  .selector__item--custom-color .selector__input:disabled ~ & {
    --selector-background-color: transparent;
  }

  .selector__item--custom-color .selector__input:disabled:checked ~ &,
  html[data-whatintent='mouse'] .selector__item--custom-color .selector__input:disabled:checked:hover ~ & {
    --selector-box-shaddow: inset 0 0 0 1px var(--color-brand-primary-border);
    --selector-background-color: transparent;
    border: 1px solid;
  }
}

.selector__indicator-label--has-children {
  @include visually-hidden();
}

.selector__indicator-inner {
  .selector__item--custom-color & {
    display: block;
    height: 24px;
    width: 24px;
    border-radius: 50%;

    .selector--small & {
      height: 16px;
      width: 16px;
    }
  }

  .selector__item--inactive.selector__item--custom-color .selector__indicator & {
    clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 86%, 86% 0, 100% 0, 100% 15%, 15% 100%, 100% 100%, 100% 0);
  }

  .selector__item--custom-color .selector__input:checked ~ .selector__indicator & {
    box-shadow: $elevation-0;
  }

  .selector__item--custom-color .selector__input:disabled ~ .selector__indicator & {
    opacity: 0.4;
  }
}

.selector__item-label {
  .selector__item--custom-color & {
    @include visually-hidden();
  }
}

.selector__form-helper {
  margin-top: -4px;
  margin-bottom: 8px;
}
