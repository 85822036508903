@import '@telia-front/sass-tokens/index.scss';

$btn-min-width: 150px;
$btn-min-width-sm: 150px;
$btn-font-size: var(--type-font-size);
$btn-line-height: 1.125; // 18px
$btn-border-width: 1px;
$btn-padding: 6px;
$btn-border-radius: 24px;
$btn-min-vertical-padding: 6 - $btn-border-width;
$btn-min-horizontal-padding: 24 - $btn-border-width;
$btn-letter-spacing: normal;
$btn-icon-size: 1.5rem;
$btn-spinner-size: 1.5rem;
$btn-spinner-color: currentcolor;
$btn-inner-min-height: 24px;
$btn-small-inner-min-height: 26px;
$btn-small-icon-size: 1rem;
$btn-small-spinner-size: 1rem;
$btn-icon-large-size: 1.5rem;
$btn-large-font-size: 1rem;
$btn-icon-min-width: $btn-min-height;
$btn-icon-wrapper-margin-left: -4px;
$btn-icon-wrapper-margin-right: -4px;
$btn-icon-outer-padding: 19px - $btn-min-horizontal-padding;
$btn-icon-inner-padding: 6px;
$btn-icon-translate: translateY(-50%);
$btn-icon-transition: cubic-bezier(0.25, 0.1, 0.25, 1);
$btn-icon-transition-length: 5px;
$btn-small-icon-transition-length: 4px;
$btn-icon-only-small-padding: 0;
$btn-link-letter-spacing: 0;
$btn-link-spinner-size: 1.5rem;
$btn-link-inner-loading-margin: calc(#{$btn-link-spinner-size} + 4px);
$btn-link-small-spinner-size: 1rem;
$btn-link-small-inner-loading-margin: calc(#{$btn-link-small-spinner-size} + 4px);
$btn-primary-background: var(--color-brand-primary-bg-strong);
$btn-primary-background-hover-color: var(--color-brand-primary-bg-strong-hover);
$btn-primary-border-color: var(--color-brand-primary-bg-strong);
$btn-primary-border-hover-color: var(--color-brand-primary-bg-strong-hover);
$btn-primary-color: var(--color-brand-primary-text-onbg-strong);
$btn-primary-hover-color: var(--color-brand-primary-text-onbg-strong);
$btn-primary-pressed-color: var(--color-brand-primary-text-onbg-strong);
$btn-primary-pressed-background: var(--color-brand-primary-bg-strong-pressed);
$btn-employee-color: var(--color-employee-text-onbg-strong);
$btn-employee-hover-color: var(--color-employee-text-onbg-strong);
$btn-employee-background: var(--color-employee-bg-strong);
$btn-employee-background-hover: var(--color-employee-bg-strong-hover);
$btn-employee-border-color: var(--color-employee-bg-strong);
$btn-employee-border-hover-color: var(--color-employee-bg-strong-hover);
$btn-employee-link-color: var(--color-employee-text);
$btn-employee-link-hover-color: var(--color-employee-text-hover);
$btn-employee-link-pressed-color: var(--color-employee-text-hover);
$btn-employee-pressed-color: var(--color-employee-text-onbg-strong);
$btn-employee-pressed-background: var(--color-employee-bg-strong-pressed);

$btn-employee-secondary-color: var(--color-employee-text);
$btn-employee-secondary-color-hover: var(--color-employee-text-onbg-strong);
$btn-employee-secondary-background: transparent;
$btn-employee-secondary-background-hover: var(--color-employee-bg-strong);
$btn-employee-secondary-border: var(--color-employee-border);
$btn-employee-secondary-border-hover: var(--color-employee-bg-strong);
$btn-employee-secondary-pressed-color: var(--color-employee-text-onbg-strong);
$btn-employee-secondary-pressed-background: var(--color-employee-bg-strong-hover);

$btn-withdrawal-color: var(--color-error-text-onbg-strong);
$btn-withdrawal-background: var(--color-error-bg-strong);
$btn-withdrawal-background-hover: var(--color-error-bg-strong-hover);
$btn-withdrawal-border-color: var(--color-error-bg-strong);
$btn-withdrawal-border-hover-color: var(--color-error-bg-strong-hover);
$btn-withdrawal-link-color: var(--color-error-text);
$btn-withdrawal-link-hover-color: var(--color-error-text-hover);
$btn-withdrawal-link-pressed-color: var(--color-error-text-hover);
$btn-withdrawal-pressed-background: var(--color-error-bg-strong-pressed);

$btn-withdrawal-secondary-color: var(--color-error-text);
$btn-withdrawal-secondary-background: transparent;
$btn-withdrawal-secondary-background-hover: var(--color-error-bg-strong);
$btn-withdrawal-secondary-border: var(--color-error-border);
$btn-withdrawal-secondary-border-hover: var(--color-error-bg-strong);
$btn-withdrawal-secondary-pressed-background: var(--color-error-bg-strong-hover);

$btn-expressive-color: var(--color-brand-expressive-text-onbg-strong);
$btn-expressive-background: var(--color-brand-expressive-bg-strong);
$btn-expressive-background-hover: var(--color-brand-expressive-bg-strong-hover);
$btn-expressive-border-color: var(--color-brand-expressive-bg-strong);
$btn-expressive-border-hover-color: var(--color-brand-expressive-bg-strong-hover);
$btn-expressive-link-color: var(--color-brand-expressive-text);
$btn-expressive-link-hover-color: var(--color-brand-expressive-text-hover);
$btn-expressive-link-pressed-color: var(--color-brand-expressive-text-hover);
$btn-expressive-pressed-color: var(--color-brand-expressive-text-onbg-strong);
$btn-expressive-pressed-background: var(--color-brand-expressive-bg-strong-pressed);

$btn-secondary-color: var(--color-brand-primary-text);
$btn-secondary-color-hover: var(--color-brand-primary-text-onbg-strong);
$btn-secondary-background: transparent;
$btn-secondary-background-hover: var(--color-brand-primary-bg-strong);
$btn-secondary-icon-only-background-hover: var(--color-brand-primary-bg-strong);
$btn-secondary-border: var(--color-brand-primary-border);
$btn-secondary-border-hover: var(--color-brand-primary-bg-strong);
$btn-secondary-icon-only-border: $btn-secondary-border;
$btn-secondary-icon-only-border-hover: var(--color-brand-primary-bg-strong);
$btn-secondary-box-shadow-hover: null;
$btn-secondary-pressed-color: var(--color-brand-primary-text-onbg-strong);
$btn-secondary-pressed-background: var(--color-brand-primary-bg-strong-hover);

$btn-text-color: var(--color-neutral-text-onbg-strong);
$btn-text-color-background-color: var(--color-neutral-bg-strong);
$btn-text-color-background-hover-color: var(--color-neutral-bg-strong-hover);
$btn-text-color-background-pressed-color: var(--color-neutral-bg-strong-pressed);
$btn-text-color-border-hover-color: var(--color-neutral-bg-strong-hover);
$btn-text-color-link-color: var(--color-neutral-text);
$btn-text-color-link-hover-color: var(--color-brand-primary-text-hover);

.btn {
  @include transition(
    (
      'animation': short,
      'properties': (
        background-color,
        border-color,
        color,
      ),
    )
  );

  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  min-width: $btn-min-width;
  max-width: 100%;
  font-weight: 700;
  text-transform: none;
  text-align: center;
  border-style: solid;
  border-width: $btn-border-width;
  padding: $btn-min-vertical-padding $btn-min-horizontal-padding;
  border-radius: $btn-border-radius;
  letter-spacing: $btn-letter-spacing;
  cursor: pointer;

  @media (min-width: $media-width-sm-min) {
    min-width: $btn-min-width-sm;
  }

  @include button-variant(
    $btn-primary-color,
    $btn-primary-background,
    $btn-primary-background-hover-color,
    $btn-primary-border-color,
    $btn-primary-border-hover-color,
    $btn-primary-hover-color,
    null,
    $btn-primary-pressed-color,
    $btn-primary-pressed-background
  );

  &:not(.btn--link) {
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    min-height: $btn-min-height;
  }

  &.is-loading {
    cursor: inherit;
  }

  &.skeleton__item {
    border: none;
    width: auto;
    cursor: inherit;
  }
}

.btn--small {
  min-width: 0;
  padding: $btn-small-padding $btn-small-min-horizontal-padding;
  border-radius: $btn-small-border-radius;

  &:not(.btn--link) {
    font-size: var(--type-small-font-size);
    line-height: $btn-small-line-height;
    min-height: $btn-small-min-height;
  }

  &.btn--link {
    font-size: var(--type-small-font-size);
  }
}

/**
 * Button variants
 */

.btn--variant-secondary {
  @include button-variant(
    $btn-secondary-color,
    $btn-secondary-background,
    $btn-secondary-background-hover,
    $btn-secondary-border,
    $btn-secondary-border-hover,
    $btn-secondary-color-hover,
    $btn-secondary-box-shadow-hover,
    $btn-secondary-pressed-color,
    $btn-secondary-pressed-background
  );

  &.btn--icon-only {
    @include button-hover-variant(
      $btn-secondary-color,
      $btn-secondary-background,
      $btn-secondary-icon-only-background-hover,
      $btn-secondary-icon-only-border-hover,
      $btn-secondary-color-hover,
      $btn-secondary-icon-only-border,
      $btn-secondary-pressed-color,
      $btn-secondary-pressed-background
    );

    &.btn--small {
      @include button-hover-variant(
        $btn-secondary-color,
        $btn-secondary-background,
        $btn-secondary-icon-only-background-hover,
        $btn-secondary-icon-only-border-hover,
        $btn-secondary-color-hover,
        $btn-secondary-icon-only-border,
        $btn-secondary-pressed-color,
        $btn-secondary-pressed-background
      );
    }
  }
}

/**
 * Button types.
 */

.btn--text-color {
  @include button-variant(
    $btn-text-color,
    $btn-text-color-background-color,
    $btn-text-color-background-hover-color,
    $btn-text-color-background-color,
    $btn-text-color-border-hover-color,
    $btn-text-color,
    null,
    $btn-text-color,
    $btn-text-color-background-pressed-color
  );
}

.btn--withdrawal {
  @include button-variant(
    $btn-withdrawal-color,
    $btn-withdrawal-background,
    $btn-withdrawal-background-hover,
    $btn-withdrawal-border-color,
    $btn-withdrawal-border-hover-color,
    $btn-withdrawal-color,
    null,
    $btn-withdrawal-color,
    $btn-withdrawal-pressed-background
  );

  &.btn--variant-secondary {
    @include button-variant(
      $btn-withdrawal-secondary-color,
      $btn-withdrawal-secondary-background,
      $btn-withdrawal-secondary-background-hover,
      $btn-withdrawal-secondary-border,
      $btn-withdrawal-secondary-border-hover,
      $btn-withdrawal-color,
      $btn-secondary-box-shadow-hover,
      $btn-withdrawal-color,
      $btn-withdrawal-secondary-pressed-background
    );

    &.btn--icon-only {
      @include button-hover-variant(
        $btn-withdrawal-secondary-color,
        $btn-withdrawal-secondary-background,
        $btn-withdrawal-secondary-background-hover,
        $btn-withdrawal-secondary-border-hover,
        $btn-withdrawal-color,
        $btn-withdrawal-secondary-border,
        $btn-withdrawal-color,
        $btn-withdrawal-secondary-pressed-background
      );

      &.btn--small {
        @include button-hover-variant(
          $btn-withdrawal-secondary-color,
          $btn-withdrawal-secondary-background,
          $btn-withdrawal-secondary-background-hover,
          $btn-withdrawal-secondary-border-hover,
          $btn-withdrawal-color,
          $btn-withdrawal-secondary-border,
          $btn-withdrawal-color,
          $btn-withdrawal-secondary-pressed-background
        );
      }
    }
  }
}

.btn--employee {
  @include button-variant(
    $btn-employee-color,
    $btn-employee-background,
    $btn-employee-background-hover,
    $btn-employee-border-color,
    $btn-employee-border-hover-color,
    $btn-employee-hover-color,
    null,
    $btn-employee-pressed-color,
    $btn-employee-pressed-background
  );

  &.btn--variant-secondary {
    @include button-variant(
      $btn-employee-secondary-color,
      $btn-employee-secondary-background,
      $btn-employee-secondary-background-hover,
      $btn-employee-secondary-border,
      $btn-employee-secondary-border-hover,
      $btn-employee-secondary-color-hover,
      $btn-secondary-box-shadow-hover,
      $btn-employee-secondary-pressed-color,
      $btn-employee-secondary-pressed-background
    );

    &.btn--icon-only {
      @include button-hover-variant(
        $btn-employee-secondary-color,
        $btn-employee-secondary-background,
        $btn-employee-secondary-background-hover,
        $btn-employee-secondary-border-hover,
        $btn-employee-secondary-color-hover,
        $btn-employee-secondary-border,
        $btn-employee-secondary-pressed-color,
        $btn-employee-secondary-pressed-background
      );

      &.btn--small {
        @include button-hover-variant(
          $btn-employee-secondary-color,
          $btn-employee-secondary-background,
          $btn-employee-secondary-background-hover,
          $btn-employee-secondary-border-hover,
          $btn-employee-secondary-color-hover,
          $btn-employee-secondary-border,
          $btn-employee-secondary-pressed-color,
          $btn-employee-secondary-pressed-background
        );
      }
    }
  }
}

.btn--expressive {
  @include button-variant(
    $btn-expressive-color,
    $btn-expressive-background,
    $btn-expressive-background-hover,
    $btn-expressive-border-color,
    $btn-expressive-border-hover-color,
    null,
    null,
    $btn-expressive-pressed-color,
    $btn-expressive-pressed-background
  );
}

.btn--link {
  background: transparent;
  border: 0;
  text-transform: none;
  text-align: inherit;
  height: auto;
  padding: 0;
  min-width: 0;
  border-radius: 0;
  letter-spacing: $btn-link-letter-spacing;
  vertical-align: bottom;

  @include button-link-variant(var(--color-brand-primary-text), var(--color-brand-primary-text-hover), var(--color-brand-primary-text-hover));

  &.btn--text-color {
    @include button-link-variant($btn-text-color-link-color, $btn-text-color-link-hover-color);
  }

  &.btn--withdrawal {
    @include button-link-variant($btn-withdrawal-link-color, $btn-withdrawal-link-hover-color, $btn-withdrawal-link-pressed-color);
  }

  &.btn--employee {
    @include button-link-variant($btn-employee-link-color, $btn-employee-link-hover-color, $btn-employee-link-pressed-color);
  }

  &.btn--underlined {
    text-decoration: underline;
  }

  &.btn--expressive {
    @include button-link-variant($btn-expressive-link-color, $btn-expressive-link-hover-color, $btn-expressive-link-pressed-color);
  }
}

.btn--large.btn--link {
  font-size: $btn-large-font-size;
}

.btn__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .btn:not(.btn--link) &,
  a.btn:not(.btn--link).btn--icon-only &,
  span.btn:not(.btn--link).btn--icon-only & {
    min-height: $btn-inner-min-height;
  }

  a.btn:not(.btn--link) &,
  span.btn:not(.btn--link) & {
    min-height: $btn-min-height - ($btn-border-width * 2) - ($btn-min-vertical-padding * 2);
  }

  .btn.btn--small:not(.btn--link) &,
  .btn.btn--small:not(.btn--link).btn--icon-only & {
    min-height: $btn-small-inner-min-height;
  }

  .btn.is-loading & {
    opacity: 0;
  }

  .btn--link.is-loading & {
    margin-right: $btn-link-inner-loading-margin;
    opacity: 1;
  }

  .btn--link.btn--small.is-loading & {
    margin-right: $btn-link-small-inner-loading-margin;
  }

  .btn--link.btn--block & {
    justify-content: flex-start;
  }

  .btn--icon-only.btn--link & {
    &::after {
      // inject zero-width space when button has only icon visible,
      // otherwise icon-only link buttons do not follow minimum height
      // set by line-height on the component
      content: '\200B';
    }
  }

  // Loading toggle animation
  .btn__spinner.fade-exit ~ &,
  .btn__spinner.fade-enter ~ & {
    @include transition(
      (
        'animation': medium,
        'properties': (
          opacity,
          transform,
        ),
      )
    );
  }

  .btn__spinner.fade-exit-active ~ & {
    opacity: 1;
    transform: translateY(0);
  }

  .btn__spinner.fade-enter-done ~ &,
  .btn__spinner.fade-enter-active ~ & {
    opacity: 0;
    transform: translateY(-10px);
  }

  // Reset animation for link and icon-only variants
  .btn--link .btn__spinner.fade-enter-done ~ &,
  .btn--link .btn__spinner.fade-enter-active ~ &,
  .btn--icon-only .btn__spinner.fade-enter-done ~ &,
  .btn--icon-only .btn__spinner.fade-enter-active ~ & {
    opacity: 1;
    transform: translateY(0);
  }

  .btn__spinner ~ & {
    opacity: 0;
  }
}

.btn__text {
  display: inline-flex;

  &::first-letter {
    text-transform: uppercase;
  }

  .btn--icon-only & {
    @include visually-hidden();
  }
}

.btn--block {
  display: block;
  width: 100%;
}

.btn--icon-only {
  padding: 7px 0;
  min-width: $btn-icon-min-width;
  height: ($btn-padding * 2) + ($btn-border-width * 2) + 16px;

  &.btn--small {
    min-width: $btn-small-min-height;
    height: $btn-small-min-height;
    padding: $btn-icon-only-small-padding 0;
  }

  &.btn--link {
    min-width: 0;
    padding: 0;
    height: auto;
  }
}

.btn--vertical-align-baseline {
  vertical-align: baseline;
}

.btn--vertical-align-top {
  vertical-align: top;
}

.btn--vertical-align-middle {
  vertical-align: middle;
}

.btn--vertical-align-bottom {
  vertical-align: bottom;
}

/**
 * Icons
 */

.btn__icon {
  @include reset-icon();

  position: absolute;
  left: 0;
  top: 50%;
  transform: $btn-icon-translate;

  .btn:not(.btn--link) &:not(.btn__icon--custom-size) {
    font-size: $btn-icon-size;
  }

  .btn:not(.btn--link).btn--small & {
    font-size: $btn-small-icon-size;
  }

  .btn--link.is-loading & {
    opacity: 0;
  }

  .btn--link & {
    &:not(.btn__icon--custom-size) {
      font-size: $btn-icon-size;
    }
  }

  .btn--large.btn--link & {
    &:not(.btn__icon--custom-size) {
      font-size: $btn-icon-large-size;
    }
  }

  .btn--small.btn--link & {
    &:not(.btn__icon--custom-size) {
      font-size: $btn-small-icon-size;
    }
  }
}

.btn__icon--right {
  .btn--with-arrow-right-icon & {
    @include transition(
      (
        'animation': medium,
        'properties': transform,
      )
    );

    transform: translateX(0) $btn-icon-translate;
  }

  html[data-whatintent='mouse'] .card--link:not(.click-area):hover .btn--with-arrow-right-icon &,
  html[data-whatintent='mouse'] .click-area:hover .btn--with-arrow-right-icon.click-area__trigger &,
  html[data-whatintent='mouse'] .btn--with-arrow-right-icon:hover:not([aria-disabled='true']) & {
    transform: translateX($btn-icon-transition-length) $btn-icon-translate;
  }

  html[data-whatintent='mouse'] .card--link:not(.click-area):hover .btn--small.btn--with-arrow-right-icon &,
  html[data-whatintent='mouse'] .click-area:hover .btn--small.btn--with-arrow-right-icon.click-area__trigger &,
  html[data-whatintent='mouse'] .btn--small.btn--with-arrow-right-icon:hover:not([aria-disabled='true']) & {
    transform: translateX($btn-small-icon-transition-length) $btn-icon-translate;
  }
}

.btn__icon-wrapper {
  flex-shrink: 0;
  height: 1em;
  width: $btn-icon-size;
  position: relative;

  .btn--small & {
    width: $btn-small-icon-size;
  }

  .btn.is-loading & {
    width: 0;
  }
}

.btn__icon-wrapper--xxs {
  width: $icon-font-size-xxs;
}

.btn__icon-wrapper--xs {
  width: $icon-font-size-xs;
}

.btn__icon-wrapper--sm {
  width: $icon-font-size-sm;
}

.btn__icon-wrapper--md {
  width: $icon-font-size-md;
}

.btn__icon-wrapper--lg {
  width: $icon-font-size-lg;
}

.btn__icon-wrapper--xl {
  width: $icon-font-size-xl;
}

.btn__icon-wrapper--xxl {
  width: $icon-font-size-xxl;
}

.btn__icon-wrapper--xxxl {
  width: $icon-font-size-xxxl;
}

.btn__icon-wrapper--left {
  margin-left: $btn-icon-wrapper-margin-left;
  margin-right: $btn-icon-inner-padding;

  .btn--small & {
    margin-right: $btn-small-icon-spacing;
  }

  .btn--link & {
    margin-left: 0;
    margin-right: 3px;
  }

  .btn--link.is-loading & {
    margin: 0;
  }
}

.btn__icon-wrapper--right {
  margin-right: $btn-icon-wrapper-margin-right;
  margin-left: $btn-icon-inner-padding;

  .btn--small & {
    margin-left: $btn-small-icon-spacing;
    margin-right: $btn-small-icon-padding - $btn-small-min-horizontal-padding;
  }

  .btn--link & {
    margin-left: 3px;
    margin-right: 0;
  }

  .btn--link.btn--block & {
    margin-left: auto;
  }

  .btn--with-arrow-right-icon & {
    margin-left: 0;
    margin-right: $btn-icon-outer-padding;
  }
}

/**
 * Spinner
 */

.btn__spinner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner__circle {
    width: $btn-spinner-size;
    height: $btn-spinner-size;

    .btn--small & {
      width: $btn-small-spinner-size;
      height: $btn-small-spinner-size;
    }

    .btn--link & {
      width: $btn-link-spinner-size;
      height: $btn-link-spinner-size;
    }

    .btn--link.btn--small & {
      width: $btn-link-small-spinner-size;
      height: $btn-link-small-spinner-size;
    }
  }

  .btn--link & {
    width: auto;
    margin-left: 10px;
    right: 0;
    left: auto;
  }

  .spinner__path {
    stroke: $btn-spinner-color;
  }

  // Loading toggle animation
  &.fade-exit,
  &.fade-enter {
    @include transition(
      (
        'animation': medium,
        'properties': (
          opacity,
          transform,
        ),
      )
    );

    .btn--link &,
    .btn--icon-only & {
      transition: none;
    }
  }

  &.fade-enter,
  &.fade-exit-active {
    opacity: 0;
    transform: translateY(10px);

    .btn--link &,
    .btn--icon-only & {
      opacity: 0;
      transform: none;
    }
  }

  &.fade-enter-done,
  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0);

    .btn--link &,
    .btn--icon-only & {
      opacity: 1;
      transform: none;
    }
  }
}

/*
 * Third party buttons
 */

.btn--outer {
  &-swed {
    @include button-variant(
      // color
      $white,
      // background
      #d34300,
      // background hover
      #ae3700,
      // border
      #d34300,
      // border hover
      #ae3700,
      // hover color
      $white,
      null,
      // pressed color
      $white,
      // pressed background
      #882b00
    );
  }

  &-seb {
    @include button-variant(
      // color
      $white,
      // background
      #3f8510,
      // background hover
      #336d0d,
      // border
      #3f8510,
      // border hover
      #336d0d,
      // hover color
      $white,
      null,
      // pressed color
      $white,
      // pressed background
      #28540a
    );
  }

  &-luminor {
    @include button-variant(
      // color
      $white,
      // background
      #481335,
      // background hover
      #370e28,
      // border
      #481335,
      // border hover
      #370e28,
      // hover color
      $white,
      null,
      // pressed color
      $white,
      // pressed background
      #200818
    );
  }

  // Deprecared, as Danske Bank does not exist anymore.
  &-danske {
    @include button-variant(
      // color
      $white,
      // background
      #00466d,
      // background hover
      #00466d,
      // border
      #00466d,
      // border hover
      #00466d,
      // hover color
      $white,
      null,
      // pressed color
      $white,
      // pressed background
      #00466d
    );
  }

  &-coop {
    @include button-variant(
      // color
      $white,
      // background
      #004bc5,
      // background hover
      #002f7d,
      // border
      #004bc5,
      // border hover
      #002f7d,
      // hover color
      $white,
      null,
      // pressed color
      $white,
      // pressed background
      #002665
    );
  }

  &-lhv {
    @include button-variant(
      // color
      $white,
      // background
      #222221,
      // background hover
      #1d1d1d,
      // border
      #222221,
      // border hover
      #1d1d1d,
      // hover color
      $white,
      null,
      // pressed color
      $white,
      // pressed background
      #111110
    );
  }

  &-google {
    @include button-variant(
      // color
      $white,
      // background
      #1a73e8,
      // background hover
      #155ebd,
      // border
      #1a73e8,
      // border hover
      #155ebd,
      // hover color
      $white,
      null,
      // pressed color
      $white,
      // pressed background
      #104992
    );
  }

  &-facebook {
    @include button-variant(
      // color
      $white,
      // background
      #0866ff,
      // background hover
      #0756d8,
      // border
      #0866ff,
      // border hover
      #0756d8,
      // hover color
      $white,
      null,
      // pressed color
      $white,
      // pressed background
      #0543a9
    );
  }

  &-microsoft {
    @include button-variant(
      // color
      $white,
      // background
      #007cb6,
      // background hover
      #006696,
      // border
      #007cb6,
      // border hover
      #006696,
      // hover color
      $white,
      null,
      // pressed color
      $white,
      // pressed background
      #004f74
    );
  }

  &-spotify {
    @include button-variant(
      // color
      $white,
      // background
      #15873e,
      // background hover
      #116f32,
      // border
      #15873e,
      // border hover
      #116f32,
      // hover color
      $white,
      null,
      // pressed color
      $white,
      // pressed background
      #0e5727
    );
  }

  &-twitter {
    @include button-variant(
      // color
      $white,
      // background
      #1a93dd,
      // background hover
      #167ab8,
      // border
      #1a93dd,
      // border hover
      #167ab8,
      // hover color
      $white,
      null,
      // pressed color
      $white,
      // pressed background
      #126497
    );
  }

  &-diil {
    @include button-variant(
      // color
      $white,
      // background
      #00847e,
      // background hover
      #006c67,
      // border
      #00847e,
      // border hover
      #006c67,
      // hover color
      $white,
      null,
      // pressed color
      $white,
      // pressed background
      #02534f
    );
  }
}
