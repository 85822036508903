@import '@telia-front/sass-tokens/index.scss';

a,
.anchor {
  text-decoration: none;
  color: var(--color-brand-primary-text);
  font-weight: 700;
  @include transition(
    (
      'animation': short,
      'properties': color,
    )
  );

  html[data-whatintent='mouse'] .click-area:hover &.click-area__trigger,
  html[data-whatintent='mouse'] &:hover {
    color: var(--color-brand-primary-text-hover);
  }

  &.is-text-color {
    color: var(--color-neutral-text);

    html[data-whatintent='mouse'] .click-area:hover &.click-area__trigger,
    html[data-whatintent='mouse'] &:hover {
      color: var(--color-brand-primary-text-hover);
    }
  }

  &.is-underlined {
    text-decoration: underline;
  }
}

.anchor--employee {
  color: var(--color-employee-text);

  html[data-whatintent='mouse'] .click-area:hover &.click-area__trigger,
  html[data-whatintent='mouse'] &:hover {
    color: var(--color-employee-text-hover);
  }

  &.is-text-color {
    color: var(--color-neutral-text);

    html[data-whatintent='mouse'] .click-area:hover &.click-area__trigger,
    html[data-whatintent='mouse'] &:hover {
      color: var(--color-employee-text-hover);
    }
  }
}
