@import '@telia-front/sass-tokens/index.scss';

.signature {
  --signature-border-color: var(--color-neutral-border);
  --signature-shadow-size: 0px;
  --signature-background: var(--color-neutral-bg-level-2);

  display: flex;
  flex-direction: column;
  gap: 8px;
}

.signature--light {
  --signature-background: var(--color-neutral-bg-level-1);
}

.signature--employee {
  --signature-border-color: var(--color-employee-border);
}

.signature--invalid {
  --signature-shadow-size: 1px;
  --signature-border-color: var(--color-error-border);
}

.signature--disabled {
  --signature-border-color: var(--color-disabled-border);
  --signature-background: var(--color-disabled-bg);
}

.signature__canvas {
  position: relative;
  display: flex;
  height: 230px;
  background: var(--signature-background);
  border: 1px solid var(--signature-border-color);
  box-shadow: inset 0 0 0 var(--signature-shadow-size) var(--signature-border-color);
  border-radius: 4px;
  overflow: auto;

  .signature:not(.signature--disabled) &:focus {
    @include keyboard-focus();
  }

  .signature__canvas-inner {
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
  }
}

.signature__label-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.signature__label {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: var(--type-small-font-size);
  line-height: var(--type-small-line-height);

  .signature--disabled & {
    color: var(--color-disabled-text);
  }
}

.signature__label-icon {
  font-size: 1rem;
  color: var(--color-employee-graphic);
}
