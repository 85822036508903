@use 'sass:map';

@import '@telia-front/sass-tokens/index.scss';

.large-search {
  position: relative;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  z-index: map.get($zindex, search-large);
}

.large-search--left {
  margin-left: 0;
}

.large-search--right {
  margin-right: 0;
}

.large-search__inner {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .large-search--single-line & {
    flex-direction: row;
    align-items: flex-end;
    gap: 8px;
  }
}

.large-search__fields {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;

  .large-search--single-line & {
    flex-direction: row;
    align-items: flex-end;
    gap: 0;
  }
}

.large-search__block {
  position: relative;
  flex: 1;
  padding: 0;
  display: flex;

  & + & {
    margin-left: -1px;
  }

  .large-search--single-line &:nth-child(2) {
    max-width: 165px;
  }
}

.large-search__action {
  flex-shrink: 0;
}

.large-search__help {
  margin-top: 4px;

  .large-search--single-line & {
    padding-left: 16px;
  }
}

/* Input Overrides */

.textfield {
  .large-search--single-line & {
    --textfield-horizontal-padding: 16px;
  }
}

.textfield__input {
  .large-search--single-line .large-search__block & {
    border-radius: 0;
  }

  .large-search--single-line .large-search__block:first-child & {
    border-top-left-radius: 48px;
    border-bottom-left-radius: 48px;
  }

  .large-search--single-line .large-search__block:last-child & {
    border-top-right-radius: 48px;
    border-bottom-right-radius: 48px;
  }

  .large-search--single-line .large-search__block:not(:first-child) .textfield:not(.is-invalid) & {
    border-left-color: transparent;
  }
}

.textfield__label {
  .large-search--single-line .large-search__block:first-child & {
    padding-left: 16px;
  }
}

.textfield__form-helper {
  .large-search--single-line .large-search__block:first-child & {
    padding-left: 16px;
  }
}

/* Select Overrides */

.select {
  .large-search--single-line & {
    --select-horizontal-padding: 16px;
  }
}

.select__wrapper {
  .large-search--single-line .large-search__block:first-child & {
    border-top-left-radius: 48px;
    border-bottom-left-radius: 48px;
  }

  .large-search--single-line .large-search__block:last-child & {
    border-top-right-radius: 48px;
    border-bottom-right-radius: 48px;
  }

  .large-search--single-line .large-search__block:not(:first-child) .select:not(.is-invalid) & {
    border-left-color: transparent;
  }
}

.select__label {
  .large-search--single-line .large-search__block:first-child & {
    padding-left: 16px;
  }
}
