@import '@telia-front/sass-tokens/index.scss';

// Select
$select-border-width: 1px;
$select-inner-gap: 8px;

.select,
.select-menu-outer {
  --select-icon-size: 1.5rem;
}

.select {
  --select-border-color: var(--color-neutral-border);
  --select-shadow-size: 0px;
  --select-horizontal-padding: 12px;
  --select-horizontal-inner-padding: calc(var(--select-horizontal-padding) - #{$select-border-width});

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  width: 100%;
  max-width: 100%;

  &.is-focused {
    z-index: 2;
  }

  .table & {
    min-width: 180px;
  }
}

.select--small,
.select-menu-outer--small {
  --select-icon-size: 1rem;
}

.select--small {
  --select-horizontal-padding: 8px;

  .table & {
    min-width: 140px;
  }
}

.select--employee {
  --select-border-color: var(--color-employee-border);
}

.select.is-invalid {
  --select-shadow-size: 1px;
  --select-border-color: var(--color-error-border);
}

.select.is-disabled {
  --select-border-color: var(--color-disabled-border);

  color: var(--color-disabled-text);
}

.select__inner {
  position: relative;
  width: 100%;
}

.select__wrapper {
  border: $select-border-width solid var(--select-border-color);
  border-radius: 4px;
  box-shadow: inset 0 0 0 var(--select-shadow-size) var(--select-border-color);
  display: block;
  font-size: var(--type-font-size);
  line-height: var(--type-line-height);
  font-weight: 400;
  margin: 0;
  width: 100%;
  background: transparent;
  color: var(--color-neutral-text);
  text-transform: none;
  @include transition(
    (
      'animation': short,
      'properties': (
        border-color,
        box-shadow,
      ),
    )
  );
  text-align: left;

  &:focus-within {
    @include keyboard-focus();
  }

  .select.is-disabled & {
    background-color: transparent;
    color: var(--color-disabled-text);
    -webkit-text-fill-color: var(--color-disabled-text);
    outline: none;
  }

  .select--no-border.is-open &,
  .select--no-border & {
    border-color: transparent;
  }

  &::-ms-clear {
    display: none;
  }

  &:focus {
    outline: none;
  }

  .select--small & {
    font-size: var(--type-small-font-size);
    line-height: var(--type-small-line-height);
  }
}

.select__label {
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--color-neutral-text);
  font-size: var(--type-small-font-size);
  line-height: var(--type-small-line-height);
  text-align: left;
  padding: 0;
  @include transition(
    (
      'animation': short,
      'properties': color,
    )
  );
  font-weight: 400;

  .select.is-disabled & {
    color: var(--color-disabled-text);
  }

  .select--no-label-no-break & {
    @include visually-hidden();
  }

  .select--no-label-sm & {
    @media (min-width: $media-width-sm-min) {
      @include visually-hidden();
    }
  }

  .select--no-label-md & {
    @media (min-width: $media-width-md-min) {
      @include visually-hidden();
    }
  }
}

.select__label-icon {
  font-size: 1rem;
  color: var(--color-employee-graphic);
}

.select__icon {
  @include reset-icon();
  @include transition(
    (
      'animation': medium,
      'properties': transform,
    )
  );

  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: var(--select-horizontal-padding);
  font-size: var(--select-icon-size);
  pointer-events: none;

  .select.is-open & {
    transform: translateY(50%) rotate(180deg);
  }
}

.select__icon-search {
  @include reset-icon();

  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  left: var(--select-horizontal-padding);
  font-size: var(--select-icon-size);
}

.select__control {
  position: relative;
  width: 100%;
}

.select__placeholder {
  color: var(--color-neutral-text-subtle);
  padding: 1px 0;
  min-height: calc(var(--type-font-size) * var(--type-line-height));

  .select--small & {
    min-height: calc(var(--type-small-font-size) * var(--type-small-line-height));
  }

  .select--small &,
  .select__value-container--is-multi & {
    padding: 0;
  }
}

.select__menu {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: -1px;
  background-color: var(--color-neutral-bg-level-3);
  box-shadow: $elevation-5;
  border-radius: 4px;
  max-height: 400px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  font-size: var(--type-font-size);
  line-height: var(--type-line-height);
}

.select-menu-outer--small {
  font-size: var(--type-small-font-size);
  line-height: var(--type-small-line-height);
}

.select__menu-list {
  max-height: 400px;
  overflow-y: auto;
  border-radius: 4px;
}

.select__option {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 9px 21px;
  cursor: pointer;

  &:not(:first-child)::after {
    content: '';
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    height: 1px;
    background: var(--color-neutral-border-subtle);
  }

  .select-menu-outer--small & {
    padding: 7px 11px;
  }
}

.select__option--is-selected {
  color: var(--color-brand-primary-text);
  font-weight: 700;

  .select-menu-outer--employee & {
    color: var(--color-employee-text);
  }

  .check__content {
    font-weight: 700;
  }
}

.select__option--is-focused {
  color: var(--color-brand-primary-text-hover);
  background-color: var(--color-neutral-bg-hover);

  .select-menu-outer--employee & {
    color: var(--color-employee-text-hover);
  }

  &::after {
    display: none;
  }

  + .select__option::after {
    display: none;
  }
}

.select__option--employee {
  color: var(--color-employee-text);

  &.select__option--is-focused {
    color: var(--color-employee-text-hover);
  }
}

.select__option--is-disabled {
  color: var(--color-disabled-text);
  cursor: default;
}

.select__value-container {
  $with-icon-padding: calc(var(--select-icon-size) + $select-inner-gap + var(--select-horizontal-inner-padding));

  display: block;
  padding: 11px $with-icon-padding 11px var(--select-horizontal-inner-padding);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  .select--searchable & {
    display: flex;
    flex: 1;
    padding-left: $with-icon-padding;
  }

  .select--small & {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  & > input {
    @include visually-hidden();
  }
}

.select__value-container--is-multi {
  padding-top: 12px;
  padding-bottom: 12px;
}

.select__multi-value,
.select__single-value {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
  white-space: nowrap;

  .select--searchable & {
    align-items: normal;
  }
}

.select__multi-value {
  display: inline;
  position: relative;
  flex: 0 1 auto;
  min-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(:first-child)::before {
    content: ',';
    margin-right: 4px;
  }
}

.select__menu-notice--no-options {
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.check__inner {
  .select-menu-outer & {
    display: block;
  }
}

.check__content {
  .select-menu-outer--small & {
    font-size: var(--type-small-font-size);
    line-height: var(--type-small-line-height);
    margin-top: calc((1rem - var(--type-small-line-height) * var(--type-small-font-size)) / 2);
  }
}

.select__image-wrapper {
  display: block;
  height: var(--select-icon-size);
  width: var(--select-icon-size);

  .select__value-container & {
    position: static;
    margin-right: $select-inner-gap;
  }

  .select__value-container--is-multi & {
    display: none;
  }
}

.select__image-option {
  padding-left: 8px;
}

.select__option-icon-wrapper {
  display: inline-block;
  flex: 0 0 var(--select-icon-size);
  font-size: var(--select-icon-size);
  height: var(--select-icon-size);
  vertical-align: bottom;

  .select-menu-outer--small & {
    margin-top: 0.125rem;
  }

  .select__value-container & {
    position: relative;
    margin-right: $select-inner-gap;
    display: flex;
    align-items: center;
  }

  .select__value-container--is-multi & {
    display: none;
  }
}

.select__option-icon {
  .select__value-container & {
    @include reset-icon();

    display: block;
  }
}

.select__option-icon-label {
  display: inline-block;
  flex: 1 1;
  padding-left: 8px;

  .select__option-icon-wrapper + & {
    margin-top: 2px;
  }
}

.select__sub-label {
  flex: 1 0 100%;
  max-width: 100%;
  margin-top: 4px;
  color: var(--color-neutral-text-subtle);
  font-size: var(--type-tiny-font-size);
  line-height: var(--type-tiny-line-height);
}

.select__asterisk-wrapper {
  display: inline-block;
  align-self: flex-start;
  padding-left: 1px;
  margin-top: calc((0.5rem + 1px) * -1);
  vertical-align: text-top;
}

.select__asterisk {
  font-size: 0.5rem;
}

.select__input-container {
  padding: 1px 0;

  .select--small &,
  .select__value-container--is-multi & {
    padding: 0;
  }
}

.select__text-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1px 0;

  .select--small & {
    padding: 0;
  }
}

.select__native-select {
  appearance: none;
  background-color: inherit;
  border: none;
}

.select__option-children {
  display: inline-block;
}

.select__multi-option-content {
  display: flex;
}
