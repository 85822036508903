@use 'sass:math';

@import '@telia-front/sass-tokens/index.scss';

.slider {
  --slider-font-size: var(--type-small-font-size);
  --slider-line-height: var(--type-small-line-height);
  --slider-handle-size: 1.5rem;
  --slider-handle-inner-size: 0.625rem;
  --slider-tooltip-padding: 4px;
  --slider-tooltip-height: calc(var(--slider-font-size) * var(--slider-line-height));
  --slider-target-height: 0.125rem;
  --slider-target-border-radius: 5px;
  --slider-handle-focus-outline-size: 4px;

  padding-top: calc((var(--slider-handle-size) / 2) + var(--slider-handle-focus-outline-size));
  padding-right: calc((var(--slider-handle-size) / 2) + var(--slider-handle-focus-outline-size));
  padding-bottom: calc((var(--slider-handle-size) / 2) + var(--slider-tooltip-height) + var(--slider-tooltip-padding));
  padding-left: calc((var(--slider-handle-size) / 2) + var(--slider-handle-focus-outline-size));
  overflow: hidden;
  position: relative;

  * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    touch-action: none;
    user-select: none;
    box-sizing: border-box;
  }
}

.slider__target {
  position: relative;
  height: var(--slider-target-height);
  background: var(--color-neutral-border-subtle);
  color: var(--color-neutral-text);

  &[disabled] {
    color: var(--color-disabled-text);
    background: var(--color-disabled-bg);
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: var(--slider-target-height);
    width: calc(var(--slider-handle-size) / 2);
    top: 0;
    background: var(--color-neutral-border-subtle);
  }

  &[disabled]::before,
  &[disabled]::after {
    background: var(--color-disabled-bg);
  }

  &::before {
    left: calc(var(--slider-handle-size) / -2);
    border-radius: var(--slider-target-border-radius) 0 0 var(--slider-target-border-radius);
  }

  &::after {
    right: calc(var(--slider-handle-size) / -2);
    border-radius: 0 var(--slider-target-border-radius) var(--slider-target-border-radius) 0;
  }
}

.slider__base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  transform: translate3d(0, 0, 0);

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: var(--slider-handle-size);
    top: calc(var(--slider-handle-size) / -2);
    width: 100%;
  }

  &::before {
    left: calc(var(--slider-handle-size) / -2);
  }

  &::after {
    right: calc(var(--slider-handle-size) / -2);
  }
}

.slider__origin {
  position: absolute;
  z-index: 1;
  top: 0;
  left: auto;
  right: 0;
  height: 0;
  width: 100%;
  transform-origin: 0 0;
  will-change: transform;

  .slider__state-tap & {
    transition: transform 0.3s;
  }
}

.slider__state-drag * {
  cursor: inherit !important;
}

.slider__handle {
  position: absolute;
  width: var(--slider-handle-size);
  height: var(--slider-handle-size);
  right: calc(var(--slider-handle-size) / -2);
  top: calc(var(--slider-handle-size) / -2);
  border-radius: 50%;
  background: var(--color-neutral-bg-level-1);
  cursor: pointer;
  transition: background-color 200ms;

  &::before {
    content: ' ';
    position: absolute;
    width: var(--slider-handle-size);
    height: var(--slider-handle-size);
    left: 0;
    top: 0;
    display: block;
    border-radius: 50%;
    background: var(--color-brand-primary-bg-strong);

    .slider__inner[disabled] & {
      background: var(--color-disabled-text);
    }
  }

  &::after {
    content: ' ';
    position: absolute;
    height: var(--slider-handle-inner-size);
    width: var(--slider-handle-inner-size);
    left: 50%;
    top: 50%;
    display: block;
    background: var(--color-brand-primary-text-onbg-strong);
    transform: translate(-50%, -50%);
    border-radius: 50%;

    .slider__inner[disabled] & {
      background: var(--color-neutral-bg-level-1);
    }
  }

  .slider__inner[disabled] & {
    cursor: default;
  }

  &.slider__hover::before {
    background: var(--color-brand-primary-bg-strong-hover);
  }
}

.slider__tooltip {
  display: block;
  position: absolute;
  right: auto;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(var(--slider-handle-size) * -1);
  text-align: center;
  font-weight: 700;
  font-size: var(--slider-font-size);
  line-height: var(--slider-line-height);
  white-space: nowrap;

  .slider__hover & {
    color: var(--color-brand-primary-text-hover);
  }
}

.slider__tooltip--left {
  left: 0;
  right: auto;
  transform: none;
}

.slider__tooltip--right {
  left: auto;
  right: 0;
  transform: none;
}

.slider__tooltip--collapsed {
  bottom: var(--slider-target-height);
}

.slider__tooltip--hidden {
  visibility: hidden;
}

.slider__connect {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
  will-change: transform;
  background-color: var(--color-brand-primary-bg-strong);

  .slider__inner[disabled] & {
    background: var(--color-disabled-bg);
  }

  .slider__state-tap & {
    transition: transform 0.3s;
  }
}

.slider__pips-wrapper {
  position: relative;
  width: 100%;
  margin-top: calc((var(--slider-handle-size) / 2) + (var(--slider-tooltip-padding) / 2));
  margin-bottom: 2.375rem;
  font-size: var(--slider-font-size);
  line-height: var(--slider-line-height);
}

.slider__pip {
  cursor: pointer;
  position: absolute;
  transform: translateX(-50%);

  &:hover {
    color: var(--color-brand-primary-text-hover);
  }
}

.slider__pip--active {
  color: var(--color-brand-primary-bg-strong);
  transition: color 0.3s;
}

.slider__pip--disabled,
.slider__pip--disabled:hover {
  color: var(--color-disabled-text);
  cursor: auto;
}

.slider__pip-inner {
  display: flex;
  font-weight: 700;
}

.slider__pip-label {
  max-width: 5.625rem;
}

.slider__pip-icon {
  font-size: 0.5rem;
}
