@use 'sass:map';

.header {
  position: fixed;
  pointer-events: none;
  z-index: map.get($zindex, header);
  top: 0;
  left: 0;
  right: 0;
  line-height: var(--type-line-height);

  .header__bottom-search-wrapper {
    @media (max-width: $media-width-lg-max) {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s ease-in;
    }

    @media (min-width: $media-width-xl-min) {
      transition: width 0.15s ease-in-out;
    }

    &:not(.is-open) {
      @media (max-width: $media-width-lg-max) {
        visibility: hidden;
        opacity: 0;
        transition:
          opacity 0.15s ease-out,
          visibility 0.15s ease-out,
          width 0.15s ease-out;
        width: 320px;
      }

      @media (min-width: $media-width-xl-min) {
        padding-left: 20px;
        width: 180px;
      }

      @media (max-width: $media-width-md-max) {
        position: relative;
        width: 100%;
      }
    }

    &.is-open {
      @media (min-width: $media-width-lg-min) {
        width: 320px;

        &::before {
          content: '';
          position: absolute;
          height: 45px;
          width: 60px;
          background: linear-gradient(to left, var(--color-neutral-bg-level-3) 0, var(--color-neutral-bg-level-3) 30px, transparent 100%);
          left: -45px;
        }
      }
    }
  }

  &.header--minimized-search {
    .header__bottom-search-wrapper:not(.is-open) {
      visibility: hidden;
      opacity: 0;
    }

    .header__actions-item--search {
      display: flex !important;
    }
  }
}

.header__main {
  position: static;
  z-index: 4;

  @media (min-width: $media-width-lg-min) {
    position: relative;
  }

  .header--animating-nav.header--has-overlay &,
  .header--open-nav.header--has-overlay & {
    @media (min-width: $media-width-lg-min) {
      position: relative;
    }
  }

  .header--open-sso & {
    @media (min-width: $media-width-lg-min) {
      position: static;
    }
  }

  .header--open-nav & {
    @media (max-width: $media-width-md-max) {
      position: relative;
    }
  }
}

.header__bottom-main {
  pointer-events: auto;
  position: relative;
  z-index: -1;
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);

  .header.has-scrolled & {
    pointer-events: none;
    transform: translateY(-100%);
  }

  &[data-state='exited'] {
    z-index: auto;
  }
}

.header__top {
  min-height: 48px;
  background-color: var(--color-neutral-bg-level-2);
  white-space: nowrap;

  @media (min-width: $media-width-sm-min) {
    overflow: auto;
  }
}

.header__top-inner {
  display: flex;
  justify-content: space-between;
}

.header__top-left {
  display: flex;
  flex-shrink: 1;
  min-width: 0;

  .header__employee & {
    min-width: auto;
  }
}

.header__top-right {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  overflow: hidden;
  gap: 3rem;

  @media (min-width: $media-width-sm-min) {
    flex-shrink: 0;
    overflow: visible;
  }

  .header__employee & {
    flex-shrink: 1;
    overflow: visible;
  }

  &.logged-in {
    flex-shrink: 1;
  }
}

.header__bottom {
  position: relative;
  background-color: var(--color-neutral-bg-level-3);
  padding: 0;
  z-index: 1;
}

.header__bottom-shadow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(100% + 2px);
  overflow: hidden;
  pointer-events: none;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 2px;
    box-shadow: $elevation-1;
  }
}

.header__bottom-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.header__bottom-left {
  display: flex;
  align-items: center;

  @media (min-width: $media-width-lg-min) {
    flex-grow: 0;
  }

  .header--open-search & {
    opacity: 0;

    @media (min-width: $media-width-sm-min) {
      opacity: 1;
    }
  }
}

.header__bottom-right-search {
  position: relative;
  width: 100%;
  height: 48px;

  @media (min-width: $media-width-xl-min) {
    max-width: 320px;
  }

  @media (max-width: $media-width-md-max) {
    position: absolute;
    width: calc(100% - $header-search-offset-left-md);
    right: $header-search-offset-right;
  }

  @media (max-width: $media-width-xs-max) {
    width: calc(100% - $header-search-offset-left-xs);
  }
}

.header__bottom-right {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;

  @media (max-width: $media-width-md-max) {
    position: absolute;
    right: $container-padding-sm;
    width: 100%;
  }

  @media (max-width: $media-width-xs-max) {
    right: $container-padding;
  }
}

.header__bottom-search-wrapper {
  position: absolute;
  right: 0;
  width: 100%;
  height: 45px;
  z-index: 1;

  @media (max-width: $media-width-lg-max) {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s ease-in;
  }

  @media (min-width: $media-width-xl-min) {
    transition: width 0.15s ease-in-out;
  }

  &:not(.is-open) {
    @media (max-width: $media-width-lg-max) {
      visibility: hidden;
      opacity: 0;
    }

    @media (min-width: $media-width-xl-min) {
      padding-left: 20px;
      width: 180px;
    }
  }

  &.is-open {
    @media (min-width: $media-width-lg-min) {
      width: 320px;

      &::before {
        content: '';
        position: absolute;
        height: 45px;
        width: 60px;
        background: linear-gradient(to left, var(--color-neutral-bg-level-3) 0, var(--color-neutral-bg-level-3) 30px, transparent 100%);
        left: -45px;
      }
    }
  }
}

.header__logo {
  display: block;
  position: relative;
  z-index: 1;
  margin-right: 36px;
  flex-shrink: 0;
  padding: 17px 0;

  @media (min-width: $media-width-lg-min) {
    padding: 21px 0;
  }

  [data-brand='diil'] &,
  [data-brand='super'] & {
    padding: 12px 0;

    @media (min-width: $media-width-lg-min) {
      padding: 16px 0;
    }
  }
}

.header__overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-neutral-bg-level-2);
  z-index: 3;
  pointer-events: auto;
  will-change: opacity;
  opacity: 0;
  transition: opacity 100ms ease-out;

  &.is-entered {
    opacity: 0.8;
  }
}

.header__accessibility {
  padding: 16px 8px;
  margin-left: -8px;
  margin-right: -8px;

  .header__top & {
    font-size: var(--type-tiny-font-size);
    line-height: var(--type-tiny-line-height);
    display: none;
    outline-offset: -5px;

    @media (min-width: $media-width-lg-min) {
      display: block;
    }
  }

  .header__dropdown & {
    outline-offset: -4px;

    @media (min-width: $media-width-lg-min) {
      display: none;
    }
  }

  @media (max-width: $media-width-md-max) {
    display: block;
  }
}

.list__content {
  .header__actions-item--cart > .header__dropdown .header__dropdown-content > .text > .list > .list__inner > .list__item > .list__item-inner > & {
    padding: 6px 8px;
  }
}
