@import '@telia-front/sass-tokens/index.scss';

$textfield-border-width: 1px;
$textfield-inner-gap: 8px;

.textfield {
  --textfield-border-color: var(--color-neutral-border);
  --textfield-shadow-size: 0px;
  --textfield-horizontal-padding: 12px;
  --textfield-horizontal-inner-padding: calc(var(--textfield-horizontal-padding) - #{$textfield-border-width});
  --textfield-icon-size: 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  width: 100%;
  max-width: 100%;

  &.is-focused {
    z-index: 2;
  }

  .table & {
    min-width: 180px;
  }
}

.textfield--center {
  align-items: center;
}

.textfield--small {
  --textfield-horizontal-padding: 8px;
  --textfield-icon-size: 1rem;
}

.textfield--employee {
  --textfield-border-color: var(--color-employee-border);
}

.textfield.is-invalid {
  --textfield-shadow-size: 1px;
  --textfield-border-color: var(--color-error-border);
}

.textfield.is-disabled {
  --textfield-border-color: var(--color-disabled-border);

  color: var(--color-disabled-text);
}

.textfield__inner {
  position: relative;
  width: 100%;
}

.textfield__image {
  position: absolute;
  width: var(--textfield-icon-size);
  left: var(--textfield-horizontal-padding);
  bottom: 50%;
  transform: translateY(50%);
}

.textfield__input {
  border: $textfield-border-width solid var(--textfield-border-color);
  border-radius: 4px;
  box-shadow: inset 0 0 0 var(--textfield-shadow-size) var(--textfield-border-color);
  display: block;
  font-size: var(--type-font-size);
  line-height: var(--type-line-height);
  font-weight: 400;
  margin: 0;
  padding: 12px var(--textfield-horizontal-inner-padding);
  width: 100%;
  background: transparent;
  color: var(--color-neutral-text);
  text-transform: none;
  @include transition(
    (
      'animation': short,
      'properties': (
        border-color,
        box-shadow,
      ),
    )
  );
  text-align: left;

  .textfield.is-disabled & {
    background-color: transparent;
    color: var(--color-disabled-text);
    -webkit-text-fill-color: var(--color-disabled-text);
    outline: none;
  }

  // Needed for when isFocused is controlled externally, e.g. in DateField.
  .textfield.is-focused & {
    @include keyboard-focus();
  }

  .textfield.is-static & {
    background: none;
    box-shadow: $elevation-0;
    height: auto;
    border: 0;
    padding-left: 0;
    padding-right: 0;
    outline: none;
  }

  &::-ms-clear {
    display: none;
  }

  .textfield--small & {
    padding: 5px var(--textfield-horizontal-inner-padding);
    font-size: var(--type-small-font-size);
    line-height: var(--type-small-line-height);
  }

  .textfield--large & {
    padding: 15px 24px;
    font-size: var(--type-h4-xl-font-size);
    line-height: var(--type-h4-xl-line-height);
    font-weight: 700;
  }

  .textfield--center & {
    text-align: center;
  }

  $with-icon-padding: calc(var(--textfield-icon-size) + $textfield-inner-gap + var(--textfield-horizontal-inner-padding));

  .textfield--with-icon & {
    padding-right: $with-icon-padding;
  }

  .textfield--center.textfield--with-icon & {
    padding-left: $with-icon-padding;
  }

  .textfield--image & {
    padding-left: $with-icon-padding;
  }
}

.textfield__label {
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--color-neutral-text);
  font-size: var(--type-small-font-size);
  line-height: var(--type-small-line-height);
  text-align: left;
  padding: 0;
  @include transition(
    (
      'animation': short,
      'properties': (
        color,
      ),
    )
  );
  font-weight: 400;

  .textfield--center & {
    justify-content: center;
  }

  .textfield.is-disabled & {
    color: var(--color-disabled-text);
  }

  .textfield--no-label-no-break & {
    @include visually-hidden();
  }

  .textfield--no-label-sm & {
    @media (min-width: $media-width-sm-min) {
      @include visually-hidden();
    }
  }

  .textfield--no-label-md & {
    @media (min-width: $media-width-md-min) {
      @include visually-hidden();
    }
  }
}

.textfield__label-icon {
  font-size: 1rem;
  color: var(--color-employee-graphic);
}

.textfield__icon {
  @include reset-icon();

  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: var(--textfield-horizontal-padding);
  font-size: var(--textfield-icon-size);
  pointer-events: none;
}

.textfield__clear-btn {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: var(--textfield-horizontal-padding);

  .textfield--small & {
    right: 4px;
  }
}

.textfield__textarea-input {
  resize: none;
}
