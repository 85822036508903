@import '@telia-front/sass-tokens/index.scss';

.textarea__input {
  display: block;
  resize: none;
  height: 8.5rem; // Fallback for browsers that do not support lh units, can be removed once support is better for our browserslist: https://caniuse.com/mdn-css_types_length_lh
  // border width + padding + 5 line height
  height: calc(2px + 24px + 5lh);
  font-weight: 400;
  overflow: hidden;

  .textarea--max-lines & {
    overflow: auto;
  }

  .textarea--small & {
    height: 3rem; // Fallback for browsers that do not support lh units, can be removed once support is better for our browserslist: https://caniuse.com/mdn-css_types_length_lh
    // border width + padding + line height
    height: calc(2px + 24px + 1lh);
  }
}

.textarea__hidden {
  @include visually-hidden();
  width: 100% !important;
}
