@import '@telia-front/sass-tokens/index.scss';

@mixin channel-size($size, $padding, $size-sm: null, $padding-sm: null) {
  width: $size;
  height: $size;
  padding: $padding;

  @if $size-sm {
    @media (min-width: $media-width-sm-min) {
      height: $size-sm;
      width: $size-sm;

      @if $padding-sm {
        padding: $padding-sm;
      }
    }
  }

  &::before {
    width: $size;
    height: $size;

    @if $size-sm {
      @media (min-width: $media-width-sm-min) {
        height: $size-sm;
        width: $size-sm;
      }
    }
  }
}

.channel {
  z-index: 1;
  position: relative;
  display: inline-block;
  background: var(--color-neutral-bg-level-2);
  border: 3px solid var(--color-neutral-bg-level-2);
  border-radius: 100%;
  @include channel-size(35px, 6px);

  &::before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    z-index: -1;
    background: var(--color-neutral-bg-level-3);
    border-radius: 100%;
    border: 2px solid var(--color-neutral-bg-level-2);
    transform: scale(0);
    transition: transform 300ms;
    transition-timing-function: cubic-bezier(0.55, 0.05, 0.67, 0.19);
  }

  &:hover::before {
    transform: scale(1);
    transition-timing-function: cubic-bezier(0.22, 0.61, 0.35, 1);
  }
}

.channel--color-inverse {
  background: var(--color-neutral-bg-level-1);
  border-color: var(--color-neutral-bg-level-1);

  &::before {
    background: var(--color-neutral-bg-level-2);
    border-color: var(--color-neutral-bg-level-1);
  }
}

.channel--size-small {
  @include channel-size(50px, 10px);
}

.channel--size-medium {
  @include channel-size(50px, 10px, 80px, 15px);
}

.channel--size-large {
  @include channel-size(50px, 10px, 120px, 20px);
}

.channel__image-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.channel__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
