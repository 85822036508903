@import '@telia-front/sass-tokens/index.scss';

.service-cards {
  position: relative;

  .service-cards__item-col {
    margin-bottom: 32px;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
}

.service-cards__inner {
  margin-left: auto;
  margin-right: auto;

  @for $count from 1 through 8 {
    .service-cards--items-#{$count} & {
      max-width: #{9.5 * $count}rem;
    }
  }
}

.service-cards__item {
  font-weight: 700;
  line-height: var(--type-line-height);
  font-size: var(--type-font-size);
  text-align: center;
  padding-right: 12px;
  padding-left: 12px;
  width: 9.5rem;
}

.service-cards__item,
.service-cards__item-inner {
  display: flex;
  flex-direction: column;
}

.service-cards__icon {
  margin-top: 0;
  width: 2rem;
  height: 2rem;
}
