@use 'sass:map';
@use 'sass:math';

@import '@telia-front/sass-tokens/index.scss';

$progress-icon-size: 32px;
$progress-icon-size-sm: 24px;
$progress-counter-size: 8px;
$progress-current-counter-size: 32px;
$progress-current-counter-mobile-size: 24px;
$progress-indicator-line-position: 11px;
$progress-indicator-line-width: 2px;

@mixin progress-inline-break($progress-break: $media-width-sm-min, $break: 'sm') {
  @media only screen and (min-width: $progress-break) {
    .progress__steps {
      .progress.has-break-#{$break} & {
        display: flex;
        align-items: flex-end;
      }
    }

    .progress__item {
      .progress.has-break-#{$break} & {
        flex: 1 1 0;
        display: inline-block;
        margin-top: 0;

        &:first-child {
          flex-grow: 0.5;
        }

        &:last-child {
          flex-grow: 0.5;
        }
      }
    }

    .progress__header {
      .progress.has-break-#{$break} & {
        padding-left: 0;
        padding-bottom: 48px;
        text-align: center;
        margin-top: 0;

        &::before {
          width: 100%;
          height: $progress-indicator-line-width;
          top: auto !important;
          bottom: math.div($progress-current-counter-size - $progress-indicator-line-width, 2);
          right: 50%;
          left: auto;
        }

        &::after {
          display: none;
        }
      }

      .progress.has-break-#{$break} .progress__item:last-child > & {
        &::before {
          top: auto;
          right: 0;
          width: 200%;
        }
      }

      .progress.has-break-#{$break} .progress__item.is-current & {
        &::before {
          background: var(--color-neutral-border-subtlest);
        }
      }

      .progress.has-break-#{$break} .progress__item.is-done & {
        &::before {
          background: var(--color-success-bg-subtle);
        }

        &::after {
          background-color: var(--color-success-bg-subtle);
        }
      }

      .progress.has-break-#{$break} .progress__item.is-current + & {
        &::before {
          display: block;
        }
      }

      .progress.has-break-#{$break} .progress__item:first-child & {
        text-align: left;
      }

      .progress.has-break-#{$break} .progress__item:last-child & {
        text-align: right;
      }
    }

    .progress__counter {
      .progress.has-break-#{$break} & {
        top: auto;
        bottom: 0;
        left: 50%;
        margin-left: -1 * math.div($progress-current-counter-size, 2);
      }

      .progress.has-break-#{$break} .progress__item:first-child & {
        left: 0;
        margin-left: 0;
      }

      .progress.has-break-#{$break} .progress__item:last-child & {
        left: 100%;
        margin-left: -$progress-current-counter-size;
      }
    }

    .progress__title {
      .progress.has-break-#{$break} & {
        padding-left: 10px;
        padding-right: 10px;
      }

      .progress.has-break-#{$break} .progress__item:first-child & {
        padding-left: 0;
      }

      .progress.has-break-#{$break} .progress__item:last-child & {
        padding-right: 0;
      }
    }

    .progress__error {
      .progress.has-break-#{$break} & {
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        margin-top: 10px;
      }

      .progress.has-break-#{$break} .progress__item:first-child & {
        text-align: left;
      }

      .progress.has-break-#{$break} .progress__item:last-child & {
        text-align: right;
      }
    }

    .progress__info {
      .progress.has-break-#{$break} & {
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        margin-top: 10px;
      }

      .progress.has-break-#{$break} .progress__item:first-child & {
        text-align: left;
      }

      .progress.has-break-#{$break} .progress__item:last-child & {
        text-align: right;
      }
    }

    .progress__content {
      .progress.has-break-#{$break} & {
        margin-left: 0;
        border: 0;
        padding: 15px 0;
      }

      .progress.has-break-#{$break} .progress__item & {
        display: none !important;
      }
    }
  }
}

@include progress-inline-break($media-width-sm-min, 'sm');
@include progress-inline-break($media-width-md-min, 'md');
@include progress-inline-break($media-width-lg-min, 'lg');
@include progress-inline-break($media-width-xl-min, 'xl');

.progress.progress--card {
  position: relative;
  display: flex;
  flex-direction: column;

  @media (min-width: $media-width-md-min) {
    align-items: flex-start;
    flex-direction: row;
  }
}

.progress__steps {
  @include list-unstyled();

  position: relative;
  z-index: 1;
  counter-reset: progress-counter;

  .progress--card & {
    width: 280px;
    flex-direction: column;
    padding-left: 0;
    flex: 0 0 auto;
    border-radius: 3px;
    background: var(--color-neutral-bg-level-1);

    & > li:first-child {
      border-radius: 8px 8px 0 0;
    }

    & > li:last-child {
      border-radius: 0 0 8px 8px;
    }

    @media (max-width: $media-width-sm-max) {
      height: 100%;
      width: 100%;
      margin-top: 1rem;
    }

    @media (min-width: $media-width-xl-min) {
      width: 380px;
    }
  }
}

.progress__category {
  font-weight: 700;
  padding: 12px;
  color: var(--color-neutral-text);
  width: 100%;
  border-top: 1px solid var(--color-neutral-bg-level-1);
  background: linear-gradient(0deg, var(--color-neutral-bg-subtlest) 0%, var(--color-neutral-bg-subtlest) 100%), var(--color-neutral-bg-level-2);

  &:first-child {
    border-top: 0;
  }
}

.progress__item {
  counter-increment: progress-counter;
  position: relative;
  list-style: none;

  .progress--card & {
    width: 100%;
    border-top: 1px solid var(--color-neutral-bg-level-1);
    padding: 12px;
    position: relative;
    background: var(--color-neutral-bg-level-2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;

    &.progress__item--employee {
      background-color: var(--color-employee-bg-subtlest);
    }

    &.is-disabled {
      pointer-events: none;
      color: var(--color-neutral-text-subtle);
    }

    &.is-current:not(.has-subitems) {
      background: var(--color-neutral-bg-level-1);
      border-top: 0;

      + .progress__item {
        border-top: 0;
      }
    }

    &.has-subitems {
      @media (min-width: $media-width-md-min) {
        padding: 16px;
      }
    }

    @media (min-width: $media-width-md-min) {
      padding: 16px;
      min-height: 74px;
    }
  }
}

.progress__item.progress__item--uncategorized {
  background: var(--color-neutral-bg-level-2);
}

.progress__sub-steps {
  @include list-unstyled();

  margin: 12px -12px -12px;
  position: relative;
  z-index: 1;
  counter-reset: progress-counter;
  background: var(--color-neutral-bg-level-2);

  .progress__item--employee & {
    background-color: var(--color-neutral-bg-level-1);
  }

  @media (min-width: $media-width-md-min) {
    margin: 16px -16px -16px;
  }
}

.progress__sub-item {
  position: relative;
  padding-left: 12px;
  display: flex;
  align-items: center;

  &.is-current {
    background: var(--color-neutral-bg-level-1);
  }

  &.is-disabled {
    color: var(--color-neutral-text-subtle);
    pointer-events: none;
  }

  &.progress__sub-item--employee {
    background: var(--color-employee-bg-subtlest);
  }

  &.progress__sub-item--employee.is-current {
    background: var(--color-neutral-bg-level-1);
  }

  &::before {
    content: counters(progress-counter, '.');
    counter-increment: progress-counter;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $progress-current-counter-size;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--type-h5-font-size);
    line-height: var(--type-h5-line-height);
  }

  &.is-current::before {
    color: var(--color-neutral-text);
  }

  &.progress__sub-item--employee.is-current::before {
    color: var(--color-employee-text);
  }

  @media (min-width: $media-width-md-min) {
    min-height: 60px;
    padding-left: 16px;
  }
}

.progress__header {
  @include reset-button();

  display: block;
  position: relative;
  padding-left: 44px;
  margin-top: 32px;
  min-height: $progress-current-counter-size;

  @media (min-width: $media-width-sm-min) {
    padding-left: 52px;
  }

  &::before {
    content: '';
    display: block;
    width: $progress-indicator-line-width;
    position: absolute;
    z-index: -1;
    top: -32px;
    left: $progress-indicator-line-position;
    bottom: 100%;
    background: var(--color-neutral-border-subtlest);

    @media (min-width: $media-width-sm-min) {
      left: 16px;
    }
  }

  &::after {
    content: '';
    display: block;
    width: $progress-indicator-line-width;
    position: absolute;
    z-index: -1;
    top: $progress-current-counter-mobile-size;
    left: $progress-indicator-line-position;
    bottom: 0;
    background: var(--color-neutral-border-subtlest);

    @media (min-width: $media-width-sm-min) {
      top: $progress-current-counter-size;
      left: 16px;
    }
  }

  .progress__item:first-child & {
    margin-top: 0;

    &::before {
      display: none;
    }
  }

  .progress__item:last-child & {
    &::after {
      display: none;
    }
  }

  .progress__item.is-current + .progress__item & {
    margin-top: 0;

    &::before {
      top: -15px;
    }
  }

  .progress--bottom .progress__item:last-child & {
    &::after {
      display: block;
    }
  }

  .progress--bottom .progress__item.is-current + .progress__item & {
    margin-top: 32px;

    &::before {
      display: block;
      top: -32px;
    }
  }

  .progress--card & {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 22px;
    padding-left: 36px;
    margin-top: 0;

    &:first-child {
      text-align: left;
    }

    &::after,
    &::before {
      content: none;
    }

    @media (min-width: $media-width-md-min) {
      padding-left: 48px;
    }
  }
}

button.progress__header {
  html[data-whatintent='mouse'] .progress--card & {
    &:hover {
      color: var(--color-brand-primary-text-hover);
    }
  }
}

.progress__header-inner {
  .progress--card & {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 4px;

    @media (min-width: $media-width-xs-min) {
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
    }
  }
}

.progress__sub-header-inner {
  .progress--card & {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    border-top: 1px solid var(--color-neutral-bg-level-1);
    padding: 12px 12px 12px 0;

    @media (min-width: $media-width-md-min) {
      padding: 16px 16px 16px 0;
    }
  }

  .progress--card .progress__sub-item.is-current + .progress__sub-item & {
    border-top: 0;
  }

  .progress--card .progress__sub-item.is-current & {
    border-top: 0;
  }
}

.progress__header-title-section {
  .progress--card & {
    flex: 1 1 auto;
  }
}

.progress__counter {
  display: flex;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 24px;
  font-size: 22px;
  color: $white;
  background-color: var(--color-neutral-bg-level-1);
  border-radius: 50%;

  .progress--card & {
    top: 50%;
    transform: translateY(-50%);
    background-color: unset;

    & .progress__badge {
      @media (max-width: $media-width-sm-max) {
        padding: 4px;
        min-width: 24px;
        line-height: 16px;
        font-size: $badge-font-size-large-sm;
        font-weight: $badge-font-weight-large-sm;
      }

      & .badge__icon {
        width: $badge-size-large-icon-width;
        height: $badge-size-large-icon-height;

        @media (max-width: $media-width-sm-max) {
          width: $badge-font-size-large-sm;
          height: $badge-font-size-large-sm;
        }
      }
    }
  }
}

.progress__title {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.375;

  @media (min-width: $media-width-sm-min) {
    font-size: 22px;
    font-weight: 400;
  }

  .progress__item.is-done.progress__item--employee &,
  .progress__item.is-current.progress__item--employee & {
    color: var(--color-employee-text);
  }

  .progress--card & {
    font-size: 16px;
    font-weight: 700;
  }

  .progress__item.is-disabled & {
    pointer-events: none;
    color: var(--color-neutral-text-subtle);
  }
}

.progress__sub-title {
  display: flex;
  font-weight: 700;
  align-items: flex-start;

  .progress__sub-item.is-current & {
    color: var(--color-neutral-text);
  }

  .progress__sub-item.is-current.progress__sub-item--employee & {
    color: var(--color-employee-text);
  }
}

.progress__error {
  display: none;
  color: var(--color-error-text);
  font-size: 12px;
  font-weight: 700;

  .progress__item.is-invalid & {
    display: block;
  }
}

.progress__info {
  display: block;
  font-size: 14px;

  .progress--card & {
    text-overflow: clip;
    position: relative;
    overflow: hidden;

    @media (min-width: $media-width-md-min) {
      font-size: 14px;
    }
  }
}

.progress__icon {
  @include reset-icon();

  width: $progress-icon-size-sm;
  height: $progress-icon-size-sm;

  @media (min-width: $media-width-md-min) {
    height: $progress-icon-size;
    width: $progress-icon-size;
  }

  .progress__item.is-disabled.has-icon & {
    color: var(--color-neutral-text-subtle);
  }
}

.progress__content {
  display: block;
  position: relative;
  margin-top: 14px;
  margin-bottom: 24px;
  border: 2px solid var(--color-neutral-border-subtlest);
  border-radius: 3px;
  padding: 15px;

  .progress--bottom &,
  .progress__item.is-current & {
    display: block;

    &::after {
      content: '';
      display: block;
      width: $progress-indicator-line-width;
      position: absolute;
      z-index: -1;
      top: -16px;
      left: $progress-indicator-line-position - $progress-indicator-line-width;
      bottom: calc(100% + 2px);
      background: var(--color-neutral-border-subtlest);

      @media (min-width: $media-width-sm-min) {
        left: 16px - $progress-indicator-line-width;
      }
    }

    &::before {
      content: '';
      display: block;
      width: $progress-indicator-line-width;
      position: absolute;
      z-index: -1;
      top: calc(100% + 2px);
      bottom: -11px;
      left: $progress-indicator-line-position - $progress-indicator-line-width;
      background: var(--color-neutral-border-subtlest);

      @media (min-width: $media-width-sm-min) {
        left: 16px - $progress-indicator-line-width;
      }
    }
  }

  .progress--bottom & {
    &::before {
      display: none;
    }
  }

  .progress--card & {
    border: 0;
    padding: 0 0 0 20px;
    margin: 0;
    width: 100%;

    @media (max-width: $media-width-sm-max) {
      padding-left: 0;
      max-width: 100%;
      margin-top: 1rem;
    }

    @media (min-width: $media-width-lg-min) {
      min-width: calc(100% - 280px);
      max-width: calc(100% - 280px);
    }

    @media (min-width: $media-width-xl-min) {
      min-width: calc(100% - 380px);
      max-width: calc(100% - 380px);
    }
  }
}

.progress__header-meta-section {
  font-size: 16px;
  color: var(--color-neutral-text);
  white-space: nowrap;
  display: block;
  text-align: right;
}

.progress__sub-badge {
  margin-left: 5px;
}

.progress__footer {
  border-top: 1px solid var(--color-neutral-bg-level-1);
  padding: 12px;
  width: 100%;
  background-color: var(--color-neutral-bg-level-2);

  @media (min-width: $media-width-md-min) {
    padding: 16px;
  }
}

// progress mobile steps
.progress__indicator-list {
  @include list-unstyled();

  display: flex;
}

.progress__collapse-button {
  @include reset-button();
  @include button-link-variant(var(--color-brand-primary-text), var(--color-brand-primary-text-hover), var(--color-brand-primary-text-hover));
}

.progress__indicator-list-icon {
  transition: transform 0.3s ease-in-out;

  &.is-open {
    transform: rotate(-180deg);
  }
}

.progress__indicator-list-item {
  display: block;
  position: relative;
  min-height: $progress-current-counter-size;
  flex: 1 1 0;

  &:first-child {
    &.is-current {
      margin-left: calc($progress-current-counter-size / 2 - $progress-counter-size / 2);
    }
  }

  &:last-child {
    flex-grow: 0;
    margin-right: $progress-current-counter-mobile-size;

    &.is-current {
      margin-right: $progress-current-counter-size;

      @media (min-width: $media-width-sm-min) {
        margin-right: calc($progress-current-counter-size + $progress-counter-size);
      }
    }

    &::before {
      content: none;
    }
  }

  &::before {
    content: '';
    width: 100%;
    height: $progress-indicator-line-width;
    display: block;
    position: absolute;
    background-color: var(--color-neutral-border-subtlest);
    top: 50%;
    transform: translateY(-50%);
  }

  &.is-done {
    &::before {
      background: var(--color-success-bg-subtle);
    }
  }
}

.progress__indicator-step-wrapper {
  top: 50%;
  position: absolute;

  .progress__indicator-list-item.is-current & {
    left: calc(($progress-current-counter-size / 2 - $progress-counter-size / 2) * -1);
    transform: translateY(-50%);
  }
}

.progress__dot {
  position: absolute;
  height: $progress-counter-size;
  width: $progress-counter-size;
  border-radius: 100%;
  background-color: var(--color-neutral-border);
}

.progress__dot--done {
  background-color: var(--color-success-graphic);
}

.progress__dot--employee.progress__dot--disabled {
  background-color: var(--color-employee-bg-subtlest);
}

.progress__dot--employee.progress__dot--done {
  background-color: var(--color-employee-text);
}

.progress__dot-background {
  background-color: var(--color-neutral-bg-level-1);
  position: absolute;
  width: $progress-counter-size;
  height: $progress-counter-size;
  border-radius: 100%;
  transform: translateY(-50%);
}
