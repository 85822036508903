@import '@telia-front/sass-tokens/index.scss';

$countdown-gutter: 8px;
$countdown-gutter-lg: 16px;
$countdown-time-transform: translate(-50%, -50%);

.countdown {
  display: flex;
  flex-wrap: wrap;
  margin-right: $countdown-gutter * 0.5 * -1;
  margin-left: $countdown-gutter * 0.5 * -1;
  margin-bottom: $countdown-gutter * -1;

  @media (min-width: $media-width-xl-min) {
    margin-right: $countdown-gutter-lg * 0.5 * -1;
    margin-left: $countdown-gutter-lg * 0.5 * -1;
    margin-bottom: $countdown-gutter-lg * -1;
  }
}

.countdown__item {
  display: inline-block;
  text-align: center;
  padding-right: $countdown-gutter * 0.5;
  padding-left: $countdown-gutter * 0.5;
  padding-bottom: $countdown-gutter;

  @media (min-width: $media-width-xl-min) {
    padding-right: $countdown-gutter-lg * 0.5;
    padding-left: $countdown-gutter-lg * 0.5;
    padding-bottom: $countdown-gutter-lg;
  }
}

.countdown__item-inner {
  position: relative;
  border-radius: 8px;
  width: 3.5rem;
  height: 3.25rem;

  @media (min-width: $media-width-sm-min) {
    width: 4rem;
    height: 3.75rem;
  }

  &:not(.countdown__item-inner--custom-color) {
    color: var(--color-brand-primary-text-onbg-strong);
  }

  &:not(.countdown__item-inner--custom-background) {
    background-color: var(--color-brand-primary-bg-strong);
  }
}

.countdown__item-unit {
  margin-top: 5px;
  color: var(--color-neutral-text);
}

.countdown__item-time {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: $countdown-time-transform;
}

.countdown__item-time--animate {
  &-enter {
    opacity: 0;
    transform: $countdown-time-transform translateY(-10px);
  }

  &-enter-active {
    opacity: 1;
    transform: $countdown-time-transform translateY(0);
    transition:
      opacity 100ms ease-in,
      transform 100ms ease-in;
  }

  &-exit-active {
    opacity: 0;
    transform: $countdown-time-transform translateY(10px);
    transition:
      opacity 100ms ease-out,
      transform 100ms ease-out;
  }
}
