@import '@telia-front/sass-tokens/index.scss';

$horizontal-progress-title-color: var(--color-neutral-text-subtle);
$horizontal-progress-title-color-current-done: var(--color-neutral-text);
$horizontal-progress-indicator-line-inactive-color: var(--color-neutral-border-subtlest);
$horizontal-progress-indicator-line-success-color: var(--color-success-border);
$horizontal-progress-content-border-color: var(--color-neutral-border-subtlest);

.horizontal-progress {
  margin-right: -7px;
  margin-left: -7px;

  &.is-horizontal {
    margin-right: -10px;
    margin-left: -10px;
  }
}

.horizontal-progress__steps {
  @include list-unstyled();

  position: relative;
  z-index: 1;
  counter-reset: progress-counter;

  .horizontal-progress.is-horizontal & {
    display: flex;
    align-items: flex-start;
  }
}

.horizontal-progress__item {
  counter-increment: progress-counter;
  position: relative;
  margin-top: 40px;
  padding-right: 7px;
  padding-left: 7px;

  &:first-child {
    margin-top: 0;
  }

  .horizontal-progress__item.is-current + & {
    margin-top: 0;
  }

  .horizontal-progress.is-horizontal & {
    flex: 1 1 0%;
    display: inline-block;
    margin-top: 0;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.horizontal-progress__header {
  @include reset-button();

  display: block;
  position: relative;
  padding-left: 50px;
  min-height: 34px;

  &::after {
    content: '';
    display: block;
    width: 2px;
    position: absolute;
    z-index: -1;
    top: -15px;
    left: 15px;
    bottom: -25px;
    background: $horizontal-progress-indicator-line-inactive-color;

    @media (max-width: $media-width-xs-max) {
      left: 11px;
    }
  }

  .horizontal-progress__item:first-child & {
    &::after {
      top: 0;

      @media (max-width: $media-width-xs-max) {
        top: 5px;
      }
    }
  }

  .horizontal-progress__item:last-child & {
    &::after {
      bottom: 16px;
    }
  }

  .horizontal-progress__item.is-current & {
    &::after {
      top: 28px;
      bottom: -15px;
    }
  }

  .horizontal-progress__item.is-done & {
    &::after {
      top: 28px;
      bottom: -46px;
      background: $horizontal-progress-indicator-line-success-color;
    }
  }

  .horizontal-progress.is-horizontal & {
    padding-left: 0;
    padding-bottom: 44px;
    text-align: left;

    &::after {
      width: 100%;
      height: 2px;
      top: auto;
      bottom: 15px;
      left: 20px;
    }
  }

  .horizontal-progress.is-horizontal .horizontal-progress__item:last-child &::after {
    display: none;
  }

  .horizontal-progress.is-horizontal .horizontal-progress__item.is-current &::after {
    bottom: 15px;
  }

  .horizontal-progress.is-horizontal .horizontal-progress__item.is-done & {
    &::after {
      background: $horizontal-progress-indicator-line-success-color;
    }
  }
}

.horizontal-progress__badge-wrapper {
  background-color: var(--color-neutral-bg-level-1);
  border-radius: 100rem;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  @media (max-width: $media-width-xs-max) {
    top: 5px;
  }

  .horizontal-progress.is-horizontal & {
    top: auto;
    bottom: 0;
    left: 0;
    margin-left: 0;
  }
}

.horizontal-progress__counter {
  &::before {
    content: counter(progress-counter);
  }

  .horizontal-progress__item.is-done &::before {
    display: none;
  }
}

.horizontal-progress__title {
  color: $horizontal-progress-title-color;
  padding: 6px 0 0;
  font-size: 16px;

  .horizontal-progress__item.is-done &,
  .horizontal-progress__item.is-current & {
    color: $horizontal-progress-title-color-current-done;
  }

  .horizontal-progress.is-horizontal & {
    padding: 0;
    font-size: 20px;
  }
}

.horizontal-progress__button {
  text-align: left;
  padding: 0;
}

button.horizontal-progress__button {
  @include reset-button();
}

.horizontal-progress__content {
  display: none;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 2px solid $horizontal-progress-content-border-color;
  border-radius: 3px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: $horizontal-progress-title-color;

  .horizontal-progress__item.is-current & {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    color: $horizontal-progress-title-color-current-done;

    @media (max-width: $media-width-xs-max) {
      margin-left: -5px;
    }
  }

  .horizontal-progress__item.is-done & {
    color: $horizontal-progress-title-color-current-done;
  }

  .horizontal-progress.is-horizontal & {
    display: block;
    border: none;
    text-align: left;
  }

  .horizontal-progress.is-horizontal .horizontal-progress__item.is-current & {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
}
