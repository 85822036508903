@use 'sass:color';
@use 'sass:math';
@import '@telia-front/design-tokens/lib/scss/variables.scss';
@import '@telia-front/design-tokens/lib/scss/telia-light.scss';

// Variables

// Page maximum width
$page-max-width: 80.5rem; // 1288px

// Breakpoints

// Extra small (320px - 599px)
// Use the modifier "-xs" when defining classes.
$media-width-xs-min: 20rem;
$media-width-xs-max: 37.499rem;

// Small (600px - 799px)
// Use the modifier "-sm" when defining classes.
$media-width-sm-min: 37.5rem;
$media-width-sm-max: 49.999rem;

// Medium (800px - 999px)
// Use the modifier "-md" when defining classes.
$media-width-md-min: 50rem;
$media-width-md-max: 62.499rem;

// Large (1000px - 1288px)
// Use the modifier "-lg" when defining classes.
$media-width-lg-min: 62.5rem;
$media-width-lg-max: 80.499rem;

// Extra large (1288px - 1449px)
// Use the modifier "-xl" when defining classes.
$media-width-xl-min: 80.5rem;
$media-width-xl-max: 90.624rem;

// 2xExtra large (1450px - ...)
// Use the modifier "-xxl" when defining classes.
$media-width-xxl-min: 90.625rem;

// Color definitions

// Main brand colors
$purple: $color-raw-purple-base;
$purple-l: $color-raw-purple-light;

// Commerce flow colors
$green: $color-raw-green-base;
$green-l: $color-raw-green-light;

// Info colors
$blue: $color-raw-blue-base;
$blue-l: $color-raw-blue-light;

// Additional colors
$red: $color-raw-red-base;

$orange: $color-raw-orange-base;

$pink: $color-raw-pink-base;
$pink-l: $color-raw-pink-light;

// Neutral colors
// Background colors
$white: $color-raw-white-base; // white background
$grey: $color-raw-grey-base; // tables and lines
$grey-l: $color-raw-grey-light; // default background
$grey-m: $color-raw-grey-light; // tables and lines
$grey-b: $color-raw-grey-black; // dark background

// Text colors
$grey-d: $color-raw-grey-dark; // grey text and input field line
$grey-t: $color-raw-black-strong; // text, headings
$black: $color-raw-black-base; // for graphics

// Grid
$grid-columns: 12; // used also in table
$grid-gutter: 24px; // used also in collapse-layer, lightbox, modal, tabs

// container
$container-padding: 16px; // used also in accordion, carousel, header, header-dropdown, header-segment, notice, section
$container-padding-sm: 24px; // used also in carousel, header, header-segment, notice, section
$container-padding-lg: calc(24px * 4 / 3); // used also in carousel, header-dropdown, header-segment, notice, section

$transition-default: 150ms ease; // used in arrow-list, header-nav, header-segment, product-comparison, progress-bar, suggestion

// z-index
$zindex: (
  datepicker: 100,
  carousel-arrow: 100,
  tooltip-on-header: 32,
  tooltip: 32,
  datepickers-container-scroll-disabled: 31,
  select-menu: 31,
  notice-on-top: 30,
  modal: 30,
  modal-footer: 30,
  lightbox: 30,
  page-container: 22,
  filter-sidemenu-open: 22,
  header-dropdown: 22,
  progress-card-sidebar-open: 21,
  header: 20,
  progress-card-sidebar: 19,
  suggestion: 19,
  dropdown-menu: 19,
  filter-sidemenu: 19,
  horizontal-filter-dropdown: 19,
  filter-backdrop: 18,
  dropdown-backdrop: 18,
  overlay: 15,
  fixed-buttons: 15,
  collapse-modal: 10,
  header-backdrop: 10,
  header-backdrop-desktop: 9,
  header-backdrop-nav: 9,
  product-comparison-bar: 4,
  scroll-to-top-button: 3,
  form-address-search: 2,
  sidemenu: 2,
  badge: 2,
  affix: 2,
  breadcrumbs: 1,
  carousel: 1,
  search-large: 1,
  carousel-hero: 0,
);

// Base spacing
$base-horizontal-spacing-xs: 15px;
$base-horizontal-spacing: 20px;

// Badge
$badge-padding: 7px;
$badge-line-height: 1rem; // must be in rems so it could be used in calc
$badge-height: calc(#{$badge-padding * 2} + $badge-line-height); // used in card
$badge-font-weight-large-sm: 700; // used in progress
$badge-font-size-large-sm: 1rem; // used in progress
$badge-size-large-icon-height: 1.375rem; // used in progress
$badge-size-large-icon-width: 1.25rem; // used in progress

// Button variants
$btn-min-height: 48px; // used also number
$btn-border-width: 1px; // used in mixins
$btn-min-horizontal-padding: 24 - $btn-border-width;

$btn-small-min-height: 32px; // used also number
$btn-small-line-height: 1.2; // used also mixins
$btn-small-padding: 2px; // used also mixins
$btn-small-min-horizontal-padding: 16 - $btn-border-width; // used also mixins
$btn-small-border-radius: 16px; // used also mixins
$btn-small-icon-padding: 16px - $btn-border-width; // used also mixins
$btn-small-icon-spacing: 6px; // used also mixins

// Elevations
$elevation-0: 0 0 0 0 var(--color-neutral-border-subtlest);
$elevation-1:
  0 0 0 0.5px var(--color-neutral-border-subtlest) inset,
  0 1px 2px 0 rgba(0, 0, 0, 0.1);
$elevation-2:
  0 0 0 0.5px var(--color-neutral-border-subtlest) inset,
  0 2px 4px rgba(0, 0, 0, 0.1);
$elevation-3:
  0 0 0 0.5px var(--color-neutral-border-subtlest) inset,
  0 4px 8px rgba(0, 0, 0, 0.1);
$elevation-4:
  0 0 0 0.5px var(--color-neutral-border-subtlest) inset,
  0 8px 16px rgba(0, 0, 0, 0.1);
$elevation-5:
  0 0 0 0.5px var(--color-neutral-border-subtlest) inset,
  0 12px 24px rgba(0, 0, 0, 0.1);

// Icon
$icon-font-size-xxs: 1rem; // used also button
$icon-font-size-xs: 1.5rem; // used also button
$icon-font-size-sm: 2rem; // used also button
$icon-font-size-md: 2.5rem; // used also button
$icon-font-size-lg: 3rem; // used also button
$icon-font-size-xl: 3.5rem; // used also button
$icon-font-size-xxl: 4rem; // used also button
$icon-font-size-xxxl: 6rem; // used also button

// Modal
$modal-horizontal-spacing-xs: 16px; // used also tabs

// Header
$header-search-close-button-margin-left: 8px; // used also header-actions
$header-search-close-button-width: 32px;
$header-search-offset-right: $header-search-close-button-margin-left + $header-search-close-button-width;
$header-search-offset-left-xs: $header-search-offset-right + $container-padding * 2;
$header-search-offset-left-md: $header-search-offset-right + $container-padding-sm * 2;
$header-scrolled-height: 40px; // used also nav and affix

// Card
$card-padding-none: 0px; // stylelint-disable-line
$card-padding-xsmall: 8px; // used also carousel, separator, tabs
$card-padding-small: 16px; // used also carousel, separator, tabs
$card-padding-medium: 24px; // used also carousel, separator, tabs
$card-padding-large: 32px; // used also carousel, separator, tabs
$card-padding-xlarge: 48px; // used also carousel, separator, tabs
$card-padding-xxlarge: 64px; // used also carousel, separator, tabs

$card-elevation-0: $elevation-0; // used also option-card
$card-elevation-1: $elevation-1;
$card-elevation-2: $elevation-2;
$card-elevation-3: $elevation-3; // used also option-card
$card-elevation-4: $elevation-4;
$card-elevation-5: $elevation-5; // used also option-card

$card-border-width: 1px; // used also option-card
$card-border-selected-width: 2px; // used also option-card
$card-border-radius-small: 4px; // used also option-card
$card-border-radius-medium: 8px; // used also accordion, option-card
$card-border-radius-large: 16px; // used also option-card

// used also carousel, separator, tabs
$card-paddings: (
  none $card-padding-none,
  xsmall $card-padding-xsmall,
  small $card-padding-small,
  medium $card-padding-medium,
  large $card-padding-large,
  xlarge $card-padding-xlarge,
  xxlarge $card-padding-xxlarge
);

// Option-card
$option-card-indicator-top-padding: 16px; // used also in card
$option-card-indicator-size: 24px; // used also in card

// Tag
$tag-tiny-padding-vertical: 4px;
$tag-tiny-font-height: var(--type-tiny-line-height);
$tag-tiny-height: calc(#{$tag-tiny-padding-vertical * 2} + (#{$tag-tiny-font-height} * 1em)); // used in card
