@use 'sass:math';

@import '@telia-front/sass-tokens/index.scss';

$card-border-radiuses: (none 0, small $card-border-radius-small, medium $card-border-radius-medium, large $card-border-radius-large);
$card-left-border-width: 4px;
$card-content-tag-top-margin: 16px;
$card-content-tag-bottom-margin: 16px;

@mixin card-border-radius() {
  @each $size, $border-radius in $card-border-radiuses {
    .card.card--border-radius-#{$size}.card--badge > &:nth-child(2),
    .card.card--border-radius-top-#{$size}.card--badge > &:nth-child(2),
    .card.card--border-radius-#{$size}.card--is-closable > &:nth-child(2),
    .card.card--border-radius-top-#{$size}.card--is-closable > &:nth-child(2),
    .card.card--border-radius-#{$size} > &:first-child,
    .card.card--border-radius-top-#{$size} > &:first-child {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    .card.card--border-radius-#{$size} > &:last-child,
    .card.card--border-radius-bottom-#{$size} > &:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  .card--border > &:first-child,
  .card--attention > &:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .card--border > &:last-child,
  .card--attention > &:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.card {
  --card-border-color: transparent;

  @include transition(
    (
      'animation': medium,
      'properties': transform,
    )
  );

  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 0;
  transform: translateY(0);

  .card__content-tag {
    @include transition(
      (
        'animation': medium,
        'properties': (
          background-color,
          color,
        ),
      )
    );
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    border-radius: inherit;
    z-index: 1;
    border-color: var(--card-border-color);
  }

  &::after {
    opacity: 0;
    border-width: $card-border-selected-width;
    @include transition(
      (
        'animation': short,
        'properties': (
          border-color,
          opacity,
        ),
      )
    );
  }

  &::before {
    border-width: $card-border-width;
  }

  &.is-active {
    transform: translateY(-8px);
  }

  &.card--type-employee {
    --card-border-color: var(--color-employee-border);
  }

  &.is-selected {
    --card-border-color: var(--color-brand-primary-border);

    &::before,
    &::after {
      opacity: 1;
    }
  }

  &[class^='card--type-'],
  &[class*=' card--type-'] {
    &::before,
    &::after {
      border-style: solid;
    }
  }
}

.card--disabled {
  pointer-events: none;

  &:not(.card--full-opacity) {
    opacity: 0.5;
  }
}

.card--selectable {
  --card-border-color: var(--color-neutral-border-subtle);

  cursor: pointer;

  html[data-whatintent='mouse'] &:hover {
    --card-border-color: var(--color-brand-primary-border);
  }

  &.card--type-employee {
    --card-border-color: var(--color-neutral-border-subtle);

    color: var(--color-employee-text);

    html[data-whatintent='mouse'] &:hover {
      --card-border-color: var(--color-employee-border);
    }

    &.is-selected {
      --card-border-color: var(--color-employee-border);
    }
  }

  &:not(.is-active) {
    &::before,
    &::after {
      border-style: solid;
    }
  }
}

.card--type-success {
  --card-border-color: var(--color-success-border);
}

.card--type-error {
  --card-border-color: var(--color-error-border);
}

.card--type-employee {
  --card-border-color: var(--color-error-border);
}

.card--elevation-1 {
  box-shadow: $card-elevation-1;
}

.card--elevation-2 {
  box-shadow: $card-elevation-2;
}

.card--elevation-3 {
  box-shadow: $card-elevation-3;
}

.card--elevation-4 {
  box-shadow: $card-elevation-4;
}

.card--elevation-5 {
  box-shadow: $card-elevation-5;
}

@each $size, $border-radius in $card-border-radiuses {
  .card--border-radius-#{$size} {
    border-radius: $border-radius;
  }

  .card--border-radius-top-#{$size} {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .card--border-radius-right-#{$size} {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .card--border-radius-bottom-#{$size} {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .card--border-radius-left-#{$size} {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}

.card__top {
  flex: 1 0 auto;
}

.card__bottom {
  margin-top: 30px;
}

.card__content {
  @include card-border-radius();

  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  .card--background-video & {
    position: relative;
  }

  &.card__content--with-tag {
    padding-top: 0;
  }

  .card--with-background-image & {
    z-index: 1;
  }

  .card--with-flip-content & {
    opacity: 0;
    transition:
      opacity 150ms ease-in-out,
      transform 150ms ease-in-out;

    &.is-entered {
      opacity: 1;
    }

    &.is-exited,
    &.is-exiting {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      pointer-events: none;
    }
  }

  .card--with-flip-content &:not(.card__flip-content) {
    transform: translateY(-20px);

    &.is-entered {
      transform: translateY(0);
    }

    &.is-exiting,
    &.is-exited {
      transform: translateY(-20px);
    }
  }

  .card--with-flip-content.is-fixed-height & {
    flex: 0 0 100%;

    &.is-exited,
    &.is-exiting {
      position: static;
    }
  }
}

.card__content-tag-wrapper {
  display: flex;
  min-height: calc(#{$card-content-tag-top-margin} + #{$tag-tiny-height} + #{$card-content-tag-bottom-margin});
  font-size: var(--type-tiny-font-size);
  flex-direction: column;
  padding-top: $card-content-tag-top-margin;
  padding-bottom: $card-content-tag-bottom-margin;

  .option-card & {
    min-height: $option-card-indicator-top-padding + $option-card-indicator-size + 16px;
    padding-right: 32px;
  }
}

.card__content-tag-inner {
  @include transition(
    (
      'animation': medium,
      'properties': opacity,
    )
  );

  position: relative;
  opacity: 1;
  display: flex;
  flex-direction: column;

  &.is-hidden {
    opacity: 0;
  }
}

@each $size, $padding in $card-paddings {
  .card__content--padding-#{$size} {
    padding: $padding;
  }
}

@each $size, $padding in $card-paddings {
  .card__content--padding-top-#{$size} {
    padding-top: $padding;
  }

  .card__content--padding-right-#{$size} {
    padding-right: $padding;
  }

  .card__content--padding-bottom-#{$size} {
    padding-bottom: $padding;
  }

  .card__content--padding-left-#{$size} {
    padding-left: $padding;
  }

  .card__content--padding-vertical-#{$size} {
    padding-top: $padding;
    padding-bottom: $padding;
  }

  .card__content--padding-horizontal-#{$size} {
    padding-left: $padding;
    padding-right: $padding;
  }
}

@each $size, $border-radius in $card-border-radiuses {
  .card__content--border-radius-#{$size} {
    border-radius: $border-radius;
  }
}

@each $size, $border-radius in $card-border-radiuses {
  .card__content--border-radius-top-#{$size} {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .card__content--border-radius-right-#{$size} {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .card__content--border-radius-bottom-#{$size} {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .card__content--border-radius-left-#{$size} {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}

.card__content-tag {
  margin-right: auto;
}

.card__footer,
.card__header {
  @include card-border-radius();

  position: relative;
  border-top: 0;
  min-height: 80px;
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;

  &.text-center {
    justify-content: center;
  }

  &.text-left {
    justify-content: start;
  }

  &.text-right {
    justify-content: end;
  }

  .card--background-video > &,
  .card--with-background-image > & {
    background: transparent;
  }
}

@each $size, $padding in $card-paddings {
  .card__footer--padding-#{$size} {
    padding: 16px $padding;
  }
}

@each $size, $padding in $card-paddings {
  .card__footer--padding-right-#{$size} {
    padding-right: $padding;
  }

  .card__footer--padding-left-#{$size} {
    padding-left: $padding;
  }

  .card__footer--padding-vertical-#{$size} {
    padding-top: $padding;
    padding-bottom: $padding;
  }

  .card__footer--padding-horizontal-#{$size} {
    padding-left: $padding;
    padding-right: $padding;
  }
}

@each $size, $padding in $card-paddings {
  .card__header--padding-#{$size} {
    padding: 16px $padding;
  }
}

@each $size, $padding in $card-paddings {
  .card__header--padding-right-#{$size} {
    padding-right: $padding;
  }

  .card__header--padding-left-#{$size} {
    padding-left: $padding;
  }

  .card__header--padding-vertical-#{$size} {
    padding-top: $padding;
    padding-bottom: $padding;
  }

  .card__header--padding-horizontal-#{$size} {
    padding-left: $padding;
    padding-right: $padding;
  }
}

.card--link-border {
  color: var(--color-neutral-text);
  font-weight: inherit;
  cursor: pointer;

  html[data-whatintent='mouse'] &:hover {
    @include transition(
      (
        'animation': short,
        'properties': border-color,
      )
    );

    color: var(--color-neutral-text);

    &::before {
      border-color: var(--color-brand-primary-border);
      border-style: solid;
    }
  }
}

.card--link-background {
  --card-hover-background: var(--color-neutral-bg-hover);

  color: var(--color-neutral-text);
  font-weight: inherit;
  cursor: pointer;

  html[data-whatintent='mouse'] &:hover {
    color: var(--color-neutral-text);
  }

  &::before {
    @include transition(
      (
        'animation': short,
        'properties': background-color,
      )
    );

    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    background-color: transparent;
    border-radius: inherit;
    z-index: -1;
  }

  html[data-whatintent='mouse'] &:hover::before {
    @include transition(
      (
        'animation': short,
        'properties': background-color,
      )
    );

    background-color: var(--card-hover-background);
  }
}

.card__video {
  .card & {
    overflow: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    // stylelint-disable-next-line value-no-vendor-prefix, property-no-vendor-prefix
    -webkit-mask-image: -webkit-radial-gradient($white, $black); // https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
  }

  .card--background-video-opacity & {
    opacity: 0.2;
  }
}

.card__video .video__html5 {
  position: absolute;
  height: 100%;
  width: unset;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card__background-image {
  border-radius: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
}

.card__background-image-picture {
  border-radius: inherit;
  display: block;
  width: 100%;
  height: 100%;

  .card & {
    overflow: hidden;
    position: absolute;
    border-radius: inherit;
  }
}

.card__content--with-background-image {
  position: relative;
  width: 100%;
  height: 100%;
}

.card__content-background-image-picture {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;

  .card & {
    overflow: hidden;
    position: absolute;
    border-radius: inherit;
  }
}

.card__content-object-fit {
  border-radius: inherit;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
}

.card__tag {
  position: relative;
  z-index: 2;
}

.card__tags {
  position: absolute;
  z-index: 2;
  top: 16px;
  right: -12px;

  @media (max-width: $media-width-xs-max) {
    right: -8px;
  }
}

.card--badge {
  margin-top: calc(#{$badge-height} / 2);
}

.card__badge-wrapper {
  position: absolute;
  left: 10px;
  right: 10px;
  pointer-events: none;
  display: flex;
  justify-content: center;
  top: 0;
}

.card__badge-wrapper-inner {
  border-radius: 100rem;
  background: var(--color-neutral-bg-level-1);
  pointer-events: auto;
  position: relative;
  z-index: 2;

  .card__badge-wrapper & {
    transform: translate(0, -50%);
    top: -1px;
  }
}

.card.bg-brand-secondary-bg-subtlest {
  --card-hover-background: var(--color-brand-secondary-bg-subtlest-hover);
}

.card--with-flip-content {
  transition: height 150ms ease-in-out;

  &.is-animating {
    overflow: hidden;
  }

  &.is-fixed-height {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
}

.card--full-width {
  @media (max-width: $media-width-xs-max) {
    width: calc(100% + 30px);
    margin-left: -$base-horizontal-spacing-xs;
    margin-right: -$base-horizontal-spacing-xs;
  }
}

.card--type-inactive {
  --card-border-color: var(--color-disabled-border);
}

.card--type-attention {
  --card-border-color: var(--color-attention-border);
}

.card--type-light {
  --card-border-color: var(--color-neutral-border-subtle);
}

.card--border-dashed {
  &[class^='card--type-'],
  &[class*=' card--type-'] {
    &::before,
    &::after {
      border-width: $card-border-selected-width;
      border-style: dashed;
    }
  }

  &.card--selectable:not(.is-active)::before,
  &.card--selectable:not(.is-active)::after {
    border-width: $card-border-selected-width;
    border-style: dashed;
  }

  &.card--link-border {
    html[data-whatintent='mouse'] &:hover::before,
    html[data-whatintent='mouse'] &:hover::after {
      border-width: $card-border-selected-width;
      border-style: dashed;
    }
  }
}

.card__close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2; // should be higher than card__content z-index
}

.card__figure {
  position: relative;
  flex-shrink: 0;
}

.card__image {
  width: 100%;
  height: auto;
  display: block;

  .card--attention & {
    border-radius: 0;
  }

  .card--border-radius-small & {
    border-top-left-radius: $card-border-radius-small;
    border-top-right-radius: $card-border-radius-small;
  }

  .card--border-radius-medium & {
    border-top-left-radius: $card-border-radius-medium;
    border-top-right-radius: $card-border-radius-medium;
  }

  .card--border-radius-large & {
    border-top-left-radius: $card-border-radius-large;
    border-top-right-radius: $card-border-radius-large;
  }
}

.card__footer {
  @include card-border-radius();
}

.card.card--left-bordered {
  border-left: $card-left-border-width solid;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  &.card--border-radius-small > :first-child,
  &.card--border-radius-medium > :first-child,
  &.card--border-radius-large > :first-child {
    border-top-left-radius: 0;
  }

  &.card--border-radius-small > :last-child,
  &.card--border-radius-medium > :last-child,
  &.card--border-radius-large > :last-child {
    border-bottom-left-radius: 0;
  }

  &.card--left-bordered-blue {
    border-left-color: var(--color-palette-blue-500);
  }

  &.card--left-bordered-blue-l {
    border-left-color: var(--color-palette-blue-300);
  }

  &.card--left-bordered-green {
    border-left-color: var(--color-palette-green-500);
  }

  &.card--left-bordered-green-l {
    border-left-color: var(--color-palette-green-300);
  }

  &.card--left-bordered-pink {
    border-left-color: var(--color-palette-pink-500);
  }

  &.card--left-bordered-pink-l {
    border-left-color: var(--color-palette-pink-300);
  }

  &.card--left-bordered-orange {
    border-left-color: var(--color-palette-orange-500);
  }

  &.card--link.card--left-bordered::before {
    left: $card-left-border-width * -1;
  }

  &[class^='card--type-'],
  &[class*=' card--type-'] {
    &::before,
    &::after {
      left: $card-left-border-width * -1;
    }

    &.is-selected {
      &::before,
      &::after {
        left: $card-left-border-width * -1;
      }
    }
  }
}

.card__flip-toggle {
  position: absolute;
  z-index: 2;
  top: 16px;
  right: 16px;
}

.card__flip-content {
  transform: translateY(20px);

  &.is-exiting,
  &.is-exited {
    transform: translateY(20px);
  }

  &.is-entered {
    transform: translateY(0);
  }

  .card.is-fixed-height & {
    transform: translate(-100%, 20px);

    &.is-exiting,
    &.is-exited {
      transform: translate(-100%, 20px);
    }

    &.is-entered {
      transform: translate(-100%, 0);
    }
  }
}
