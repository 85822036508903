@import '@telia-front/sass-tokens/index.scss';

$image-border-rounded: 3px;

.image {
  @include img-responsive();

  &.lazyload,
  &.lazyloading {
    opacity: 0;
    transition: opacity 400ms ease-in-out;
  }

  &.lazyloaded {
    opacity: 1;
    transition: opacity 400ms ease-in-out;
  }

  &.image--no-fade {
    transition: none;
  }
}

.image--circle {
  border-radius: 100%;
}

.image--rounded {
  border-radius: $image-border-rounded;
}

.image--rounded-top {
  border-top-right-radius: $image-border-rounded;
  border-top-left-radius: $image-border-rounded;
}

.image--rounded-right {
  border-top-right-radius: $image-border-rounded;
  border-bottom-right-radius: $image-border-rounded;
}

.image--rounded-bottom {
  border-bottom-right-radius: $image-border-rounded;
  border-bottom-left-radius: $image-border-rounded;
}

.image--rounded-left {
  border-top-left-radius: $image-border-rounded;
  border-bottom-left-radius: $image-border-rounded;
}

.image--left {
  margin-right: auto;
}

.image--center {
  margin-left: auto;
  margin-right: auto;
}

.image--right {
  margin-left: auto;
}

.image--inactive {
  &.lazyloaded {
    opacity: 0.5;
  }
}
