@import '@telia-front/sass-tokens/index.scss';

.table-collapse__row--trigger {
  cursor: pointer;
}

.table-collapse__content {
  display: none;

  &.is-open {
    display: table-row;
  }
}

.table-collapse.table--responsive .table__responsive {
  tbody::before {
    display: block;
    content: '';
    height: 2em;
  }

  tbody::after,
  tbody.table-collapse__body::before,
  table thead:first-child + tbody::before {
    display: none;
  }
}
