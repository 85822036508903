@import '@telia-front/sass-tokens/index.scss';

.product__text {
  @media (min-width: $media-width-lg-min) {
    text-align: center;
  }
}

.product__action {
  @media (min-width: $media-width-lg-min) {
    text-align: center;
  }
}

.product__button {
  @media (max-width: $media-width-xs-max) {
    @include button-small();
  }
}
