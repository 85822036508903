@use 'sass:map';

@import '@telia-front/sass-tokens/index.scss';

$separator-color-default: var(--color-neutral-border-subtlest);
$separator-color-medium: var(--color-neutral-border-subtle);
$separator-color-strong: var(--color-neutral-border);
$separator-weight-default: 2px;
$separator-weight-thin: 1px;

$separator-spacings: (
  xxsmall: (
    default: 4px,
  ),
  xsmall: (
    default: 8px,
  ),
  small: (
    default: 16px,
    mobile: 12px,
  ),
  medium: (
    default: 24px,
    mobile: 16px,
  ),
  medium-plus: (
    default: 32px,
    mobile: 24px,
  ),
  large: (
    default: 40px,
    mobile: 32px,
  ),
  xlarge: (
    default: 80px,
    mobile: 56px,
  ),
);

hr {
  @extend .separator;
}

@mixin separator-block {
  content: '';
  position: absolute;
  top: 50%;
  bottom: 50%;
  border-top: $separator-weight-default solid $separator-color-default;
  transform: translateY(-50%);
}

.separator {
  display: block;
  margin: 0;
  border: 0;
  border-top: $separator-weight-default solid $separator-color-default;
}

@each $name, $spacings in $separator-spacings {
  .separator--#{$name} {
    @include margin-vertical(#{map.get($spacings, 'default')});

    @if map.get($spacings, 'mobile') {
      @media (max-width: $media-width-xs-max) {
        @include margin-vertical(#{map.get($spacings, 'mobile')});
      }
    }
  }
}

.separator--color-invisible {
  border-color: transparent;
}

.separator--color-medium {
  border-color: $separator-color-medium;
}

.separator--color-strong {
  border-color: $separator-color-strong;
}

.separator--content {
  border: none;
  overflow: hidden;
  text-align: center;
}

.separator--left {
  text-align: left;
}

.separator--right {
  text-align: right;
}

.separator--thin {
  border-top-width: $separator-weight-thin;
}

.separator--full-width {
  .modal__body > &,
  .collapse__inner > & {
    margin-left: -$base-horizontal-spacing-xs;
    margin-right: -$base-horizontal-spacing-xs;

    @media (min-width: $media-width-sm-min) {
      margin-left: -40px;
      margin-right: -40px;
    }
  }

  @each $size, $padding in $card-paddings {
    .card__content--padding-#{$size} & {
      margin-left: -$padding;
      margin-right: -$padding;
    }
  }

  @each $size, $padding in $card-paddings {
    .card__content--padding-right-#{$size} & {
      margin-left: -$padding;
      margin-right: -$padding;
    }

    .card__content--padding-left-#{$size} & {
      margin-left: -$padding;
      margin-right: -$padding;
    }

    .card__content--padding-horizontal-#{$size} & {
      margin-left: -$padding;
      margin-right: -$padding;
    }
  }
}

.separator__content {
  display: inline-block;
  position: relative;
  padding-left: $base-horizontal-spacing-xs;
  padding-right: $base-horizontal-spacing-xs;

  @media (min-width: $media-width-sm-min) {
    padding-left: $base-horizontal-spacing;
    padding-right: $base-horizontal-spacing;
  }

  &::before {
    @include separator-block();

    left: -$page-max-width * 2;
    right: 100%;
  }

  &::after {
    @include separator-block();

    right: -$page-max-width * 2;
    left: 100%;
  }

  .separator--color-medium & {
    &::before,
    &::after {
      border-color: $separator-color-medium;
    }
  }

  .separator--color-strong & {
    &::before,
    &::after {
      border-color: $separator-color-strong;
    }
  }

  .separator--left & {
    padding-left: 0;
  }

  .separator--right & {
    padding-right: 0;
  }

  .separator--thin & {
    &::before,
    &::after {
      border-top-width: $separator-weight-thin;
    }
  }
}
