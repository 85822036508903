@import '@telia-front/sass-tokens/index.scss';

$tabs-nav-link-icon-size: 32px;
$tabs-nav-link-icon-top-size: 40px;
$tabs-light-selected-color: var(--color-neutral-bg-level-2);

.tabs {
  color: var(--color-neutral-text);
}

.tabs__content {
  position: relative;

  .tabs--background-light > & {
    border: none;
    margin-top: 0;
    border-radius: 0 0 3px 3px;
    background-color: $tabs-light-selected-color;
  }

  .tabs--huge > & {
    border: 1px solid var(--color-neutral-border-subtle);
    border-top: 0;
    border-radius: 0 0 8px 8px;
  }

  .tabs--huge.tabs--full-width > & {
    border: none;
  }

  .tabs--button-navigation > & {
    border: none;
    margin-top: 0;
  }

  .tabs--sidemenu > & {
    border: none;
    margin-top: 0;

    @media (min-width: $media-width-sm-min) {
      margin-left: $grid-gutter;
      flex: 0 1 auto;
      flex-basis: calc(66.6666% - #{$grid-gutter * 0.5});
      max-width: calc(66.6666% - #{$grid-gutter * 0.5});
    }

    @media (min-width: $media-width-md-min) {
      flex-basis: calc(75% - #{$grid-gutter * 0.5});
      max-width: calc(75% - #{$grid-gutter * 0.5});
    }
  }
}

.tabs__item {
  display: none;
  padding: 24px 0;

  @media (min-width: $media-width-md-min) {
    padding: 32px 0;
  }

  &.is-current {
    display: block;
  }

  &.fade-enter {
    display: block;
    opacity: 0;
  }

  &.fade-exit {
    display: block;
    opacity: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transition: opacity 150ms cubic-bezier(0.64, 0, 0.78, 0); // easeInQuint
  }

  &.fade-enter-active {
    opacity: 0;
    transition: opacity 150ms cubic-bezier(0.22, 1, 0.36, 1); // easeOutQuint
  }

  &.fade-enter-done {
    opacity: 1;
    transition: opacity 150ms cubic-bezier(0.22, 1, 0.36, 1); // easeOutQuint
  }

  &.fade-exit-active,
  &.fade-exit-done {
    opacity: 0;
  }

  &.fade-exit-done {
    display: none;
  }

  .tabs--sidemenu & {
    padding: 20px 0;

    @media (min-width: $media-width-sm-min) {
      padding: 0;
    }
  }

  .tabs--medium.tabs--background-light & {
    padding: 20px $base-horizontal-spacing-xs;

    @media (min-width: $media-width-sm-min) {
      padding: 30px;
    }

    @media (min-width: $media-width-md-min) {
      padding: 40px;
    }
  }

  .tabs--huge > .tabs__content > div > & {
    padding: 24px 16px;

    @media (min-width: $media-width-md-min) {
      padding: 32px;
    }
  }
}

.tabs__nav {
  .tabs--pull-top > & {
    margin-top: -42px;

    &.tabs__nav--with-icons {
      margin-top: -48px;
    }

    &.tabs__nav--with-icons.tabs__nav--icons-top {
      margin-top: -86px;

      @media (min-width: $media-width-sm-min) {
        margin-top: -90px;
      }
    }
  }
}

.tabs--background-light,
.tabs--huge {
  &.tabs--full-width {
    box-shadow: $elevation-0;

    @each $size, $padding in $card-paddings {
      .card__content--padding-#{$size} > & {
        margin-left: -$padding;
        margin-right: -$padding;
      }
    }

    @each $size, $padding in $card-paddings {
      .card__content--padding-right-#{$size} > & {
        margin-right: -$padding;
      }

      .card__content--padding-left-#{$size} > & {
        margin-left: -$padding;
      }

      .card__content--padding-horizontal-#{$size} > & {
        margin-left: -$padding;
        margin-right: -$padding;
      }
    }
  }
}

.tabs--background-light {
  position: relative;
  background-color: $tabs-light-selected-color;
  box-shadow: $elevation-1;
  border-radius: 3px;

  &.tabs--full-width {
    @each $size, $padding in $card-paddings {
      .card__content--padding-#{$size} > & {
        margin-left: -$padding;
        margin-right: -$padding;
      }
    }

    @each $size, $padding in $card-paddings {
      .card__content--padding-right-#{$size} > & {
        margin-right: -$padding;
      }

      .card__content--padding-left-#{$size} > & {
        margin-left: -$padding;
      }

      .card__content--padding-horizontal-#{$size} > & {
        margin-left: -$padding;
        margin-right: -$padding;
      }
    }
  }
}

.tabs--huge {
  box-shadow: $elevation-0;
  border-radius: 0 0 8px 8px;
  background-color: var(--color-neutral-bg-level-1);

  &.tabs--background-light {
    background-color: $tabs-light-selected-color;
  }

  &.tabs--full-width {
    .modal__body > & {
      margin-left: -$modal-horizontal-spacing-xs;
      margin-right: -$modal-horizontal-spacing-xs;

      @media (min-width: $media-width-sm-min) {
        margin-left: -40px;
        margin-right: -40px;
      }
    }

    @each $size, $padding in $card-paddings {
      .card__content--padding-#{$size} > & {
        margin-left: -$padding;
        margin-right: -$padding;
      }
    }

    @each $size, $padding in $card-paddings {
      .card__content--padding-right-#{$size} > & {
        margin-right: -$padding;
      }

      .card__content--padding-left-#{$size} > & {
        margin-left: -$padding;
      }

      .card__content--padding-horizontal-#{$size} > & {
        margin-left: -$padding;
        margin-right: -$padding;
      }
    }
  }
}

.tabs--sidemenu {
  @media (min-width: $media-width-sm-min) {
    display: flex;
  }
}

.tabs__nav--button-navigation {
  display: block;
  margin-left: unset;
  margin-right: unset;
}

.tabs__nav--sidemenu {
  display: block;
  margin: 0;
  overflow: visible;
  margin-left: unset;
  margin-right: unset;

  @media (min-width: $media-width-sm-min) {
    flex: 0 0 auto;
    flex-basis: calc(33.3333% - #{$grid-gutter * 0.5});
    margin-left: unset;
    margin-right: unset;
  }

  @media (min-width: $media-width-md-min) {
    flex-basis: calc(25% - #{$grid-gutter * 0.5});
  }
}
