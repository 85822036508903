@import '@telia-front/sass-tokens/index.scss';

.clear-btn {
  @include transition(
    (
      'animation': short,
      'properties': color,
    )
  );

  background: transparent;
  border: 0;
  margin: 0;
  font-size: 1rem;
  padding: 0;
  color: var(--color-neutral-graphic);
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  html[data-whatintent='mouse'] &:hover {
    color: var(--color-brand-primary-text-hover);
  }
}

.clear-btn__icon {
  @include reset-icon();
}
