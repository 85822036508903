@import '@telia-front/sass-tokens/index.scss';

.toggle {
  --toggle-indicator-border-color: var(--color-neutral-border);
  --toggle-indicator-bg: transparent;
  --toggle-circle-color: var(--color-neutral-border);
  --toggle-circle-hover-color: var(--color-neutral-border);
  --toggle-hover-border-color: var(--color-brand-primary-border);
  --toggle-hover-bg: transparent;
  --toggle-checked-border-color: var(--color-brand-primary-border);
  --toggle-checked-bg: var(--color-brand-primary-bg-strong);
  --toggle-checked-hover-bg: var(--color-brand-primary-bg-strong-hover);
  --toggle-checked-circle-color: var(--color-brand-primary-text-onbg-strong);
  --toggle-disabled-text-color: var(--color-disabled-text);
  --toggle-disabled-border-color: var(--color-disabled-border);
  --toggle-disabled-bg: var(--color-disabled-bg);
  --toggle-disabled-circle-color: var(--color-disabled-bg);
  --toggle-disabled-checked-circle-color: var(--color-neutral-bg-level-1);
  --toggle-disabled-checked-bg: var(--color-disabled-bg);
  --toggle-spinner-color: var(--color-brand-primary-graphic);
  --toggle-label-color: var(--color-neutral-text);
  --toggle-indicator-width: 2.5rem;
  --toggle-indicator-height: 1.5rem;
  --toggle-font-size: var(--type-small-font-size);
  --toggle-line-height: var(--type-small-line-height);
  --toggle-circle-size: 1rem;
  --toggle-indicator-label-spacing: 8px;
  --toggle-indicator-padding: calc(var(--toggle-indicator-width) + var(--toggle-indicator-label-spacing));
  --toggle-circle-spacing: 4px;
  --toggle-spinner-size: 1rem;

  position: relative;
}

.toggle--employee {
  --toggle-indicator-border-color: var(--color-employee-border);
  --toggle-indicator-bg: var(--color-employee-bg);
  --toggle-circle-color: var(--color-employee-graphic);
  --toggle-circle-hover-color: var(--color-employee-text-hover);
  --toggle-hover-border-color: var(--color-employee-border);
  --toggle-checked-border-color: var(--color-employee-border);
  --toggle-checked-bg: var(--color-employee-bg-strong);
  --toggle-checked-hover-bg: var(--color-employee-bg-strong-hover);
  --toggle-checked-circle-color: var(--color-employee-text-onbg-strong);
  --toggle-label-hover-color: var(--color-employee-text-hover);
  --toggle-spinner-color: var(--color-employee-graphic);
}

.toggle--small {
  --toggle-indicator-width: 1.5rem;
  --toggle-indicator-height: 1rem;
  --toggle-font-size: var(--type-tiny-font-size);
  --toggle-line-height: var(--type-tiny-line-height);
  --toggle-circle-size: 0.5rem;
  --toggle-indicator-label-spacing: 4px;
}

.toggle--large {
  --toggle-indicator-width: 3.5rem;
  --toggle-indicator-height: 2rem;
  --toggle-font-size: var(--type-font-size);
  --toggle-line-height: var(--type-line-height);
  --toggle-spinner-size: 1.375rem;
  --toggle-indicator-label-spacing: 12px;
  --toggle-circle-size: 1.375rem;
  --toggle-circle-spacing: 5px;
  --toggle-text-inside-font-size: var(--type-tiny-font-size);
  --toggle-text-inside-line-height: var(--type-tiny-line-height);
}

.toggle__label {
  @include visually-hidden();
}

.toggle__inner {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: var(--toggle-indicator-padding);
  min-height: var(--toggle-indicator-height);
  cursor: pointer;

  .toggle--with-label-left & {
    padding-left: 0;
    padding-right: var(--toggle-indicator-padding);
  }

  .toggle--with-label-inside & {
    white-space: nowrap;
    min-height: var(--toggle-indicator-height);
    padding: 0;
  }

  .toggle__input:disabled ~ & {
    cursor: default;
    pointer-events: none;
  }

  .toggle__input:focus-visible ~ & {
    @include keyboard-focus();
    border-radius: 99em;
  }
}

.toggle__indicator {
  @include transition(
    (
      'animation': medium,
      'properties': (
        background-color,
        border-color,
      ),
    )
  );

  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: var(--toggle-indicator-width);
  height: var(--toggle-indicator-height);
  border-radius: 99em;
  border: 1px solid var(--toggle-indicator-border-color);
  background-color: var(--toggle-indicator-bg);
  transform: translateY(-50%);
  user-select: none;

  .toggle--with-label-left & {
    right: 0;
    left: auto;
  }

  &::after {
    @include transition(
      (
        'animation': medium,
        'properties': all,
      )
    );

    content: '';
    position: absolute;
    display: block;
    width: var(--toggle-circle-size);
    height: var(--toggle-circle-size);
    top: 50%;
    left: var(--toggle-circle-spacing);
    transform: translate(0, -50%);
    border-radius: 99em;
    background: var(--toggle-circle-color);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  }

  .toggle__inner:hover & {
    @include transition(
      (
        'animation': short,
        'properties': (
          background-color,
          border-color,
        ),
      )
    );

    border-color: var(--toggle-hover-border-color);
    background: var(--toggle-hover-bg);

    &::after {
      background: var(--toggle-circle-hover-color);
    }
  }

  .toggle--with-label-inside & {
    width: auto;
    height: var(--toggle-indicator-height);
    position: static;
    transform: none;
  }

  .toggle__input:disabled ~ .toggle__inner & {
    border-color: var(--toggle-disabled-border-color);

    &::after {
      box-shadow: $elevation-0;
      background-color: var(--toggle-disabled-circle-color);
    }
  }

  .toggle__input:disabled:checked ~ .toggle__inner & {
    background-color: var(--toggle-disabled-bg);

    &::after {
      background-color: var(--toggle-disabled-checked-circle-color);
    }
  }

  .toggle__input:checked:not(:disabled) ~ .toggle__inner & {
    border-color: var(--toggle-checked-border-color);
    background: var(--toggle-checked-bg);

    &::after {
      background: var(--toggle-checked-circle-color);
    }
  }

  .toggle__input:checked:not(:disabled) ~ .toggle__inner:hover & {
    background: var(--toggle-checked-hover-bg);
  }

  .toggle__input:checked ~ .toggle__inner & {
    &::after {
      left: calc(100% - var(--toggle-circle-spacing) - var(--toggle-circle-size));

      .toggle--with-label-inside & {
        left: calc(100% - var(--toggle-circle-spacing) - var(--toggle-circle-size));
      }
    }
  }
}

.toggle__label-wrapper {
  display: inline-block;
  position: relative;
}

.toggle__text {
  @include transition(
    (
      'animation': medium,
      'properties': color,
    )
  );

  display: inherit;
  text-align: left;
  font-size: var(--toggle-font-size);
  line-height: var(--toggle-line-height);
  font-weight: 400;
  color: var(--toggle-label-color);

  .toggle--small & {
    font-weight: inherit;
  }

  .toggle--with-label-left & {
    text-align: right;
  }

  .toggle--with-label-inside & {
    display: block;
    font-size: var(--toggle-text-inside-font-size);
    line-height: var(--toggle-text-inside-line-height);
    font-weight: 700;
    padding-top: calc((var(--toggle-indicator-height) - var(--toggle-text-inside-line-height) * var(--toggle-text-inside-font-size)) / 2);
    padding-bottom: calc((var(--toggle-indicator-height) - var(--toggle-text-inside-line-height) * var(--toggle-text-inside-font-size)) / 2);
    text-align: center;
  }

  .toggle--with-label-inside &:not(.toggle__text--loading) {
    color: var(--color-neutral-text-subtle);
  }

  .toggle__inner:hover & {
    @include transition(
      (
        'animation': short,
        'properties': color,
      )
    );
    color: var(--toggle-label-hover-color);
  }

  .toggle__input:disabled ~ .toggle__inner & {
    color: var(--toggle-disabled-text-color);
  }

  .toggle__input:checked ~ .toggle__inner &:not(.toggle__text--loading) {
    .toggle--with-label-inside & {
      color: var(--toggle-checked-circle-color);
    }
  }

  .toggle__input:disabled:checked ~ .toggle__inner &:not(.toggle__text--loading) {
    .toggle--with-label-inside & {
      color: var(--toggle-disabled-text-color);
    }
  }

  .toggle--loading &:not(.toggle__text--loading) {
    visibility: hidden;
  }
}

.toggle__text--off {
  .toggle__indicator & {
    padding-left: calc(var(--toggle-circle-size) + var(--toggle-circle-spacing) + 10px); // 10px min-space between text and circle
    padding-right: 15px;
  }

  .toggle--with-on-label .toggle__input:checked ~ .toggle__inner &,
  .toggle--with-label-inside .toggle__input:checked ~ .toggle__inner & {
    visibility: hidden;
  }
}

.toggle__text--on {
  visibility: hidden;
  display: inherit;
  margin-top: calc(var(--toggle-line-height) * var(--toggle-font-size) * -1); // pull on top of off text

  .toggle__indicator & {
    margin-top: calc(var(--toggle-indicator-height) * -1); // pull on top of off text
    padding-right: calc(var(--toggle-circle-size) + var(--toggle-circle-spacing) + 10px); // 10px min-space between text and circle
    padding-left: 15px;
  }

  .toggle__inner > .toggle__text--off + & {
    display: block;
  }

  .toggle:not(.toggle--loading) .toggle__input:checked ~ .toggle__inner & {
    visibility: visible;
  }
}

.toggle__input {
  @include visually-hidden();
}

.toggle__text--loading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: var(--toggle-spinner-size);
  line-height: var(--toggle-spinner-size);

  .toggle__indicator & {
    padding-left: calc(var(--toggle-circle-size) + var(--toggle-circle-spacing) + 10px); // 10px min-space between text and circle
    padding-right: 15px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .toggle__input:checked ~ .toggle__inner .toggle__indicator & {
    padding-right: calc(var(--toggle-circle-size) + var(--toggle-circle-spacing) + 10px); // 10px min-space between text and circle
    padding-left: 15px;
  }
}

.toggle__text--loading .spinner__circle {
  width: var(--toggle-spinner-size);
  height: var(--toggle-spinner-size);

  & .spinner__path {
    stroke: var(--toggle-spinner-color);
  }
}
