@import '@telia-front/sass-tokens/index.scss';

.internet-speed {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.internet-speed--left {
  justify-content: flex-start;
}

.internet-speed--center {
  justify-content: center;
}

.internet-speed--right {
  justify-content: flex-end;
}

.internet-speed__items {
  display: flex;
  align-items: center;

  .internet-speed--multiline & {
    flex-direction: column;
    align-items: flex-end;
  }
}

.internet-speed__icon {
  font-size: 32px;
  margin: 0 5px;

  @media (min-width: $media-width-xl-min) {
    font-size: 48px;
  }

  .internet-speed--multiline & {
    font-size: 24px;
    display: block;

    &:first-child {
      margin-bottom: 10px;
    }

    @media (min-width: $media-width-xl-min) {
      font-size: 32px;
    }
  }

  .internet-speed--collapsed & {
    margin-left: 0;
  }
}

.internet-speed__speed {
  display: flex;
  align-items: center;
}

.internet-speed__number {
  margin-right: 5px;
}

.internet-speed__unit {
  font-size: 12px;
  line-height: 14px;
  text-align: left;
}

.internet-speed__down {
  .internet-speed--multiline & {
    margin-bottom: 5px;
  }

  @media (min-width: $media-width-sm-min) {
    margin-bottom: 0;
  }
}
