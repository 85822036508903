@import '@telia-front/sass-tokens/index.scss';

$textarea-rows-padding: 10px;
$textarea-rows-line-nr-width: 50px;
$textarea-rows-line-nr-wide-width: $textarea-rows-line-nr-width + 14px;

.textarea-rows {
  --textarea-rows-border-color: var(--color-neutral-border);
  --textarea-rows-shadow-size: 0px;

  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}

.textarea-rows.is-invalid {
  --textarea-rows-shadow-size: 2px;
  --textarea-rows-border-color: var(--color-error-border);
}

.textarea-rows__label {
  color: var(--color-neutral-text);
  font-size: var(--type-small-font-size);
  line-height: var(--type-small-line-height);

  .textarea-rows.is-disabled & {
    color: var(--color-disabled-text);
  }

  .textarea-rows--no-label-no-break & {
    @include visually-hidden();
  }

  .textarea-rows--no-label-sm & {
    @media (min-width: $media-width-sm-min) {
      @include visually-hidden();
    }
  }

  .textarea-rows--no-label-md & {
    @media (min-width: $media-width-md-min) {
      @include visually-hidden();
    }
  }
}

.textarea-rows__main {
  position: relative;
  padding: $textarea-rows-padding;
  border-radius: 4px;
  border: 1px solid var(--textarea-rows-border-color);
  overflow: hidden;
  background-color: var(--color-neutral-bg-level-1);
  transition: border-color 100ms ease-out;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    width: $textarea-rows-line-nr-width;
    background: var(--color-neutral-bg-level-2);
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: -1px;
    box-shadow: inset 0 0 0 var(--textarea-rows-shadow-size) var(--textarea-rows-border-color);
    pointer-events: none;
    border-radius: inherit;
  }

  .textarea-rows.is-focused & {
    @include keyboard-focus();
  }

  .textarea-rows.is-disabled & {
    opacity: 0.5;
  }
}

.textarea-rows__inner {
  position: relative;
}

.textarea-rows__mirror,
.textarea-rows__input {
  position: relative;
  width: 100%;
  padding: 0;
  font-size: var(--type-font-size);
  font-family: var(--type-font-family);
  font-weight: 400;
  line-height: var(--type-line-height);
}

.textarea-rows__mirror {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.textarea-rows__input {
  padding-left: $textarea-rows-line-nr-width;
  border: 0;
  outline: none;
  overflow: hidden;
  background: transparent;
  resize: none;
}

.textarea-rows__item {
  position: relative;
  display: flex;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin: 0 (-$textarea-rows-padding);
  padding: 0 $textarea-rows-padding 0 0;
  max-width: calc(100% + #{($textarea-rows-padding * 2)});
}

.textarea-rows__item--error {
  background: var(--color-error-bg-subtle);
}

.textarea-rows__item--valid {
  background: var(--color-success-bg-subtle);
}

.textarea-rows__item-nr {
  width: $textarea-rows-line-nr-width;
  padding-right: $textarea-rows-padding;
  color: var(--color-neutral-text-subtle);
  text-align: right;
  flex-shrink: 0;
}

.textarea-rows__item-text {
  flex: 1 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: $textarea-rows-padding;
  color: transparent;
  max-width: calc(100% - #{$textarea-rows-line-nr-width});
}

.textarea-rows__item-helper {
  position: relative;
  line-height: calc(var(--type-font-size) * var(--type-line-height));

  .form-helper__icon {
    margin-top: 3px;
  }
}

.textarea-rows__value {
  max-width: 100%;
  flex: 0 0 auto;
}

.textarea-rows.is-wide {
  .textarea-rows__main::before {
    width: $textarea-rows-line-nr-wide-width;
  }

  .textarea-rows__input {
    padding-left: $textarea-rows-line-nr-wide-width;
  }

  .textarea-rows__item-nr {
    width: $textarea-rows-line-nr-wide-width;
  }

  .textarea-rows__item-text {
    max-width: calc(100% - #{$textarea-rows-line-nr-wide-width});
  }
}
