@import '@telia-front/sass-tokens/index.scss';

$heading-font: var(--type-font-family-display);
$heading-weight: 700;

h1,
.h1 {
  font-size: var(--type-h1-font-size); // 32px
  line-height: var(--type-h1-line-height); // 32/34
  font-family: $heading-font;
  font-weight: $heading-weight;
  color: inherit;
  text-transform: var(--type-h1-text-transform);

  @media (min-width: $media-width-sm-min) {
    font-size: var(--type-h1-sm-font-size); // 44px
    line-height: var(--type-h1-sm-line-height); // 44/46
  }

  @media (min-width: $media-width-xl-min) {
    font-size: var(--type-h1-xl-font-size); // 56px
    line-height: var(--type-h1-xl-line-height); // 56/56
  }
}

h2,
.h2 {
  font-size: var(--type-h2-font-size); // 26px
  line-height: var(--type-h2-line-height); // 26/30
  font-family: $heading-font;
  font-weight: $heading-weight;
  text-transform: none;
  // if it is used with h1, like <h1 class="h2">, it should not be purple
  color: inherit;

  @media (min-width: $media-width-sm-min) {
    font-size: var(--type-h2-sm-font-size); // 32px
    line-height: var(--type-h2-sm-line-height); // 32/34
  }

  @media (min-width: $media-width-xl-min) {
    font-size: var(--type-h2-xl-font-size); // 40px
    line-height: var(--type-h2-xl-line-height); // 40/44
  }
}

h3,
.h3 {
  font-size: var(--type-h3-font-size); // 24px
  line-height: var(--type-h3-line-height); // 24/28
  text-transform: none;
  font-family: var(--type-h3-font-family);
  font-weight: $heading-weight;
  // if it is used with h1, like <h1 class="h3">, it should not be purple
  color: inherit;

  @media (min-width: $media-width-sm-min) {
    font-size: var(--type-h3-sm-font-size); // 24px
    line-height: var(--type-h3-sm-line-height); // 24/28
  }

  @media (min-width: $media-width-xl-min) {
    font-size: var(--type-h3-xl-font-size); // 30px
    line-height: var(--type-h3-xl-line-height); // 30/34
  }
}

h4,
.h4 {
  font-size: var(--type-h4-font-size); // 20px
  line-height: var(--type-h4-line-height); // 20/26
  // if it is used with h1, like <h1 class="h4">, it should not be purple
  color: inherit;
  text-transform: none;
  font-family: inherit;
  font-weight: inherit;

  @media (min-width: $media-width-xl-min) {
    font-size: var(--type-h4-xl-font-size); // 22px
    line-height: var(--type-h4-xl-line-height); // 22/28
  }
}

h5,
.h5 {
  font-size: var(--type-h5-font-size); // 18px
  line-height: var(--type-h5-line-height); // 18/24
  // if it is used with h1, like <h1 class="h5">, it should not be purple
  color: inherit;
  text-transform: none;
  font-family: inherit;
  font-weight: inherit;
}

h6,
.h6 {
  font-size: var(--type-font-size); // 16px
  line-height: var(--type-line-height); // 16/22;
  // if it is used with h1, like <h1 class="h6">, it should not be purple
  color: inherit;
  text-transform: none;
  font-family: inherit;
  font-weight: inherit;
}
