@import '@telia-front/sass-tokens/index.scss';

.chip {
  display: inline-flex;
  padding: 4px 8px;
  border-radius: 12px;
  border: 1px solid var(--color-neutral-border-subtle);
  font-size: var(--type-tiny-font-size);
  line-height: var(--type-tiny-line-height);
  word-break: break-word;

  &.is-disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.chip__remove {
  @include reset-button();
  @include transition(
    (
      'animation': short,
      'properties': color,
    )
  );

  margin-left: 5px;
  margin-right: -4px;
  color: var(--color-neutral-text);

  html[data-whatintent='mouse'] &:hover {
    color: var(--color-brand-primary-text-hover);
  }
}

.chip__remove-icon {
  vertical-align: bottom;
}
