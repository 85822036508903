@import '@telia-front/sass-tokens/index.scss';

@mixin btn-nav-purpose($name, $text-color, $text-color-hover, $text-color-active, $primary-color, $accent-color) {
  .btn-nav__item--purpose-#{$name} & {
    color: $text-color;

    html[data-whatintent='mouse'] &:hover {
      border-color: $accent-color;
      box-shadow: inset 0 0 0 1px $accent-color;
      color: $text-color-hover;
    }
  }

  .btn-nav__item--purpose-#{$name}.is-current & {
    color: $text-color-active;
    background-color: $primary-color;
    border-color: $primary-color;

    html[data-whatintent='mouse'] &:hover {
      color: $text-color-active;
      background: $accent-color;
      border-color: $accent-color;
      box-shadow: inset 0 0 0 1px $accent-color;
    }
  }
}

.btn-nav {
  @include list-unstyled();

  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 1.375;
  gap: 8px;

  &.btn-nav--size-small {
    font-size: 14px;
    line-height: 1.43;
    gap: 4px;
  }

  &.btn-nav--align-center {
    justify-content: center;
  }
}

.btn-nav__item {
  position: relative;
  cursor: pointer;
}

.btn-nav__link {
  display: block;
  padding: 12px 23px;
  border: 1px solid var(--color-neutral-border);
  border-radius: 24px;
  transition: all 120ms cubic-bezier(0.2, 0, 0.31, 1);
  outline-offset: 1px;

  @include btn-nav-purpose(
    $name: 'default',
    $text-color: var(--color-neutral-text),
    $text-color-hover: var(--color-neutral-text),
    $text-color-active: var(--color-brand-primary-text-onbg-strong),
    $primary-color: var(--color-brand-primary-bg-strong),
    $accent-color: var(--color-brand-primary-bg-strong-hover)
  );

  @include btn-nav-purpose(
    $name: 'employee',
    $text-color: var(--color-employee-text),
    $text-color-hover: var(--color-employee-text-hover),
    $text-color-active: var(--color-employee-text-onbg-strong),
    $primary-color: var(--color-employee-bg-strong),
    $accent-color: var(--color-employee-bg-strong-hover)
  );

  .btn-nav--size-small & {
    border-radius: 16px;
    padding: 5px 15px;
  }
}
