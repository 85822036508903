@import '@telia-front/sass-tokens/index.scss';

.passfield {
  position: relative;
}

.passfield__button {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: 8px;
  padding: 4px;
  background: transparent;
  border: 0;
  border-radius: 0;
  appearance: none;
  font-size: var(--textfield-icon-size);

  html[data-whatintent='mouse'] &:hover {
    color: var(--color-brand-primary-text-hover);
  }
}

.passfield__input {
  .passfield & {
    padding-right: calc(var(--textfield-horizontal-padding) + 8px + var(--textfield-icon-size));
  }
}

.passfield__button-icon {
  @include reset-icon();

  display: block;
}
