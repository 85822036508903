@use 'sass:math';

@import '@telia-front/sass-tokens/index.scss';

// Table
$table-tr-text-color: var(--color-neutral-text);
$table-tr-border-color: var(--color-neutral-border);
$table-tr-border-color-dark: var(--color-neutral-border-subtle);
$table-tr-hover-color: var(--color-neutral-bg-hover);
$table-tr-highlight-bg: var(--color-brand-secondary-bg-subtlest);
$table-tr-employee-bg: var(--color-employee-bg-subtlest);
$table-td-padding: 12px;
$table-td-padding-xsmall: 4px;
$table-td-padding-small: 8px;
$table-td-padding-large: 16px;

.table {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  empty-cells: show;

  table {
    width: 100%;
    border-collapse: collapse;
    border: 0;
    padding: 0;
    position: relative;

    tbody {
      &:not(.table-collapse__body)::after {
        content: '';
        display: block;
        height: 2em;
      }

      &:last-child::after {
        display: none;
      }
    }

    .table__row--employee {
      background: $table-tr-employee-bg;
    }

    tr[data-footer='true'],
    .table__row--footer {
      border: 0;
      background: $table-tr-highlight-bg;

      + tr {
        border-top: 0;
      }
    }

    th,
    td {
      padding: $table-td-padding;
      text-align: left;

      &.table__cell--padding-none {
        padding: 0;
      }

      &.table__cell--padding-xsmall {
        padding: $table-td-padding-xsmall;
      }

      &.table__cell--padding-small {
        padding: $table-td-padding-small;
      }

      &.table__cell--padding-large {
        padding: $table-td-padding-large;
      }

      &.table__cell--valign-top {
        vertical-align: top;
      }

      &.table__cell--valign-bottom {
        vertical-align: bottom;
      }

      &.table__cell--valign-baseline {
        vertical-align: baseline;
      }

      &.table__cell--valign-middle {
        vertical-align: middle;
      }
    }

    th {
      vertical-align: top;
    }

    thead tr {
      border-bottom: 1px solid $table-tr-border-color;
    }

    tbody tr {
      border-top: 1px solid $table-tr-border-color-dark;

      &:first-child {
        border-top: 0;
      }
    }
  }
}

/* hover */

.table--hover {
  tbody > tr:hover {
    background: $table-tr-hover-color;
  }

  .table-collapse__content:hover {
    background: none;
  }
}

/* bordered */

.table--bordered {
  table td,
  table th {
    border-width: 1px;
    border-style: solid dotted;
    border-color: $table-tr-border-color-dark;
  }
}

.table__row--left-bordered-brand {
  .table__cell:first-child {
    box-shadow: inset 2px 0 0 0 var(--color-brand-primary-border);
  }
}

.table__row--left-bordered-neutral-subtlest {
  .table__cell:first-child {
    box-shadow: inset 2px 0 0 0 var(--color-neutral-border-subtlest);
  }
}

.table__row--left-bordered-neutral {
  .table__cell:first-child {
    box-shadow: inset 2px 0 0 0 var(--color-neutral-border);
  }
}

.table__row--left-bordered-info {
  .table__cell:first-child {
    box-shadow: inset 2px 0 0 0 var(--color-info-border);
  }
}

.table__original {
  td,
  th {
    &.text-left {
      text-align: left;
    }

    &.text-right {
      text-align: right;
    }

    &.text-center {
      text-align: center;
    }

    @for $i from 1 through $grid-columns {
      &.table__cell--#{$i} {
        width: math.div(100%, $grid-columns) * $i;
      }
    }

    /* Indent level */
    @for $i from 0 through 6 {
      &.table__cell--indent-level-#{$i} {
        padding-left: $table-td-padding + (15px * $i);

        &.table__cell--padding-none {
          padding-left: 15px * $i;

          @media (min-width: $media-width-md-min) {
            padding-left: 20px * $i;
          }
        }

        &.table__cell--padding-xsmall {
          padding-left: $table-td-padding-xsmall + (15px * $i);

          @media (min-width: $media-width-md-min) {
            padding-left: $table-td-padding-xsmall + (20px * $i);
          }
        }

        &.table__cell--padding-small {
          padding-left: $table-td-padding-small + (15px * $i);

          @media (min-width: $media-width-md-min) {
            padding-left: $table-td-padding-small + (20px * $i);
          }
        }

        &.table__cell--padding-large {
          padding-left: $table-td-padding-large + (15px * $i);

          @media (min-width: $media-width-md-min) {
            padding-left: $table-td-padding-large + (20px * $i);
          }
        }

        @media (min-width: $media-width-md-min) {
          padding-left: $table-td-padding + (20px * $i);
        }
      }
    }
  }
}

/* sticky header */

@supports (position: sticky) {
  html:not(.no-js) .table--sticky-header-level-1,
  html:not(.no-js) .table--sticky-header-level-2 {
    & > .table__original > table > thead > tr > th {
      background: var(--color-neutral-bg-level-2);
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }

  html:not(.no-js) .table--sticky-header-level-1 > .table__original > table > thead > tr > th {
    background: var(--color-neutral-bg-level-1);
  }

  html:not(.no-js) .table--sticky-header-level-2 > .table__original > table > thead > tr > th {
    background: var(--color-neutral-bg-level-2);
  }

  .table--sticky-header-level-1.is-sticky,
  .table--sticky-header-level-2.is-sticky {
    & > .table__original > table > thead > tr > th::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -1px;
      left: 0;
      background: linear-gradient(to bottom, $table-tr-border-color, $table-tr-border-color);
    }
  }
}
