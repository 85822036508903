@use 'sass:math';

@import '@telia-front/sass-tokens/index.scss';

.check {
  position: relative;

  --check-indicator-size: 1.5rem;
  --check-icon-size: 1.25rem;
  --check-indicator-margin-right: 8px;
  --check-indicator-color: var(--color-brand-primary-text-onbg-strong);
  --check-indicator-border-color: var(--color-neutral-border);
  --check-indicator-hover-border-color: var(--color-brand-primary-border);
  --check-indicator-checked-background-color: var(--color-brand-primary-bg-strong);
  --check-indicator-checked-hover-background-color: var(--color-brand-primary-bg-strong-hover);
  --check-indicator-checked-border-color: var(--color-brand-primary-border);
  --check-indicator-checked-color: var(--color-brand-primary-text-onbg-strong);
  --check-content-hover-color: var(--color-neutral-text);
  --check-disabled-text-color: var(--color-disabled-text);
  --check-disabled-border-color: var(--color-disabled-border);
  --check-disabled-background-color: var(--color-disabled-bg);
  --check-disabled-indicator-color: var(--color-disabled-text);
  --check-disabled-checked-background-color: var(--color-disabled-bg);
  --check-disabled-checked-border-color: var(--color-disabled-border);
  --check-indicator-invalid-border: var(--color-error-border);
  --check-content-line-height: var(--type-line-height);
  --check-content-font-size: var(--type-font-size);
}

.check--employee {
  --check-indicator-color: var(--color-employee-text-onbg-strong);
  --check-indicator-border-color: var(--color-employee-border);
  --check-indicator-hover-border-color: var(--color-employee-border);
  --check-indicator-checked-background-color: var(--color-employee-bg-strong);
  --check-indicator-checked-hover-background-color: var(--color-employee-bg-strong-hover);
  --check-indicator-checked-border-color: var(--color-employee-border);
  --check-indicator-checked-color: var(--color-employee-text-onbg-strong);
  --check-content-hover-color: var(--color-employee-text-hover);
}

.check--medium {
  --check-indicator-size: 1.25rem;
  --check-icon-size: 1rem;
  --check-content-line-height: var(--type-small-line-height);
  --check-content-font-size: var(--type-small-font-size);
}

.check--small {
  --check-indicator-size: 1rem;
  --check-icon-size: 0.75rem;
  --check-content-line-height: var(--type-tiny-line-height);
  --check-content-font-size: var(--type-tiny-font-size);
}

.check--vertical {
  display: flex;
}

.check__label {
  @include visually-hidden();
}

.check__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.check__inner {
  position: relative;
  display: inline-block;
  padding-left: calc(var(--check-indicator-size) + var(--check-indicator-margin-right));
  min-height: var(--check-indicator-size);
  cursor: pointer;
  max-width: 100%;
  border-radius: 3px;

  .check__input:focus-visible ~ & {
    @include keyboard-focus();
  }

  .check__input:disabled ~ & {
    cursor: default;
  }

  .check--employee-background & {
    &::before {
      content: '';
      display: block;
      background: var(--color-employee-bg-subtlest);
      position: absolute;
      top: -5px;
      left: -5px;
      right: -10px;
      bottom: -5px;
      border-radius: 3px;
    }
  }

  .check--vertical & {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    justify-content: space-between;
    padding: 0;
  }

  .skeleton__content > & {
    display: block;
    cursor: default;
  }

  &--full-width {
    width: 100%;
  }

  .check--hide-label & {
    padding-left: var(--check-indicator-size);
  }
}

.check__content {
  display: block;
  font-weight: 400;
  position: relative;
  font-size: var(--check-content-font-size);
  line-height: var(--check-content-line-height);
  margin-top: calc((var(--check-indicator-size) - (var(--check-content-line-height) * var(--check-content-font-size))) / 2);

  .check__input ~ .check__inner:hover & {
    color: var(--check-content-hover-color);
  }

  .check__input:disabled ~ .check__inner &,
  .check__input:disabled ~ .check__inner:hover & {
    color: var(--check-disabled-text-color);
  }

  .check--vertical & {
    margin-bottom: 16px;
  }

  .check--hide-label & {
    display: none;
  }
}

.check__indicator {
  display: block;
  width: var(--check-indicator-size);
  height: var(--check-indicator-size);
  background: transparent;
  border: 1px solid var(--check-indicator-border-color);
  border-radius: 3px;
  box-shadow: $elevation-0;
  position: absolute;
  left: 0;
  color: var(--check-indicator-color);

  .check__input ~ .check__inner:hover & {
    border-color: var(--check-indicator-hover-border-color);
  }

  .check__input:checked ~ .check__inner &,
  .check__input:checked[aria-invalid='true'] ~ .check__inner & {
    background: var(--check-indicator-checked-background-color);
    border-color: var(--check-indicator-checked-border-color);
  }

  .check__input:checked ~ .check__inner:hover & {
    background: var(--check-indicator-checked-hover-background-color);
  }

  .check--indicator-middle & {
    top: 50%;
    transform: translateY(-50%);
  }

  .check__input[aria-invalid='true'] ~ .check__inner & {
    border-color: var(--check-indicator-invalid-border);
  }

  .check__input:disabled ~ .check__inner & {
    opacity: 1;
    background: var(--check-disabled-background-color);
    border-color: var(--check-disabled-border-color);
  }

  .check__input:disabled:checked ~ .check__inner & {
    color: var(--check-disabled-indicator-color);
    background: var(--check-disabled-checked-background-color);
    border-color: var(--check-disabled-checked-border-color);
  }

  .check--vertical & {
    top: 0;
    position: relative;
    margin: 0 auto;
  }

  &.skeleton__item {
    border: none;
  }
}

.check__icon {
  @include reset-icon();

  font-size: var(--check-icon-size);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;

  .check__input:checked ~ .check__inner & {
    opacity: 1;
  }
}

.check__extra,
.check__form-helper {
  padding-top: 8px;
  padding-left: calc(var(--check-indicator-size) + var(--check-indicator-margin-right));
}
