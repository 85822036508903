@import '@telia-front/sass-tokens/index.scss';

$timeline-gutter: 20px;

.timeline {
  position: relative;
}

.timeline__entry {
  position: relative;
  margin-left: $timeline-gutter;
  margin-right: $timeline-gutter;

  @media (min-width: $media-width-sm-min) {
    margin-left: 25px;
    margin-right: 25px;
  }

  &::before {
    content: '';
    position: relative;
    display: block;
    width: 3px;
    background: var(--color-neutral-border-subtlest);
    top: 0;
    bottom: 0;
    left: calc(0.5rem - 2px);
    height: 20px;

    @media (min-width: $media-width-sm-min) {
      position: absolute;
      height: calc(100% - 10px - 1rem);
      top: calc(26px + 1rem);
    }
  }

  &:last-child {
    &::before {
      @media (min-width: $media-width-sm-min) {
        height: 0;
      }
    }
  }
}

.timeline__icon {
  box-sizing: content-box;
  position: relative;
  display: block;
  top: 0;
  left: -5px;
  color: var(--color-neutral-graphic);
  padding: 5px;
  background-clip: padding-box;
  margin-top: 0;

  @media (min-width: $media-width-sm-min) {
    position: absolute;
    top: 16px;
  }

  .timeline__entry.has-warning & {
    color: var(--color-error-graphic);
  }

  .timeline__entry.is-current & {
    color: var(--color-brand-primary-graphic);
  }

  .timeline__entry.is-done & {
    color: var(--color-success-graphic);
  }
}

.timeline__content {
  position: relative;
  padding: 20px 0;
  margin-left: 0;

  @media (min-width: $media-width-sm-min) {
    padding: 20px 1em 0;
    margin-left: calc(1rem + 9px);
  }

  .timeline__entry:last-child & {
    padding-bottom: 0;
  }
}
