@import '@telia-front/sass-tokens/index.scss';

.comments__item {
  margin-bottom: 10px;

  @media (min-width: $media-width-sm-min) {
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.comments__item--level-1 {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--color-neutral-border-subtlest);

  @media (min-width: $media-width-sm-min) {
    margin-bottom: 24px;
    padding-bottom: 24px;
  }
}

.comments__item-inner {
  display: flex;
  flex-direction: column;

  @media (min-width: $media-width-sm-min) {
    flex-direction: row;
  }
}

.comments__item-sender {
  margin: 0 4px 9px 0;
  font-size: var(--type-font-size);
  line-height: var(--type-line-height);
  font-weight: 700;

  @media (min-width: $media-width-sm-min) {
    margin: 9px 20px 0 0;
  }
}

.comments__item-sender-name {
  max-width: 9.375rem;
}

.comments__item-comment-wrapper {
  width: 100%;
}

.comments__item-comment {
  display: inline-block;
  padding: 9px 17px 11px 15px;
  background-color: var(--color-neutral-bg-level-1);
  border-radius: 3px 12px 12px;
  max-width: 27.5rem;

  .comments--inverse & {
    background-color: var(--color-neutral-bg-level-2);
  }

  .comments--full-width & {
    max-width: none;
  }

  .comments__item--primary & {
    background-color: var(--color-brand-primary-bg-strong);
    color: var(--color-brand-primary-text-onbg-strong);
  }
}

.comments__item-meta {
  margin-top: 10px;
  max-width: 100%;
}

.comments__item-date {
  color: var(--color-neutral-text-subtle);
  font-size: var(--type-tiny-font-size);
  line-height: var(--type-tiny-line-height);
}

.comments__item-replies {
  padding-top: 20px;
}
