@use 'sass:map';
@use 'sass:math';

@import '@telia-front/sass-tokens/index.scss';

$modal-max-width: 1230px;
$modal-border-radius: 8px;

.modal {
  @include transition(
    (
      'animation': long,
      'properties': opacity,
    )
  );

  overflow-y: scroll !important;
  z-index: map.get($zindex, modal);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-backdrop);
  display: flex;
  padding: $grid-gutter $base-horizontal-spacing-xs;
  opacity: 0;
  overflow-x: hidden !important;

  &.is-open {
    opacity: 1;
  }

  @media (min-width: $media-width-sm-min) {
    padding: $base-horizontal-spacing;
  }

  &.modal--full-screen,
  &.modal--with-sidebar-right,
  &.modal--with-sidebar-left {
    padding: 0;
  }
}

.modal__inner {
  @include transition(
    (
      'animation': long,
      'properties': (
        opacity,
        transform,
      ),
    )
  );

  position: relative;
  display: flex;
  flex-direction: column;
  max-width: $modal-max-width;
  margin: auto;
  width: 100%;
  opacity: 0;
  transform: translateY(30px);

  &.modal__inner--with-sidebar-right {
    margin-right: 0;
    transform: translateX(30px);
  }

  &.modal__inner--with-sidebar-left {
    margin-left: 0;
    transform: translateX(-30px);
  }

  .modal.is-open & {
    opacity: 1;
    transform: translate(0, 0);
  }

  &.modal__inner--10-col {
    @media (min-width: $media-width-lg-min) {
      width: math.div(10, 12) * 100%;
      max-width: $modal-max-width * math.div(10, 12);
    }
  }

  &.modal__inner--10-col.modal__inner--with-sidebar-right,
  &.modal__inner--10-col.modal__inner--10-col.modal__inner--with-sidebar-left {
    @media (min-width: $media-width-sm-min) {
      width: math.div(10, 12) * 100%;
      max-width: $modal-max-width * math.div(10, 12);
    }
  }

  &.modal__inner--8-col {
    @media (min-width: $media-width-lg-min) {
      width: math.div(8, 12) * 100%;
      max-width: $modal-max-width * math.div(8, 12);
    }
  }

  &.modal__inner--8-col.modal__inner--with-sidebar-right,
  &.modal__inner--8-col.modal__inner--with-sidebar-left {
    @media (min-width: $media-width-sm-min) {
      width: math.div(8, 12) * 100%;
      max-width: $modal-max-width * math.div(8, 12);
    }
  }

  &.modal__inner--6-col {
    @media (min-width: $media-width-lg-min) {
      width: math.div(6, 12) * 100%;
      max-width: $modal-max-width * math.div(6, 12);
    }
  }

  &.modal__inner--6-col.modal__inner--with-sidebar-right,
  &.modal__inner--6-col.modal__inner--with-sidebar-left {
    @media (min-width: $media-width-sm-min) {
      width: math.div(6, 12) * 100%;
      max-width: $modal-max-width * math.div(6, 12);
    }
  }

  &.modal__inner--4-col {
    @media (min-width: $media-width-lg-min) {
      width: math.div(4, 12) * 100%;
      max-width: $modal-max-width * math.div(4, 12);
    }
  }

  &.modal__inner--4-col.modal__inner--with-sidebar-right,
  &.modal__inner--4-col.modal__inner--with-sidebar-left {
    @media (min-width: $media-width-sm-min) {
      width: math.div(4, 12) * 100%;
      max-width: $modal-max-width * math.div(4, 12);
    }
  }

  &.modal__inner--2-col {
    @media (min-width: $media-width-lg-min) {
      width: math.div(2, 12) * 100%;
      max-width: $modal-max-width * math.div(2, 12);
    }
  }

  &.modal__inner--2-col.modal__inner--with-sidebar-right,
  &.modal__inner--2-col.modal__inner--with-sidebar-left {
    @media (min-width: $media-width-sm-min) {
      width: math.div(2, 12) * 100%;
      max-width: $modal-max-width * math.div(2, 12);
    }
  }

  &.modal__inner--with-sidebar-right,
  &.modal__inner--with-sidebar-left {
    border-radius: 0;
    min-height: 100vh;
  }
}

.modal__box {
  display: flex;
  width: 100%;
  margin: auto;
  position: relative;
  background: var(--color-neutral-bg-level-3);
  box-shadow: $elevation-5;
  border-radius: $modal-border-radius;

  .modal__inner--full-screen & {
    min-height: 100vh;
    border-radius: 0;
  }

  .modal__inner--full-height & {
    min-height: calc(100vh - (#{$grid-gutter} * 2));
  }

  .modal__inner--vertical-align-top & {
    margin-top: 0;
  }

  .modal__inner--with-sidebar-right &,
  .modal__inner--with-sidebar-left & {
    min-height: 100vh;
  }
}

.modal__body {
  padding: 40px $base-horizontal-spacing-xs;

  .modal__inner--full-screen &,
  .modal__inner--full-height & {
    flex-grow: 1;
  }

  @media (min-width: $media-width-sm-min) {
    padding: 40px;
  }

  @media (max-width: $media-width-xs-max) {
    padding: 32px $modal-horizontal-spacing-xs;
  }
}

.modal__close-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;

  .modal__inner--with-sticky-header & {
    height: 100%;
  }
}

.modal__close-icon {
  @include reset-icon();

  font-size: 24px;
  vertical-align: top;
}

.modal__box-inner {
  width: 100%;

  .modal__inner--full-screen &,
  .modal__inner--full-height & {
    display: flex;
    flex-direction: column;
  }

  .modal__inner--cover & {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: $media-width-md-min) {
      width: 66.6666%;
    }
  }
}

.modal__cover {
  display: none;
  position: relative;
  width: 33.3333%;
  padding: 40px;
  overflow: hidden;
  border-radius: $modal-border-radius 0 0 $modal-border-radius;
  background-size: cover;
  background-position: 50% 50%;

  .modal__inner--cover & {
    @media (min-width: $media-width-md-min) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.modal__cover--fill {
  padding: 0;
}

.modal__cover-icon {
  width: 100%;
  height: 100%;
}

.modal__footer {
  padding: 20px $base-horizontal-spacing-xs;
  background-color: var(--color-neutral-bg-level-2);
  border-radius: 0 0 $modal-border-radius $modal-border-radius;

  @media (min-width: $media-width-sm-min) {
    padding-left: 40px;
    padding-right: 40px;
  }

  .modal__inner--full-screen & {
    border-radius: 0;
  }
}

.modal__footer--sticky {
  position: sticky;
  bottom: -20px;
  z-index: map.get($zindex, modal-footer);
}

.modal__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal__header {
  z-index: 1;
  background-color: var(--color-neutral-bg-level-2);
  border-radius: $modal-border-radius $modal-border-radius 0 0;

  .modal__inner--full-screen & {
    border-radius: 0;
  }

  .modal__inner--with-sticky-header & {
    position: sticky;
    top: -20px;
  }
}

.modal__header--dark {
  background-color: var(--color-brand-primary-bg-subtle);
  color: var(--color-neutral-text);
}

.modal__header,
.modal__footer {
  min-height: 80px;
  padding: 16px $modal-horizontal-spacing-xs;
  display: flex;
  align-items: center;

  @media (min-width: $media-width-sm-min) {
    padding-left: 40px;
    padding-right: 40px;
  }

  &.text-center {
    justify-content: center;
  }

  &.text-left {
    justify-content: start;
  }

  &.text-right {
    justify-content: end;
  }

  .modal__inner--with-sidebar-right &,
  .modal__inner--with-sidebar-left & {
    border-radius: 0;
  }
}

.modal__close {
  .modal__inner--with-sticky-header & {
    position: sticky;
    top: -10px;
  }
}
