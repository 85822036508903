@import '@telia-front/sass-tokens/index.scss';

.logo {
  display: block;
}

.logo--small {
  width: 4.75rem;

  &.logo--diil {
    width: 3.75rem;
  }

  &.logo--super {
    width: 2.5rem;
  }

  &.logo--simpel {
    width: 5rem;
  }

  &.logo--greenit {
    width: 6.375rem;
  }

  &.logo--telia-tv {
    width: 6.813rem;
  }

  &.digital-hub-logo {
    width: 5.875rem;
  }
}

.logo__img {
  display: block;
  width: 100%;
  height: auto;
}

.logo__path {
  .logo--color-neutral & {
    fill: var(--color-neutral-graphic);
  }

  [data-theme='dark'] .logo--telia &,
  .logo--telia[data-theme='dark'] &,
  [data-theme='dark'] .logo--greenit &,
  .logo--greenit[data-theme='dark'] &,
  [data-theme='dark'] .logo--telia-tv &,
  .logo--telia-tv[data-theme='dark'] &,
  [data-theme='dark'] .digital-hub-logo &,
  .digital-hub-logo[data-theme='dark'] & {
    fill: #ffffff;
  }
}

// Diil logo only, needs an "inverted" text color.
.logo__path-foreground {
  .logo--color-neutral & {
    fill: var(--color-neutral-bg-level-1);
  }
}
