@use 'sass:map';

@import '@telia-front/sass-tokens/index.scss';

.product-comparison {
  margin-top: -30px;
}

/**
 * Nav
 */

.product-comparison__nav {
  // important to override a very specific selector in button styles
  // the default there is relative so forcing absolute here does not cause issues
  position: absolute !important;
  z-index: 1;
  top: 17px;
}

.product-comparison__nav--next {
  right: 15px;
}

.product-comparison__nav--prev {
  left: 15px;
}

/**
 * Wrapper and container
 */

.product-comparison__affix {
  transition-property: top, transform;
  transform: translateY(0);

  @media (max-width: $media-width-md-max) {
    position: fixed;
    z-index: map.get($zindex, affix);
    left: 0;
    right: 0;
  }

  .product-comparison:not(.has-scrolled) & {
    transform: translateY(-200%);

    @media (min-width: $media-width-lg-min) {
      transform: translateY(0);
    }
  }
}

.product-comparison__container {
  padding-top: 20px;
  padding-bottom: 20px;

  @media (min-width: $media-width-lg-min) {
    padding-top: 10px;
    padding-bottom: 0;
  }
}

.product-comparison__wrapper {
  position: relative;
  background: var(--color-neutral-bg-level-1);
  box-shadow: $elevation-1;
}

.product-comparison__content-wrapper {
  overflow: auto;
  padding-bottom: 1px;
}

/**
 * Table
 */

.product-comparison__table {
  padding-bottom: 0;

  .product-comparison__table-row:last-child {
    border-bottom: 1px solid var(--color-neutral-border-subtle);

    .product-comparison__wrapper & {
      border-bottom: 0;
    }
  }

  .product-comparison__content-wrapper & {
    overflow: visible;
  }
}

.product-comparison__table-cell {
  .product-comparison__table & {
    padding: 10px 15px;
    border-left: 1px solid var(--color-neutral-border-subtle);

    @media (min-width: $media-width-sm-min) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (min-width: $media-width-lg-min) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .product-comparison__table-row-no-borders & {
    border-left: 0;
  }

  .product-comparison.show-difference .product-comparison__content-wrapper & {
    &.no-difference {
      opacity: 0.5;
    }

    &.has-difference {
      box-shadow: inset 2px 0 var(--color-success-border);
    }
  }
}

th.product-comparison__table-cell {
  .product-comparison__table & {
    vertical-align: middle;

    &:first-child {
      border-left: 0;
    }
  }
}

.product-comparison__table-row--images {
  .product-comparison__affix & {
    @media (max-width: $media-width-md-max) {
      display: none;
    }

    + .product-comparison__table-row {
      @media (max-width: $media-width-md-max) {
        border-top: 0;
      }
    }
  }
}

/**
 * Category
 */

.product-comparison__category-heading {
  margin-top: 0;
  padding: 20px 0 10px 15px;
  border-bottom: 1px solid var(--color-neutral-border);

  @media (min-width: $media-width-sm-min) {
    padding-left: 20px;
  }

  @media (min-width: $media-width-lg-min) {
    padding: 40px 0 15px;
  }
}

/**
 * Item
 */

.product-comparison__item-image-container {
  display: inline-flex;
  position: relative;
  margin-top: 10px;

  @media (min-width: $media-width-lg-min) {
    margin-top: 0;
  }
}

.product-comparison__item-image {
  max-width: 6.25rem;
  max-height: 6.25rem;

  @media (min-width: $media-width-lg-min) {
    max-width: 5.625rem;
    max-height: 5.625rem;
  }
}

/**
 * Pager
 */

.product-comparison__pager {
  list-style: none;
  position: relative;
  z-index: 0;
  background: var(--color-neutral-bg-level-1);
  border-top: 1px solid var(--color-neutral-border-subtle);
  border-bottom: 1px solid var(--color-neutral-border-subtle);
  width: 100%;
  padding: 8px 56px;
  transition: transform 0.5s ease;

  @media (min-width: $media-width-md-min) {
    display: none;
  }
}

.product-comparison__pager-slider {
  position: static;
  width: calc((2.5rem + 2px) * 4 + 3px);
  margin: 0 auto;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    transition: all 0.5s ease;

    &:focus {
      outline: none;
    }
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    // important to override inline style
    transform: none !important;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-slide {
    display: flex;
    transition: all 0.5s ease;
    position: relative;
    width: calc(2.5rem + 2px);
    border: 1px solid var(--color-neutral-border-subtlest);

    &:not(:last-child) {
      border-right: 0;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      left: -1px;
      bottom: -1px;
      right: -1px;
      pointer-events: none;
      border: 2px solid var(--color-brand-primary-border);
      border-right-width: 0;
      opacity: 0;
    }

    &.slick-active {
      pointer-events: auto;

      &::after {
        opacity: 1;
      }

      + .slick-active {
        &::after {
          border-left-width: 0;
        }
      }

      + .slick-slide:not(.slick-active) {
        &::after {
          opacity: 1;
          border-top-width: 0;
          border-bottom-width: 0;
          border-left-width: 2px;
        }
      }

      &:last-child {
        &::after {
          border-right-width: 2px;
        }
      }
    }

    > div {
      display: flex;
      width: 100%;
    }
  }

  .product-comparison__pager--1 & {
    width: calc((2.5rem + 2px) * 1 + 3px);
  }

  .product-comparison__pager--2 & {
    width: calc((2.5rem + 2px) * 2 + 3px);
  }

  .product-comparison__pager--3 & {
    width: calc((2.5rem + 2px) * 3 + 3px);
  }
}

.product-comparison__pager-item {
  width: 2.5rem;
  height: 2.5rem;
  padding: 5px;
  border: 0;
  background: transparent;
}

.product-comparison__check {
  @media (min-width: $media-width-lg-min) {
    margin-top: 20px;
  }
}

/**
 * Remove button
 */

.product-comparison__remove-btn {
  display: block;
  border: 0;
  margin: 0;
  background: transparent;
  border-radius: 50%;
  font-size: 1.5rem;
  line-height: 1;
  padding: 8px;
  color: var(--color-neutral-graphic);
  transition: color $transition-default;
  position: absolute;
  z-index: 0;
  top: -16px;
  right: -16px;

  &::before {
    position: absolute;
    z-index: -1;
    inset: 0;
    background-color: var(--color-neutral-bg-level-3);
    border-radius: 50%;
    opacity: 0.5;
    content: '';
  }

  html[data-whatintent='mouse'] &:hover {
    color: var(--color-brand-primary-text-hover);
  }
}

.product-comparison__remove-btn-icon {
  display: block;
  @include reset-icon();
}

/**
 * Placeholder link
 */

.product-comparison__placeholder-link {
  position: relative;
  display: block;
  max-width: 5.625rem;
  max-height: 5.625rem;
  text-align: center;
  margin-top: 10px;

  @media (min-width: $media-width-lg-min) {
    margin-top: 0;
  }

  .product-comparison__pager & {
    margin-top: 0;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }
}

.product-comparison__placeholder-link-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.product-comparison__placeholder-link-icon {
  font-size: 1.5rem;
  color: var(--color-neutral-graphic);
  margin-bottom: 10px;
  flex-shrink: 0;

  .product-comparison__pager & {
    color: var(--color-brand-primary-graphic);
    margin-bottom: 0;

    html[data-whatintent='mouse'] &:hover {
      color: var(--color-brand-primary-text-hover);
    }
  }
}

.product-comparison__placeholder-link-label {
  .product-comparison__pager & {
    @include visually-hidden();
  }
}
