@import '@telia-front/sass-tokens/index.scss';

.option-card-group:not(.option-card-group__carousel) {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.option-card-group__legend {
  display: contents;
}

.option-card-group__carousel-legend {
  @include visually-hidden();
}

.option-card-group__carousel-container {
  min-inline-size: inherit;
}

.option-card-group__label,
.option-card-group__helper {
  .option-card-group__carousel & {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
