@import '@telia-front/sass-tokens/index.scss';

.product-card__header {
  width: 60px;
  min-width: 60px;
  margin: -10px 0 -10px -5px;

  @media (min-width: $media-width-sm-min) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80px;
    min-width: 80px;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.product-card__figure {
  position: relative;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  transition: 300ms cubic-bezier(0.22, 0.1, 0.25, 1);
  transition-property: transform, margin-bottom;
  will-change: transform, margin-bottom;
  transform-origin: 50% 0;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }
}

.product-card__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.card.product-card {
  .product-card__image--copy {
    left: 260px;
    visibility: hidden;
    opacity: 0;
    transition: 300ms cubic-bezier(0.22, 0.1, 0.25, 1);
    transition-property: visibility, opacity, left;
  }

  .product-card__image-compare-icon {
    visibility: hidden;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 230px;
    font-size: 24px;
    color: var(--color-neutral-graphic);
    opacity: 0;
    transition: 300ms cubic-bezier(0.22, 0.1, 0.25, 1);
    transition-property: visibility, opacity, left;
  }

  &.product-card--hover-compare {
    .product-card__image--copy {
      visibility: visible;
      left: 240px;
      opacity: 1;
    }

    .product-card__image-compare-icon {
      visibility: visible;
      left: 208px;
      opacity: 1;
    }
  }
}

.product-card__content {
  overflow: hidden;
}

.product-card__main {
  width: 100%;

  @media (min-width: $media-width-sm-min) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.product-card__info-inner {
  padding-top: 24px;
}

.product-card__tags {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: -12px;
  text-align: center;
}

.product-card__colors {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.product-card__color-select {
  width: 100%;
  margin: 0 auto 8px;

  .select__text-wrapper {
    padding-top: 3px;
  }

  .select__value-container {
    padding-top: 0;
  }

  .select__option-children {
    display: block;
  }
}

.product-card__color {
  display: inline-block;
  border-radius: 50%;
  padding: 2px;
  border: 1px solid transparent;

  &.is-current {
    border-color: var(--color-brand-primary-border);
  }
}

.product-card__color-inner {
  width: 16px;
  height: 16px;
  border-radius: 50%;

  .product-card__color-select & {
    margin-top: -2px;
  }
}

.product-card__color-label {
  font-size: 0.75rem;
  line-height: 1.33;

  .product-card & {
    width: 100%;
    margin-left: 0;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.product-card__memory-selector {
  margin: 8px 0;
}

.product-card__price {
  @media (min-width: $media-width-md-min) {
    font-size: 1.125rem;
    line-height: 1.3;
  }
}

.product-card__discount-price,
.product-card__current-price {
  font-size: 1.125rem;
  line-height: 1.33;
  font-weight: 700;

  @media (min-width: $media-width-md-min) {
    font-size: 1.375rem;
    line-height: 1.27;
  }
}

.product-card--inactive {
  p,
  h5,
  h4 {
    color: var(--color-neutral-text-subtle);
  }
}

.product-card__compare-button {
  display: block;
  position: absolute;
  z-index: 2;
  top: 8px;
  left: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 0;
  padding: 0;
  font-size: 20px;
  color: var(--color-neutral-graphic);
  padding-bottom: 1px;
  background-color: var(--color-neutral-bg-level-2);

  &.is-active {
    color: var(--color-brand-primary-text-onbg-strong);
    background-color: var(--color-brand-primary-bg-strong);

    .product-card &:hover {
      background-color: var(--color-brand-primary-bg-strong-hover);
    }
  }

  @media (min-width: $media-width-md-min) {
    top: 16px;
    left: 16px;
  }
}

.product-card__compare-button-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-card__compare-button-text {
  .product-card & {
    @include visually-hidden();
  }
}

.product-card__compare-button-icon {
  @include reset-icon();

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .product-card__compare-button:hover & {
    opacity: 0;
  }
}

.product-card__compare-button-icon--add {
  opacity: 0;

  .product-card__compare-button:not(.is-active):hover & {
    opacity: 1;
  }
}

.product-card__compare-button-icon--remove {
  opacity: 0;

  .product-card__compare-button.is-active:hover & {
    opacity: 1;
  }
}

.product-card__compare-button-active-icon {
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 20px;
}

.product-card__transit {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;

  @media (min-width: $media-width-lg-min) {
    font-size: 14px;
  }
}

.product-card__transit-icon {
  @include reset-icon();

  font-size: 24px;
  margin-right: 4px;
}

.product-card__inactive-content {
  font-weight: 700;

  .product-card & {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-neutral-text);
    text-align: center;
  }
}

.product-card__cart-button-wrapper {
  margin-top: 24px;
  text-align: center;
}

.product-card__cart-button-helper {
  justify-content: center;
  margin-top: 10px;
}

.product-card__features {
  margin-top: 5px;
}

.product-card__feature-icon {
  color: var(--color-success-graphic);
  font-size: var(--type-font-size);
}

.product-card__monthly-price-inner,
.product-card__price-inner {
  width: 100%;
}

.product-card__footer {
  display: block;
  overflow: hidden;
  background-color: var(--color-neutral-bg-level-2);
  margin: 16px -16px -16px;
  padding: 16px;

  @media (min-width: $media-width-md-min) {
    margin: 16px -24px -16px;
    padding: 16px 24px 24px;
  }

  .telia-tag--tiny {
    white-space: nowrap;

    + p {
      margin-top: 4px;
    }
  }
}

.product-card__footer--column-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  gap: 6px;
}

.product-card__footer--column-left,
.product-card__footer--column-right {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.product-card__footer--column-right {
  text-align: right;
}

.product-card__footer--narrow {
  .product-card__footer--column-inner {
    flex-direction: column;
    gap: 8px;
  }

  .product-card__footer--column-right {
    text-align: left;
  }
}

.product-card--type-light {
  .product-card__footer {
    background-color: transparent;
    border-top: 1px solid var(--color-neutral-border-subtle);
  }
}

.product-card--type-campaign {
  .product-card__footer {
    background-color: var(--color-campaign-2-bg);
  }

  .product-card__discount-price {
    color: var(--color-campaign-2-text);
  }
}

.product-card__price-months,
.product-card__price-text {
  display: inline-block;
  white-space: nowrap;
}
