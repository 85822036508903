@import '@telia-front/sass-tokens/index.scss';

.number {
  --number-input-width: 3.75rem;

  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.number--disabled {
  color: var(--color-disabled-text);
}

.number--align-center {
  align-items: center;
}

.number--small {
  --number-input-width: 2.5rem;
}

.number__label {
  display: block;
  font-size: var(--type-small-font-size);
  line-height: var(--type-small-line-height);

  .number--hide-label & {
    @include visually-hidden();
  }
}

.number__inner {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  width: fit-content;

  .number--full-width & {
    width: auto;
  }

  &:focus-within {
    @include keyboard-focus();
    border-radius: 4px;
  }
}

.number__input {
  border: 1px solid var(--color-neutral-border);
  display: block;
  font-size: var(--type-font-size);
  line-height: var(--type-line-height);
  padding: 0;
  height: $btn-min-height;
  width: var(--number-input-width);
  background: none;
  text-align: center;
  color: inherit;
  appearance: textfield;
  outline: none;
  margin-left: -1px;
  margin-right: -1px;

  .number--small & {
    height: $btn-small-min-height;
  }

  .number--full-width & {
    width: 100%;
  }

  .number--disabled & {
    border-color: var(--color-disabled-border);
  }

  .number--invalid & {
    border: 2px solid var(--color-error-border);
    z-index: 2;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
}

.number__button--decrease {
  border-radius: 4px 0 0 4px;
}

.number__button--increase {
  border-radius: 0 4px 4px 0;
}

.number__label-icon {
  color: var(--color-employee-graphic);
  margin-right: 4px;
}
