@import '@telia-front/sass-tokens/index.scss';

.sort-indicator {
  @include reset-button();

  position: relative;
  cursor: pointer;
  width: 100%;
  padding: 2px 0;
  border-radius: 4px;

  @include transition(
    (
      'animation': short,
      'properties': (
        color,
      ),
    )
  );

  html[data-whatintent='mouse'] &:hover {
    color: var(--color-brand-primary-text-hover);
  }
}

.sort-indicator__text {
  display: inline-block;
  padding-right: calc(1rem + 8px);
}

.sort-indicator__arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 1.375rem;
  color: var(--color-neutral-graphic);

  @include transition(
    (
      'animation': short,
      'properties': (
        color,
      ),
    )
  );

  html[data-whatintent='mouse'] .sort-indicator:hover & {
    color: var(--color-brand-primary-text-hover);
  }
}

.sort-indicator__arrows-up {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75rem;

  .sort-indicator--asc & {
    top: 50%;
    transform: translateY(-50%);
    font-size: 1rem;
  }

  .sort-indicator--desc & {
    display: none;
  }
}

.sort-indicator__arrows-down {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.75rem;

  .sort-indicator--desc & {
    top: 50%;
    transform: translateY(-50%);
    font-size: 1rem;
  }

  .sort-indicator--asc & {
    display: none;
  }
}
