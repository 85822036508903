@import '@telia-front/sass-tokens/index.scss';

@mixin text-align($name) {
  .text-center-#{$name} {
    text-align: center;
  }

  .text-left-#{$name} {
    text-align: left;
  }

  .text-right-#{$name} {
    text-align: right;
  }
}

small,
.text-small {
  font-size: var(--type-small-font-size);
  line-height: var(--type-small-line-height);

  @media (min-width: $media-width-xl-min) {
    font-size: var(--type-small-font-size);
    line-height: var(--type-small-line-height);
  }
}

strong,
.text-bold {
  font-weight: 700;
}

em,
.text-italic {
  font-style: italic;
}

.text-weight-normal {
  font-weight: 400 !important;
}

.text-size-normal {
  font-size: var(--type-font-size) !important;
  line-height: var(--type-line-height) !important;

  @media (min-width: $media-width-xl-min) {
    font-size: var(--type-font-size-xl, var(--type-font-size)) !important;
    line-height: var(--line-height-xl, var(--type-line-height)) !important;
  }
}

.text-color-normal {
  color: var(--color-neutral-text) !important;
}

.text-white-space-normal {
  white-space: normal !important;
}

.text-normal {
  @extend .text-size-normal;
  @extend .text-weight-normal;
  @extend .text-color-normal;
}

.text-expressive {
  color: var(--color-brand-expressive-text);
}

.text-attention {
  color: var(--color-attention-text);
}

.text-error {
  color: var(--color-error-text);
}

.text-info {
  color: var(--color-info-text);
}

.text-employee {
  color: var(--color-employee-text);
}

// Deprecated. Use dark theme instead.
.text-white {
  color: $white;
}

// Text utilities

.text-tiny {
  color: var(--color-neutral-text-subtle);
  font-size: var(--type-tiny-font-size);
  line-height: var(--type-tiny-line-height);

  @media (min-width: $media-width-xl-min) {
    font-size: var(--type-tiny-font-size);
    line-height: var(--type-tiny-line-height);
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

// We need it to override text-tiny in some cases (InternetSpeedIndicator)...
.text-primary {
  color: var(--color-neutral-text);
}

// Deprecated. Use dark theme instead.
.text-primary-dark {
  color: $color-raw-white-strong;
}

// Deprecated. Use dark theme instead.
.text-light-dark {
  color: $color-raw-white-medium;
}

// Deprecated. Use dark theme instead.
.text-inactive-dark {
  color: $color-raw-white-dull;
}

.text-beige {
  color: var(--color-brand-secondary-text);
}

.text-campaign {
  color: var(--color-campaign-2-text);
}

.text-success {
  color: var(--color-success-text);
}

.text-light {
  color: var(--color-neutral-text-subtle);
}

.text-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.text-inactive {
  color: var(--color-neutral-text-subtle);
}

/**
 * Text helpers
 */

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

@media (min-width: $media-width-sm-min) {
  @include text-align(sm);
}

@media (min-width: $media-width-md-min) {
  @include text-align(md);
}

@media (min-width: $media-width-lg-min) {
  @include text-align(lg);
}

@media (min-width: $media-width-xl-min) {
  @include text-align(xl);
}
