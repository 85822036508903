@import '@telia-front/sass-tokens/index.scss';

.choice-group {
  --choice-group-gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--choice-group-gap);
  position: relative;
  width: 100%;
  max-width: 100%;
}

.choice-group--center {
  text-align: center;
  align-items: center;
}

.choice-group__inner {
  display: flex;
  gap: var(--choice-group-gap);
  flex-wrap: wrap;
  flex-direction: column;

  .choice-group--inline & {
    flex-direction: row;
  }

  .choice-group--center & {
    justify-content: center;
  }
}

.choice-group__inner-card {
  .is-invalid &::before {
    @include transition(
      (
        'animation': short,
        'properties': (
          border-color,
          box-shadow,
        ),
      )
    );

    box-shadow: inset 0 0 0 1px var(--color-error-border);
  }
}

.choice-group__label {
  margin-bottom: var(--choice-group-gap);

  .choice-group--no-label-no-break & {
    @include visually-hidden();
  }

  .choice-group--no-label-sm & {
    @media (min-width: $media-width-sm-min) {
      @include visually-hidden();
    }
  }

  .choice-group--no-label-md & {
    @media (min-width: $media-width-md-min) {
      @include visually-hidden();
    }
  }
}

.choice-group__form-helper {
  .choice-group--center & {
    justify-content: center;
  }
}
