@use 'sass:map';

@import '@telia-front/sass-tokens/index.scss';

$tag-size: 96px;
$tag-size-small: 64px;
$tag-size-medium: 80px;
$tag-padding: 15%;
$tag-padding-small: 15%;
$tag-font-size-size-tiny: 9px;
$tag-font-size-size-small: 11px;
$tag-font-size-height-small: 14px;
$tag-font-size-size-medium: 14px;
$tag-font-size-size-large: 16px;
$tag-font-size-size-extra-large: 20px;
$tag-font-size-height: calc(1em + 2px);
$tag-svg-size: 24px;
$tag-svg-size-icon: 40px;
$tag-svg-size-icon-small: 32px;
$tag-text-weight: 700;
$tag-tiny-padding-horizontal: 8px;
$tag-tiny-border-radius: 4px;
$tag-tiny-font-size: var(--type-tiny-font-size);
$tag-purpose-service-border-color: var(--color-neutral-border-subtle);
$tag-purpose-service-text-color: var(--color-neutral-text);
$tag-default-background-color: var(--color-brand-primary-bg-strong);
$tag-default-text-color: var(--color-brand-primary-text-onbg-strong);

$tag-colors: (
  primary: (
    default: var(--color-brand-primary-bg-strong),
    text: var(--color-brand-primary-text-onbg-strong),
  ),
  recommended: (
    default: var(--color-brand-expressive-bg-subtle),
    text: var(--color-brand-expressive-text),
  ),
  attention: (
    default: var(--color-attention-bg-subtle),
    text: var(--color-attention-text),
  ),
  employee: (
    default: var(--color-employee-bg-strong),
    text: var(--color-employee-text-onbg-strong),
  ),
  success: (
    default: var(--color-success-bg-subtle),
    text: var(--color-success-text),
  ),
  information: (
    default: var(--color-info-bg-subtle),
    text: var(--color-info-text),
  ),
  service: (
    default: var(--color-neutral-bg-level-1),
  ),
  error: (
    default: var(--color-error-bg-strong),
    text: var(--color-error-text-onbg-strong),
  ),
  campaign-1: (
    default: var(--color-campaign-1-bg),
    text: var(--color-campaign-1-text),
  ),
  campaign-2: (
    default: var(--color-campaign-2-bg),
    text: var(--color-campaign-2-text),
  ),
  campaign-3: (
    default: var(--color-campaign-3-bg),
    text: var(--color-campaign-3-text),
  ),
  campaign-4: (
    default: var(--color-campaign-4-bg),
    text: var(--color-campaign-4-text),
  ),
  text: (
    default: var(--color-neutral-bg-subtle),
    text: var(--color-neutral-text),
  ),
);

.telia-tag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: $tag-size;
  height: $tag-size;
  border-radius: 50%;
  color: $tag-default-text-color;
  text-align: center;
  position: relative;
  background: $tag-default-background-color;
}

/* Modifier: sizes */

.telia-tag--icon {
  width: $tag-size-medium;
  height: $tag-size-medium;

  .fittext {
    line-height: $tag-font-size-height;
  }
}

.telia-tag--small {
  width: $tag-size-small;
  height: $tag-size-small;

  .fittext {
    line-height: $tag-font-size-height;
  }
}

.telia-tag--medium {
  width: $tag-size-medium;
  height: $tag-size-medium;

  .fittext {
    line-height: $tag-font-size-height;
  }
}

.telia-tag--large {
  width: $tag-size;
  height: $tag-size;

  .fittext {
    line-height: $tag-font-size-height;
  }
}

.telia-tag--tiny {
  display: inline-flex;
  width: auto;
  height: auto;
  border-radius: $tag-tiny-border-radius;
  text-transform: uppercase;
}

.telia-tag--disabled {
  opacity: 0.5;
}

.telia-tag--tooltip {
  border: none;
}

/* Modifier: Purposes */

@each $name, $colors in $tag-colors {
  .telia-tag--#{$name} {
    background: map.get($colors, 'default');
    color: map.get($colors, 'text');
  }
}

/* Modifier: Font sizes */

.telia-tag__text--tiny {
  font-size: $tag-font-size-size-tiny;
  line-height: $tag-font-size-height;
}

.telia-tag__text--small {
  font-size: $tag-font-size-size-small;
  line-height: $tag-font-size-height-small;
}

.telia-tag__text--medium {
  font-size: $tag-font-size-size-medium;
  line-height: $tag-font-size-height;
}

.telia-tag__text--large {
  font-size: $tag-font-size-size-large;
  line-height: $tag-font-size-height;
}

.telia-tag__text--extra-large {
  font-size: $tag-font-size-size-extra-large;
  line-height: $tag-font-size-height;
}

.telia-tag--service {
  color: $tag-purpose-service-text-color;
  border: 1px solid $tag-purpose-service-border-color;
}

.telia-tag__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  width: 100%;
  padding: $tag-padding;

  .telia-tag--animating & {
    transition: all 300ms cubic-bezier(0.2, 0.65, 0.35, 1);
    transform: translate(-50%, calc(-50% + 8px));
    opacity: 0;
  }

  .telia-tag--small & {
    padding: $tag-padding-small;
  }

  .telia-tag--tiny & {
    display: inline-flex;
    position: relative;
    transform: none;
    opacity: 1;
    transition: none;
    left: auto;
    top: auto;
    padding: $tag-tiny-padding-vertical $tag-tiny-padding-horizontal;

    &:not(:first-child) {
      @include visually-hidden();
    }
  }
}

.telia-tag--animating .telia-tag__content--fade-in {
  transition: all 300ms cubic-bezier(0.2, 0.65, 0.35, 1);
  transform: translate(-50%, -50%);
  opacity: 1;
}

.telia-tag--animating .telia-tag__content--faded-in {
  transform: translate(-50%, -50%);
  opacity: 1;
}

.telia-tag--animating .telia-tag__content--fade-out {
  transition: all 300ms 50ms cubic-bezier(0.5, 0, 0.65, 0.2);
  transform: translate(-50%, calc(-50% - 8px));
  opacity: 0;
}

/* Element: Icon */

.telia-tag__icon {
  @include reset-icon();

  flex-basis: $tag-svg-size;
  width: $tag-svg-size;
  height: $tag-svg-size;

  .telia-tag--animating:not(.telia-tag--large) &,
  .telia-tag--icon:not(.telia-tag--large) & {
    flex-basis: $tag-svg-size-icon;
    width: $tag-svg-size-icon;
    height: $tag-svg-size-icon;
  }

  .telia-tag--animating.telia-tag--small &,
  .telia-tag--icon.telia-tag--small & {
    flex-basis: $tag-svg-size-icon-small;
    width: $tag-svg-size-icon-small;
    height: $tag-svg-size-icon-small;
  }
}

/* Element: Text */

.telia-tag__text {
  font-weight: $tag-text-weight;
  width: 100%;
  margin: 4px 0;

  .telia-tag--tiny & {
    margin: 0;
    font-size: $tag-tiny-font-size;
    line-height: $tag-tiny-font-height;
  }
}

.telia-tag__text--hidden {
  @include visually-hidden();
}

/* Element: Image */

.telia-tag__image {
  width: 60px;

  .telia-tag--medium & {
    width: 50px;
  }

  .telia-tag--small & {
    width: 40px;
  }
}
