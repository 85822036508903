@import '@telia-front/sass-tokens/index.scss';

$close-button-modal-size: 40px;

.close-button {
  line-height: 0;
  padding: 8px;
  color: var(--color-brand-primary-text-hover);
  border: none;
  background: none;
  border-radius: 50%;
}

.close-button--type-modal {
  padding: 0;
}

.close-button__inner {
  display: block;
  position: relative;
  width: 24px;
  height: 24px;
  background-color: transparent;

  .close-button--type-modal & {
    width: $close-button-modal-size;
    height: $close-button-modal-size;
    padding: 0;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: $close-button-modal-size;
      height: $close-button-modal-size;
      background-color: var(--color-neutral-bg-level-3);
      border-radius: 50%;
      opacity: 0.8;
      content: '';
    }
  }
}

.close-button__icon {
  @include reset-icon();

  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--color-neutral-graphic);
  font-size: 1.5rem;
  transform: translate(-50%, -50%);

  html[data-whatintent='mouse'] .close-button:hover & {
    color: inherit;
  }
}
