@import '@telia-front/sass-tokens/index.scss';

.form-helper {
  display: flex;
  font-size: var(--type-small-font-size);
  line-height: var(--type-small-line-height);
  text-align: left;
  gap: 4px;
}

.form-helper--large {
  font-size: var(--type-font-size);
  line-height: var(--type-line-height);
  gap: 8px;
}

.form-helper--help {
  color: var(--color-neutral-text-subtle);
}

.form-helper--valid {
  color: var(--color-success-text);
}

.form-helper--error {
  color: var(--color-error-text);
}

.form-helper__icon {
  font-size: 1rem;
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  margin-block: 0.125rem;

  .form-helper--help & {
    color: var(--color-info-graphic);
  }

  .form-helper--error & {
    color: var(--color-error-graphic);
  }

  .form-helper--valid & {
    color: var(--color-success-graphic);
  }

  .form-helper--employee & {
    color: var(--color-employee-graphic);
  }

  .form-helper--large & {
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-block: 0;
  }

  .spinner__circle {
    height: 0.875em;
    width: 0.875em;
  }
}

.form-helper__content {
  .form-helper--large & {
    margin-block: 0.0625rem;
  }
}
