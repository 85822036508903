@import '@telia-front/sass-tokens/index.scss';

.tab-cordion.tab-cordion--background-light {
  &.tab-cordion--full-width {
    .card__content > & {
      margin-left: -$base-horizontal-spacing-xs;
      margin-right: -$base-horizontal-spacing-xs;

      @media (min-width: $media-width-sm-min) {
        margin-left: -$base-horizontal-spacing;
        margin-right: -$base-horizontal-spacing;
      }
    }

    .card__content--padding-none > & {
      margin-left: 0;
      margin-right: 0;
    }

    .card__content--padding-medium > &,
    .card__content--padding-cta > & {
      margin-left: -$base-horizontal-spacing-xs;
      margin-right: -$base-horizontal-spacing-xs;

      @media (min-width: $media-width-sm-min) {
        margin-left: -40px;
        margin-right: -40px;
      }
    }

    .card__content--padding-image > & {
      margin-left: -$base-horizontal-spacing-xs;
      margin-right: -$base-horizontal-spacing-xs;

      @media (min-width: $media-width-sm-min) {
        margin-left: -30px;
        margin-right: -30px;
      }
    }
  }
}
