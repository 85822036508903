@use 'sass:map';

@import '@telia-front/sass-tokens/index.scss';

.overlay {
  z-index: map.get($zindex, overlay);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--color-neutral-bg-level-1);
    opacity: 0.8;
  }
}

.overlay__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay--fixed {
  position: fixed;
}
