@import '@telia-front/sass-tokens/index.scss';

$badge-icon-size: 1rem;
$badge-background: var(--color-brand-primary-bg-strong);
$badge-color: var(--color-brand-primary-text-onbg-strong);
$badge-inactive-background: var(--color-neutral-bg-subtle);
$badge-inactive-color: var(--color-neutral-text);
$badge-recommended-background: var(--color-brand-expressive-bg-subtle);
$badge-recommended-color: var(--color-brand-expressive-text);
$badge-selected-background: var(--color-brand-primary-bg-strong);
$badge-selected-color: var(--color-brand-primary-text-onbg-strong);
$badge-success-background: var(--color-success-bg-subtle);
$badge-success-color: var(--color-success-text);
$badge-font-size-small: 0.625rem;
$badge-font-height-small: 1.1;
$badge-icon-size-small: 0.625rem;
$badge-icon-margin-small: 0;
$badge-font-weight-large: 400;
$badge-font-size-large: 1.25rem;
$badge-line-height-large-sm: 1;
$badge-size-small: 16px;
$badge-size-large: 32px;
$badge-size-large-line-height: 1.1;
$badge-size-large-icon-size-sm: 1rem;
$badge-spacing-small: 2.5px;
$badge-text-spacing-small: 0;
$badge-font-size-medium: var(--type-small-font-size);
$badge-line-height-medium: var(--type-small-line-height);
$badge-three-digit-font-weight: 700;
$badge-three-digit-large-font-size: 1rem;
$badge-three-digit-large-line-height: 1.5;
$badge-three-digit-large-line-height-sm: 1.8334;
$badge-three-digit-large-font-size-sm: 0.75rem;
$badge-three-digit-large-padding: 4px;
$badge-three-digit-large-padding-sm: 1px;
$badge-three-digit-small-font-size: 0.5rem;
$badge-three-digit-small-line-height: 1.5;
$badge-three-digit-small-padding: 2px;
$badge-two-digit-small-padding: 2px;
$badge-two-digit-small-line-height: 1.2;
$badge-two-digit-large-padding: 4px;
$badge-two-digit-large-line-height: 1.2;
$badge-two-digit-large-padding-sm: 2px;
$badge-two-digit-large-line-height-sm: 1.25;

.badge {
  display: inline-block;
  min-width: 30px;
  padding: $badge-padding;
  background: $badge-background;
  border-radius: 100rem;
  color: $badge-color;
  font-size: var(--type-tiny-font-size);
  font-weight: 700;
  line-height: $badge-line-height;
  text-align: center;
}

.badge--small {
  min-width: $badge-size-small;
  padding: $badge-spacing-small;
  font-size: $badge-font-size-small;
  line-height: $badge-font-height-small;

  &.badge--three-digits {
    font-size: $badge-three-digit-small-font-size;
    line-height: $badge-three-digit-small-line-height;
    font-weight: $badge-three-digit-font-weight;
    padding: $badge-three-digit-small-padding;
  }

  &.badge--two-digits {
    padding: $badge-two-digit-small-padding;
    line-height: $badge-two-digit-small-line-height;
  }
}

.badge--medium {
  padding: 2px 8px;
  min-width: 24px;
  line-height: $badge-line-height-medium;
  font-size: $badge-font-size-medium;
  font-weight: 700;

  &.badge--three-digits {
    padding: $badge-three-digit-large-padding-sm;
    font-size: $badge-three-digit-large-font-size-sm;
    line-height: $badge-three-digit-large-line-height-sm;
  }

  &.badge--two-digits {
    padding: $badge-two-digit-large-padding-sm;
  }
}

.badge--large {
  padding: 5px;
  min-width: $badge-size-large;
  font-size: $badge-font-size-large;
  line-height: $badge-size-large-line-height;
  font-weight: $badge-font-weight-large;

  @media (max-width: $media-width-xs-max) {
    &.badge--large-responsive {
      padding: 4px;
      min-width: 24px;
      line-height: $badge-line-height-large-sm;
      font-size: $badge-font-size-large-sm;
      font-weight: $badge-font-weight-large-sm;
    }
  }

  &.badge--three-digits,
  &.badge--three-digits.badge--large-responsive {
    font-size: $badge-three-digit-large-font-size;
    line-height: $badge-three-digit-large-line-height;
    font-weight: $badge-three-digit-font-weight;
    padding: $badge-three-digit-large-padding;
  }

  &.badge--two-digits,
  &.badge--two-digits.badge--large-responsive {
    padding: $badge-two-digit-large-padding;
    line-height: $badge-two-digit-large-line-height;
  }

  @media (max-width: $media-width-xs-max) {
    &.badge--large-responsive {
      &.badge--three-digits {
        padding: $badge-three-digit-large-padding-sm;
        font-size: $badge-three-digit-large-font-size-sm;
        line-height: $badge-three-digit-large-line-height-sm;
      }

      &.badge--two-digits {
        padding: $badge-two-digit-large-padding-sm;
        line-height: $badge-two-digit-large-line-height-sm;
      }
    }
  }
}

.badge--inactive {
  background: $badge-inactive-background;
  color: $badge-inactive-color;
}

.badge--recommended {
  background: $badge-recommended-background;
  color: $badge-recommended-color;
}

.badge--selected {
  background: $badge-selected-background;
  color: $badge-selected-color;
}

.badge--success {
  background: $badge-success-background;
  color: $badge-success-color;
}

.badge__icon {
  color: var(--color-brand-primary-text-onbg-strong);
  width: $badge-icon-size;
  height: $badge-icon-size;
  margin-left: 9px;
  vertical-align: top;

  &:only-child {
    margin: 0;
  }

  .badge--large & {
    width: $badge-size-large-icon-width;
    height: $badge-size-large-icon-height;
  }

  @media (max-width: $media-width-xs-max) {
    .badge--large.badge--large-responsive & {
      width: $badge-size-large-icon-size-sm;
      height: $badge-size-large-icon-size-sm;
    }
  }

  .badge--medium & {
    width: $badge-size-large-icon-size-sm;
    height: $badge-size-large-icon-size-sm;
    margin: 2px -4px;
  }

  .badge--small & {
    width: $badge-icon-size-small;
    height: $badge-icon-size-small;
    margin: $badge-icon-margin-small;
  }

  .badge--selected & {
    color: var(--color-brand-primary-text-onbg-strong);
  }

  .badge--recommended & {
    color: var(--color-brand-expressive-graphic);
  }

  .badge--inactive & {
    color: var(--color-neutral-graphic);
  }

  .badge--success & {
    color: var(--color-success-graphic);
  }

  .badge--attention & {
    color: var(--color-attention-graphic);
  }

  .badge--error & {
    color: var(--color-error-text-onbg-strong);
  }

  .badge--information & {
    color: var(--color-info-graphic);
  }

  .badge--employee & {
    color: var(--color-employee-graphic);
  }

  .badge--employee-active & {
    color: var(--color-employee-text-onbg-strong);
  }
}

.badge__text {
  margin-left: 6px;
  margin-right: 9px;
  vertical-align: top;
  text-transform: none;

  &:only-child {
    margin: 0 9px;
  }

  .badge--small & {
    margin: $badge-text-spacing-small;
  }

  .badge--medium &,
  .badge--large & {
    &:only-child {
      margin: 0;
    }
  }
}

.badge--attention {
  background: var(--color-attention-bg-subtle);
  color: var(--color-attention-text);
}

.badge--error {
  background: var(--color-error-bg-strong);
  color: var(--color-error-text-onbg-strong);
}

.badge--information {
  background: var(--color-info-bg-subtle);
  color: var(--color-info-text);
}

.badge--employee {
  background: var(--color-employee-bg-subtle);
  color: var(--color-employee-text);
}

.badge--employee-active {
  background: var(--color-employee-bg-strong);
  color: var(--color-employee-text-onbg-strong);
}
