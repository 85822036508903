@import '@telia-front/sass-tokens/index.scss';

$video-icon-padding: 12px 16px 12px 20px;
$video-icon-font-size: 64px;
$video-icon-padding-sm: 8px 8px 8px 16px;
$video-width: 640px;
$video-background-color: var(--color-neutral-bg-level-2);
$video-icon-background-color: var(--color-brand-primary-bg-subtle);
$video-icon-background-color-hover: var(--color-brand-primary-bg-strong);
$video-icon-fill: var(--color-brand-primary-text-subtle);
$video-icon-fill-hover: var(--color-brand-primary-text-onbg-strong);
$video-border-radius: 8px;
$video-title-background: var(--color-brand-primary-bg-strong);
$video-title-color: var(--color-brand-primary-text-onbg-strong);

.video {
  position: relative;
  width: $video-width;
  max-width: 100%;
  height: auto;
  box-shadow: $elevation-0;
  background: $video-background-color;
  border-radius: $video-border-radius;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 56.25%;
  }
}

.video--html5 {
  background: transparent;
}

.video--center {
  margin-left: auto;
  margin-right: auto;
}

.video--right {
  margin-left: auto;
}

.video__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.video__btn {
  display: block;
  position: relative;
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;

  .video.hide-overview & {
    display: none;
  }
}

.video__image {
  width: 100%;
  border-radius: $video-border-radius;
}

.video__content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
}

.video__icon-wrapper {
  display: block;
  border-radius: 50%;
  background-color: $video-icon-background-color;

  .video__btn:hover & {
    background-color: $video-icon-background-color-hover;
  }
}

.video__icon {
  @include reset-icon();

  display: block;
  font-size: $video-icon-font-size;
  padding: $video-icon-padding-sm;
  fill: $video-icon-fill;

  @media (min-width: $media-width-sm-min) {
    font-size: 100px;
    padding: $video-icon-padding;
  }

  .video__btn:hover & {
    fill: $video-icon-fill-hover;
  }
}

.video__holder {
  display: none;
  width: 100%;
  height: 100%;

  .video.hide-overview & {
    display: block;
  }
}

.video__html5,
.video__iframe {
  width: 100%;
  height: 100%;
  border-radius: $video-border-radius;
}

.video__title {
  position: absolute;
  inset: auto auto 0;
  margin: 0 8px 8px;
  display: block;
  padding: 4px 8px;
  border-radius: 4px;
  background: $video-title-background;
  color: $video-title-color;
  font-weight: 700;
  text-align: center;

  @media (min-width: $media-width-sm-min) {
    margin: 0 16px 16px;
  }
}
