@import '@telia-front/sass-tokens/index.scss';

$collapse-background-color: var(--color-neutral-bg-level-1);
$collapse-background-color-grey: var(--color-neutral-bg-level-2);
$collapse-border-color: var(--color-brand-primary-border);

.collapse {
  @include transition(
    (
      'animation': medium,
      'properties': height,
    )
  );

  position: relative;
  will-change: height;
}

.collapse__arrow {
  display: none;
  position: absolute;
  border: 0 solid transparent;
  border-bottom-color: $collapse-border-color;
  width: 0;
  height: 0;
  margin-top: -8px;
  border-width: 0 8px 8px;

  .collapse.is-entered & {
    display: block;
  }
}

.collapse__inner {
  background: $collapse-background-color;
  border-radius: 0 0 3px 3px;
  border-top: 3px solid $collapse-border-color;
  box-shadow: $elevation-1;
  padding: 40px $base-horizontal-spacing-xs 20px;
  position: relative;

  @media (min-width: $media-width-sm-min) {
    padding: 40px;
  }

  .collapse--grey & {
    background-color: $collapse-background-color-grey;
  }

  .collapse--no-container & {
    background: none;
    box-shadow: $elevation-0;
    border: none;
    padding: 0;
  }

  .collapse--no-shadow & {
    box-shadow: $elevation-0;
  }

  .collapse--no-arrow & {
    border-top: none;
  }
}

.collapse__close {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 13px;
  font-size: 24px;
  line-height: 1;
  padding: 8px;
  height: 40px;
  width: 40px;
  border: none;
  background: none;
  color: var(--color-neutral-text);

  html[data-whatintent='mouse'] &:hover {
    color: var(--color-brand-primary-text-hover);
  }
}

.collapse__close-icon {
  @include reset-icon();
}

.collapse--fade {
  &::after {
    @include transition(
      (
        'animation': medium,
        'properties': opacity,
      )
    );

    position: absolute;
    bottom: 0;
    content: '';
    opacity: 0;
    background: linear-gradient(180deg, transparent 0%, $collapse-background-color 100%);
    height: 40px;
    width: 100%;
    pointer-events: none;
  }

  &.collapse--grey::after {
    background: linear-gradient(180deg, transparent 0%, $collapse-background-color-grey 100%);
  }

  &.is-exited::after,
  &.is-exiting::after {
    @include transition(
      (
        'animation': medium,
        'properties': opacity,
      )
    );

    opacity: 1;
  }
}
