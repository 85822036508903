@use 'sass:map';
@use 'sass:math';

@import '@telia-front/sass-tokens/index.scss';

$datepicker-day-width: math.div(1, 7) * 100%;
$datepicker-bg: var(--color-neutral-bg-level-3);
$datepicker-column-border: var(--color-neutral-border-subtlest);
$datepicker-text-color: (
  hover: var(--color-brand-primary-text-hover),
  selected: var(--color-brand-primary-text-onbg-strong),
);
$datepicker-background: (
  hover: var(--color-neutral-bg-hover),
  selected: var(--color-brand-primary-bg-strong),
);
$datepicker-day-disabled-color: var(--color-neutral-text-subtle);
$datepicker-value-color: var(--color-brand-primary-text);

.datepicker {
  display: inline-block;
  vertical-align: top;
  background: $datepicker-bg;
  z-index: map.get($zindex, datepicker);

  @media (min-width: $media-width-sm-min) {
    width: auto;
  }
}

.datepicker__content {
  position: relative;
  display: block;

  @media (min-width: $media-width-sm-min) {
    display: flex;
    flex-wrap: nowrap;
  }
}

.datepicker__column {
  position: relative;
  width: 100%;
  border-top: 1px solid $datepicker-column-border;

  @media (min-width: $media-width-sm-min) {
    display: flex;
    flex-grow: 1;
    align-items: center;
    border-top: 0;
    border-right: 1px solid $datepicker-column-border;
    width: auto;
    min-width: 100px;
  }

  @media (max-width: $media-width-xs-max) {
    min-width: auto !important; // override inline style for small screens when it does not matter
  }

  &:last-child {
    border-right: 0;
  }

  &.is-open {
    max-height: 270px;

    @media (min-width: $media-width-sm-min) {
      max-height: none;
    }

    &::before,
    &::after {
      display: block;
    }
  }
}

.datepicker__column--calendar {
  display: block;
  width: 280px;

  @media (min-width: $media-width-sm-min) {
    width: 230px;
  }

  &::before,
  &::after {
    display: none;
  }
}

.datepicker__toggle {
  position: absolute;
  z-index: 10;
  top: 13px;
  right: 20px;
  cursor: pointer;

  @media (min-width: $media-width-sm-min) {
    display: none;
  }
}

.datepicker__toggle-icon {
  @include reset-icon();

  width: 24px;
  height: 24px;
}

.datepicker__toggle-icon--expand {
  display: block;

  .datepicker__column.is-open & {
    display: none;
  }
}

.datepicker__toggle-icon--close {
  display: none;

  .datepicker__column.is-open & {
    display: block;
  }
}

.datepicker__options {
  display: none;
  width: 100%;
  height: 100%;
  padding: 25px 0 25px 10px;

  @media (min-width: $media-width-sm-min) {
    display: block;
    padding: 0 0 0 10px;
  }

  .datepicker__column.is-open & {
    display: block;
    height: 100%;
    background-color: $datepicker-bg;
    overflow-y: auto;
    z-index: 3;

    @media (min-width: $media-width-sm-min) {
      position: relative;
      height: auto;
    }
  }

  .scroll-fade__inner {
    padding: 10px 0;
  }

  .scroll-fade__content {
    padding-right: 10px;
    display: block;
  }
}

.datepicker__options-inner {
  max-height: 230px;
}

.datepicker__option {
  display: block;
  height: auto;
  margin: 2px 0;
  font-size: 14px;
  line-height: 34px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;

  .datepicker__column--align-left & {
    text-align: left;
  }

  @media (min-width: $media-width-sm-min) {
    font-size: 12px;
    line-height: 26px;

    .datepicker__option + & {
      margin-top: 4px;
    }
  }
}

.datepicker__option-inner {
  @include transition(
    (
      'animation': medium,
      'properties': (
        background-color,
        color,
      ),
    )
  );

  display: inline-block;
  padding: 0 20px;
  border-radius: 20px;
  font-variant-numeric: tabular-nums; // make numbers take equal space, so options and numbers in options align

  @media (min-width: $media-width-sm-min) {
    .datepicker__column--align-left & {
      display: inline-flex;
    }

    display: block;
    padding: 0 10px;

    html[data-whatintent='mouse'] .datepicker__option:not(.is-selected):hover & {
      color: map.get($datepicker-text-color, hover);
      background-color: map.get($datepicker-background, hover);
    }
  }

  .datepicker__option.is-current & {
    font-weight: 700;
  }

  .datepicker__option.is-selected & {
    font-weight: 700;
    color: map.get($datepicker-text-color, selected);
    background-color: map.get($datepicker-background, selected);
  }

  .datepicker__option.is-disabled & {
    color: $datepicker-day-disabled-color;
    cursor: default;

    html[data-whatintent='mouse'] &:hover {
      color: $datepicker-day-disabled-color;
      background-color: transparent;
    }
  }

  .datepicker__column--align-left &:not(.datepicker__option-inner--without-extra-content) {
    min-width: 100%;
  }
}

.datepicker__option-inner-time-column-single {
  min-width: 45px;

  @media (min-width: $media-width-sm-min) {
    min-width: 40px;
  }
}

.datepicker__option-inner-time-column-range {
  min-width: 95px;

  @media (min-width: $media-width-sm-min) {
    min-width: 82px;
  }
}

.datepicker__value {
  display: block;
  padding: 15px 40px;
  font-weight: 700;
  text-align: center;
  color: $datepicker-value-color;
  cursor: pointer;

  @media (min-width: $media-width-sm-min) {
    display: none;
    visibility: hidden;
  }

  .datepicker__column.is-open & {
    display: none;
  }
}

.datepicker__calendar {
  padding: 7px;

  @media (min-width: $media-width-sm-min) {
    padding: 10px;
  }
}

.datepicker__day-names {
  display: flex;
  flex-wrap: nowrap;
}

.datepicker__day-name {
  position: relative;
  display: inline-block;
  width: $datepicker-day-width;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }
}

.datepicker__days {
  display: flex;
  flex-wrap: wrap;
}

.datepicker__day {
  position: relative;
  width: $datepicker-day-width;
  z-index: 1;
  cursor: pointer;

  &.is-disabled {
    cursor: default;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }
}

.datepicker__day-name-inner,
.datepicker__day-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  font-size: 12px;
}

.datepicker__day-inner {
  @include transition(
    (
      'animation': medium,
      'properties': (
        background-color,
        color,
      ),
    )
  );

  border-radius: 100%;

  html[data-whatintent='mouse'] .datepicker__day:not(.is-selected):hover & {
    color: map.get($datepicker-text-color, hover);
    background-color: map.get($datepicker-background, hover);
  }

  .datepicker__day.is-current & {
    font-weight: 700;
  }

  .datepicker__day.is-selected & {
    font-weight: 700;
    color: map.get($datepicker-text-color, selected);
    background-color: map.get($datepicker-background, selected);
  }

  .datepicker__day.is-disabled &,
  html[data-whatintent='mouse'] .datepicker__day.is-disabled:hover & {
    color: $datepicker-day-disabled-color;
    background-color: transparent;
  }
}

.datepicker__buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  padding: 12px;
  background-color: var(--color-neutral-bg-level-4);
}

.datepicker__button {
  margin: 0 10px;
}

.datepicker__options-helper {
  position: absolute;
  z-index: 1;
  inset: 0;
  background: $datepicker-bg;
  display: flex;
  min-height: 100%;
  align-items: center;
  padding: 16px 8px;
}

.datepicker__spinner {
  .spinner__circle {
    display: block;
    margin: 0 auto;
    width: 24px;
    height: 24px;
  }

  .spinner__text {
    margin-top: 8px;
  }
}

.datepicker__notice {
  width: 100%;
}
