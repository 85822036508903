@import '@telia-front/sass-tokens/index.scss';

.pebble {
  position: relative;
  display: inline-block;
  width: 80px;
  max-width: 100%;
  background-repeat: no-repeat;
  background-image: url('@telia-front/static-assets/images/pebble.svg');
  background-size: 100% auto;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 105.36%;
  }
}

.pebble--medium {
  width: 120px;
}

.pebble--large {
  width: 240px;
}

.pebble--full {
  width: 100%;
}

.pebble--dark {
  background-image: url('@telia-front/static-assets/images/pebble-white.svg');
}

.pebble--stealth {
  background-image: url('@telia-front/static-assets/images/pebble-stealth.svg');
}
