@import '@telia-front/sass-tokens/index.scss';

$list-spacings: (none 0, xsmall 1px, small 2px, medium 4px, large 8px, xlarge 16px);
$list-border-color: var(--color-neutral-border-subtlest);

.list,
.list--small,
.list--large {
  --list-ver-gap: 0;
  --list-hor-gap: 0;

  color: var(--color-neutral-text);
  font-size: var(--type-font-size);
  line-height: inherit;
  font-weight: 400;
}

@each $name, $spacing in $list-spacings {
  .list--ver-spacing-#{$name} {
    --list-ver-gap: #{$spacing};
  }

  .list--hor-spacing-#{$name} {
    --list-hor-gap: #{$spacing};
  }
}

.list--xsmall {
  font-size: var(--type-small-font-size);
  line-height: var(--type-line-height);
}

.list--large {
  @media (min-width: $media-width-sm-min) {
    font-size: var(--type-h4-font-size);
    line-height: var(--type-h4-line-height);
  }

  @media (min-width: $media-width-md-min) {
    font-size: var(--type-h4-font-size);
    line-height: var(--type-h4-line-height);
  }

  @media (min-width: $media-width-xl-min) {
    font-size: var(--type-h4-xl-font-size);
    line-height: var(--type-h4-xl-line-height);
  }
}

.list--ordered {
  counter-reset: item;
}

.list__inner {
  @include list-unstyled();

  margin-top: calc(var(--list-ver-gap) / 2 * -1);
  margin-bottom: calc(var(--list-ver-gap) / 2 * -1);

  .list--direction-inline > &,
  .list--bordered > & {
    margin-top: 0;
    margin-bottom: 0;
  }

  .list--direction-inline > & {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: baseline;
    margin-inline: calc(var(--list-hor-gap) / 2 * -1);
    margin-block: calc(var(--list-ver-gap) / 2 * -1);
  }

  .list--bordered > & {
    overflow: hidden;
  }

  .list--ordered.list--number-size-huge > &,
  .list--ordered.list--number-size-small > & {
    @media (min-width: $media-width-lg-min) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0;
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

.list__item {
  position: relative;
  display: block;
  padding-left: 0;
  list-style: none;

  .list--icon-position-top.list--icon-size-xs > .list__inner > & {
    padding-top: 1px;
  }

  .list--icon-position-top.list--icon-size-sm > .list__inner > & {
    padding-top: 5px;
  }

  .list--icon-position-top.list--icon-size-md > .list__inner > & {
    padding-top: 9px;
  }

  .list--icon-position-top.list--icon-size-lg > .list__inner > & {
    padding-top: 10px;
  }

  .list--bordered:not(.list--direction-inline) > .list__inner > & {
    padding-block: var(--list-ver-gap);

    &:not(:last-child) {
      border-bottom: 1px solid $list-border-color;
    }
  }

  .list--outer-bordered > .list__inner > & {
    &:last-child {
      border-bottom: 1px solid $list-border-color;
    }

    &:first-child {
      border-top: 1px solid $list-border-color;
    }
  }

  .list--direction-inline.list--bordered > .list__inner > & {
    border-bottom: none;
    margin-left: -1px;
    border-left: 1px solid $list-border-color;

    &:first-child {
      border-top: none;
      border-left: none;
    }
  }

  .list--direction-inline > .list__inner > & {
    padding-inline: calc(var(--list-hor-gap) / 2);
    margin-block: calc(var(--list-ver-gap) / 2);
  }

  .list--ordered.list--number-size-huge > .list__inner > &,
  .list--ordered.list--number-size-small > .list__inner > & {
    @media (min-width: $media-width-lg-min) {
      flex: 1 0 20%;
      margin-top: 0;
      padding: 0 10px;
    }
  }

  .list--ordered.list--number-size-huge.list--no-gutter > .list__inner > &,
  .list--ordered.list--number-size-huge.list--no-horizontal-gutter > .list__inner > & .list--ordered.list--number-size-small.list--no-gutter > .list__inner > &,
  .list--ordered.list--number-size-small.list--no-horizontal-gutter > .list__inner > & {
    @media (min-width: $media-width-lg-min) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.list__item-inner {
  display: inline-flex;
  vertical-align: top;
  position: relative;
  padding-left: calc(0.5em + 6px);
  // When using columns with HTML list elements, ensure that the inner components have consistent top and bottom margins
  // to maintain correct line items. (safari)
  margin-top: calc(var(--list-ver-gap) / 2);
  margin-bottom: calc(var(--list-ver-gap) / 2);

  .list--xsmall > .list__inner > .list__item > & {
    padding-left: calc(0.5em + 4px);
  }

  .list--small > .list__inner > .list__item > & {
    padding-left: calc(0.5em + 8px);
  }

  .list--large > .list__inner > .list__item > & {
    padding-left: calc(0.5em + 16px);
  }

  .list--full-width > .list__inner > .list__item > & {
    width: 100%;
  }

  .list--icon-position-middle > .list__inner > .list__item > & {
    align-items: center;
  }

  .list--icon > .list__inner > .list__item > &,
  .list--unstyled > .list__inner > .list__item > &,
  .list--ordered > .list__inner > .list__item > &,
  .list--direction-inline > .list__inner > .list__item > & {
    padding-left: 0;
  }

  .list--direction-inline > .list__inner > .list__item > &,
  .list--bordered > .list__inner > .list__item > & {
    margin-top: 0;
    margin-bottom: 0;
  }

  &::before {
    content: '\00a0\00a0'; // non-breaking invisible characters used to get correct line-height and 2x for approximate bullet width
    position: absolute;
    clip-path: circle(3px at 50% 45%);
    background-color: currentcolor;
    top: 0;
    left: 0;

    .list--large > .list__inner > .list__item > & {
      @media (min-width: $media-width-sm-min) {
        clip-path: circle(4px at 50% 45%);
      }
    }

    .list--ordered > .list__inner > .list__item > & {
      position: static;
      content: counters(item, '.') '. ';
      counter-increment: item;
      background: transparent;
      border-radius: 0;
      top: 0;
      font-weight: 700;
      width: auto;
      height: auto;
      clip-path: none;
    }

    .list--ordered.list--xsmall > .list__inner > .list__item > & {
      margin-right: 0.2857em;
    }

    .list--ordered.list--small > .list__inner > .list__item > & {
      margin-right: 0.5em;
    }

    .list--ordered.list--large > .list__inner > .list__item > & {
      margin-right: 0.7273em;
    }

    .list--icon > .list__inner > .list__item > &,
    .list--unstyled > .list__inner > .list__item > &,
    .list--direction-inline > .list__inner > .list__item > & {
      display: none;
    }

    .list--ordered.list--number-size-huge > .list__inner > .list__item > &,
    .list--ordered.list--number-size-small > .list__inner > .list__item > & {
      font-family: var(--type-font-family-display);
      content: counter(item);
      font-size: 32px;
      line-height: 0.9;
      margin-right: 0.25em;
    }

    .list--ordered.list--number-size-huge > .list__inner > .list__item > & {
      @media (min-width: $media-width-lg-min) {
        font-size: 56px;
        margin-right: 0.1429em;
      }
    }

    .list--ordered.list--number-size-small > .list__inner > .list__item > & {
      @media (min-width: $media-width-lg-min) {
        font-size: 40px;
        margin-right: 0.2em;
      }
    }
  }
}

.list__icon {
  @include reset-icon();

  position: relative;
  flex: 0 0 auto;
  color: var(--color-neutral-graphic);

  .list--xsmall & {
    margin-right: 0.25em;
  }

  .list--small & {
    margin-right: 0.3333em;
  }

  .list--large & {
    margin-right: 0.5em;
  }

  .list--icon-position-top.list--icon-size-xs > .list__inner > .list__item > .list__item-inner > & {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .list--icon-position-top.list--icon-size-sm > .list__inner > .list__item > .list__item-inner > & {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .list--icon-position-top.list--icon-size-md > .list__inner > .list__item > .list__item-inner > & {
    margin-top: -9px;
    margin-bottom: -9px;
  }

  .list--icon-position-top.list--icon-size-lg > .list__inner > .list__item > .list__item-inner > & {
    margin-top: -10px;
    margin-bottom: -10px;
  }
}

.list__content {
  flex: 0 1 auto;
  align-self: center;
  text-align: left;
  width: 100%;

  .list--full-width > .list__inner > .list__item > .list__item-inner > & {
    flex-grow: 1;
  }

  .list--number-size-huge > .list__inner > .list__item > .list__item-inner > &,
  .list--number-size-small > .list__inner > .list__item > .list__item-inner > & {
    @media (min-width: $media-width-lg-min) {
      align-self: flex-start;
    }
  }
}
