@import '@telia-front/sass-tokens/index.scss';

$accordion-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);

.accordion {
  --accordion-border-color: var(--color-neutral-border-subtle);
  --accordion-title-color: var(--color-neutral-text);
  --accordion-title-hover-color: var(--color-brand-primary-text-hover);
  --accordion-title-open-color: var(--color-brand-primary-text);
  --accordion-title-inactive-color: var(--color-neutral-text-subtle);
  --accordion-arrow-color: var(--color-neutral-text);
  --accordion-arrow-hover-color: var(--color-brand-primary-text-hover);
  --accordion-arrow-open-color: var(--color-brand-primary-text);
  --accordion-icon-color: var(--accordion-arrow-color);
}

.accordion--bordered {
  border-top: 1px solid var(--accordion-border-color);
}

.accordion--full-width {
  .container > & {
    @media (max-width: $media-width-xs-max) {
      margin-left: -$container-padding;
      margin-right: -$container-padding;
    }
  }
}

.accordion__item {
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid var(--accordion-border-color);
  }

  .accordion--bordered > &:last-child {
    border-bottom: 1px solid var(--accordion-border-color);
  }

  .accordion--hide-header.accordion--large > & {
    display: flex;
  }
}

.accordion__item--transparent {
  background: transparent;
}

.accordion__item-inner {
  position: relative;
  width: 100%;
}

.accordion__heading {
  font-weight: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  min-width: 0;

  &:not(.h1, .h2, .h3, .h4, .h5, .h6, .p) {
    font-size: 16px;
    line-height: 1.375;
    font-weight: 700;

    .accordion--large & {
      font-weight: inherit;
    }
  }
}

.accordion__header {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .accordion__item--no-content & {
    cursor: default;
  }

  .accordion__item--employee > .accordion__item-inner > & {
    --accordion-title-color: var(--color-employee-text);
    --accordion-arrow-color: var(--color-employee-text);
    --accordion-title-hover-color: var(--color-employee-text-hover);
    --accordion-title-open-color: var(--color-employee-text);
    --accordion-arrow-hover-color: var(--color-employee-text-hover);
    --accordion-arrow-open-color: var(--color-employee-text);
    --accordion-icon-color: var(--accordion-arrow-color);
  }

  .accordion__item.is-inactive > .accordion__item-inner > & {
    --accordion-title-color: var(--accordion-title-inactive-color);
    --accordion-icon-color: var(--color-neutral-text-subtle);
  }

  .accordion--hide-header.accordion--large > .accordion__item > .accordion__item-inner > & {
    flex-direction: row;

    @media (min-width: $media-width-lg-min) {
      height: auto;
      transition: opacity 300ms $accordion-transition-timing-function;
      opacity: 1;
      flex-direction: column;
    }
  }

  .accordion--hide-header.accordion--large > .accordion__item.is-open > .accordion__item-inner > & {
    @media (min-width: $media-width-lg-min) {
      height: 0;
      opacity: 0;
    }
  }

  .accordion--hide-header.accordion--large > .accordion__item.is-animating > .accordion__item-inner & {
    @media (min-width: $media-width-lg-min) {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

.accordion__header-inner {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 56px;
  padding-left: 16px;
  min-height: 56px;

  @media (min-width: $media-width-sm-min) {
    padding-left: 16px;
    padding-right: 56px;
    min-height: 56px;
  }

  .accordion__item--icon > .accordion__item-inner > .accordion__header & {
    padding-left: 16px;
  }

  .accordion__item.is-open > .accordion__item-inner > .accordion__header > & {
    box-shadow: $elevation-0;
  }

  .accordion--large > .accordion__item > .accordion__item-inner > .accordion__header > & {
    @media (min-width: $media-width-sm-min) {
      min-height: 82px;
      padding-right: 64px;
    }
  }

  .accordion--large & {
    @media (min-width: $media-width-sm-min) {
      padding-left: 16px;
      gap: 16px;
    }
  }

  .accordion--hide-header.accordion--large > .accordion__item > .accordion__item-inner > .accordion__header > & {
    width: 100%;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    min-width: 0;
  }
}

.accordion__header-content-wrapper {
  position: relative;
  flex: 1 1 auto;
  min-width: 0;

  @media (min-width: $media-width-sm-min) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}

.accordion__arrow {
  @include transition(
    (
      'animation': medium,
      'properties': (
        color,
        transform,
      ),
    )
  );

  font-size: 32px;
  position: absolute;
  right: 8px;
  top: 50%;
  cursor: pointer;
  color: var(--accordion-arrow-color);
  transform: translateY(-50%);
  padding: 8px;
  line-height: 0;

  @media (min-width: $media-width-sm-min) {
    right: 8px;
    left: auto;
  }

  .accordion__item.is-open > .accordion__item-inner > .accordion__header & {
    transform: rotate(180deg) translateY(50%);
    color: var(--accordion-arrow-open-color);
  }

  .accordion__item--no-content > .accordion__item-inner > .accordion__header &,
  .accordion__item--no-content > & {
    display: none;
  }

  .accordion--large > .accordion__item > .accordion__item-inner > .accordion__header & {
    @media (min-width: $media-width-sm-min) {
      padding: 8px;
    }
  }

  html[data-whatintent='mouse'] .accordion__item:not(.accordion__item--no-content) > .accordion__item-inner > .accordion__header:hover & {
    color: var(--accordion-arrow-hover-color);
  }

  html[data-whatintent='mouse']
    .accordion--hide-header.accordion--large
    .accordion__item:not(.accordion__item--employee).is-hovered
    > .accordion__item-inner
    ~ &:hover {
    color: var(--color-brand-primary-text-hover);
  }

  .accordion--hide-header.accordion--large > .accordion__item > .accordion__item-inner > .accordion__header > &,
  .accordion--hide-header.accordion--large > .accordion__item > & {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    width: 58px;
    padding: $base-horizontal-spacing-xs;
    transition: background 300ms $accordion-transition-timing-function;
    background-color: $grey-l;

    @media (min-width: $media-width-lg-min) {
      padding: $base-horizontal-spacing;
    }
  }
}

.accordion__arrow-icon {
  .accordion--hide-header.accordion--large > .accordion__item > .accordion__arrow > &,
  .accordion--hide-header.accordion--large > .accordion__item > .accordion__item-inner > .accordion__header > .accordion__arrow > & {
    margin-left: -8px;
    margin-top: 30px;
    transform: translateY(-50%);
    transition:
      color 300ms $accordion-transition-timing-function,
      transform 300ms $accordion-transition-timing-function;

    @media (min-width: $media-width-sm-min) {
      margin-left: -12px;
    }

    @media (min-width: $media-width-lg-min) {
      margin-top: 34px;
      margin-left: -7px;
    }
  }

  .accordion--hide-header.accordion--large > .accordion__item.is-open > .accordion__arrow > &,
  .accordion--hide-header.accordion--large > .accordion__item.is-open > .accordion__item-inner > .accordion__header > .accordion__arrow > & {
    transform: rotate(180deg) translateY(50%);
    color: var(--color-brand-primary-text);
  }
}

.accordion__title {
  @include reset-button();
  @include transition(
    (
      'animation': short,
      'properties': color,
    )
  );

  color: var(--accordion-title-color);
  max-width: 100%;
  font-weight: inherit;
  line-height: inherit;

  .accordion__item.is-open > .accordion__item-inner > .accordion__header & {
    color: var(--accordion-title-open-color);
  }

  html[data-whatintent='mouse'] .accordion__item:not(.accordion__item--no-content) > .accordion__item-inner > .accordion__header:hover & {
    color: var(--accordion-arrow-hover-color);
  }

  .accordion__item--no-content:not(.is-inactive) > .accordion__item-inner > .accordion__header & {
    cursor: auto;
  }
}

.accordion__body {
  @include transition(
    (
      'animation': medium,
      'properties': height,
    )
  );

  .accordion__item.is-open > .accordion__item-inner > & {
    box-shadow: $elevation-0;
  }
}

.accordion__content {
  padding: 0 16px 16px;

  @media (min-width: $media-width-sm-min) {
    padding: 0 16px 16px;
  }

  .accordion--large.accordion--hide-header & {
    padding-right: $base-horizontal-spacing-xs;

    @media (min-width: $media-width-sm-min) {
      padding-right: $base-horizontal-spacing;
    }

    @media (min-width: $media-width-lg-min) {
      min-height: 100px;
      padding-top: 27px;
      transition: opacity 300ms $accordion-transition-timing-function;
      opacity: 0;
    }

    @media (min-width: $media-width-xl-min) {
      padding-top: 26px;
    }
  }

  .accordion--hide-header.accordion--large > .accordion__item.is-open & {
    @media (min-width: $media-width-lg-min) {
      opacity: 1;
    }
  }
}

.accordion__icon-wrapper,
.accordion__image-wrapper {
  z-index: 1;
  line-height: 0;
  flex-shrink: 0;
}

.accordion__icon {
  @include reset-icon();
  @include transition(
    (
      'animation': medium,
      'properties': color,
    )
  );

  font-size: 32px;
  color: var(--accordion-icon-color);

  .accordion__item.is-open > .accordion__item-inner > .accordion__header & {
    color: var(--accordion-arrow-open-color);
  }

  .accordion--large > .accordion__item > .accordion__item-inner > .accordion__header & {
    @media (min-width: $media-width-sm-min) {
      font-size: 48px;
    }
  }

  html[data-whatintent='mouse'] .accordion__item:not(.accordion__item--no-content) > .accordion__item-inner > .accordion__header:hover & {
    color: var(--accordion-arrow-hover-color);
  }
}

.accordion__image {
  width: 32px;

  .accordion--large > .accordion__item > .accordion__item-inner > .accordion__header & {
    @media (min-width: $media-width-sm-min) {
      width: 48px;
    }
  }
}

.accordion__meta {
  padding: 8px 8px 0 0;

  @media (min-width: $media-width-sm-min) {
    padding: 0 8px 0 0;
  }
}

.accordion__check {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border: 2px solid var(--color-neutral-border-subtlest);
  border-radius: 50%;

  .accordion__item.is-checked > .accordion__item-inner > .accordion__header & {
    border: none;
  }

  @media (min-width: $media-width-sm-min) {
    width: 32px;
    height: 32px;
  }
}

.accordion__check-icon {
  display: none;
  font-size: 24px;
  color: var(--color-success-graphic);

  .accordion__item.is-checked > .accordion__item-inner > .accordion__header & {
    display: block;
  }

  @media (min-width: $media-width-sm-min) {
    font-size: 32px;
  }
}

.accordion--type-card {
  border: 0;

  .accordion__item {
    border: 0;
    margin: 0 0 2px;

    &:last-child {
      margin: 0;
      border: 0;

      .accordion__header,
      .accordion__border {
        border-radius: 0 0 $card-border-radius-medium $card-border-radius-medium;
        transition: border-radius 300ms $accordion-transition-timing-function;
      }

      &.is-open {
        .accordion__header {
          border-radius: 0;
          transition: border-radius 300ms $accordion-transition-timing-function;
        }
      }
    }

    &:first-child {
      .accordion__header {
        border-radius: $card-border-radius-medium $card-border-radius-medium 0 0;
      }
    }

    &:only-child {
      .accordion__header {
        border-radius: $card-border-radius-medium;
        transition: border-radius 300ms $accordion-transition-timing-function;
      }

      &.is-open {
        .accordion__header-inner {
          border-radius: $card-border-radius-medium $card-border-radius-medium 0 0;
          transition: border-radius 300ms $accordion-transition-timing-function;
        }
      }

      .accordion__border {
        border-radius: 0 0 $card-border-radius-medium $card-border-radius-medium;
      }
    }

    &.is-open {
      .accordion__header-inner::before {
        opacity: 1;
      }

      .accordion__border {
        border: 1px solid var(--color-neutral-border-subtlest);
        border-top: 0;
      }
    }

    &.is-animating .accordion__border {
      border: 1px solid var(--color-neutral-border-subtlest);
      border-top: 0;
    }
  }

  .accordion__header {
    background-color: var(--color-neutral-bg-level-2);
    transition: background-color 300ms $accordion-transition-timing-function;
  }

  .accordion__header-inner::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--color-neutral-bg-subtlest);
    opacity: 0;
    transition: opacity 300ms $accordion-transition-timing-function;
  }

  .accordion__content {
    padding: 24px;
  }
}
