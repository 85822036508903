@use 'sass:map';

@import '@telia-front/sass-tokens/index.scss';

.affix {
  @supports (position: sticky) {
    position: sticky;
    top: 60px;
    z-index: map.get($zindex, affix);
    transition: top 500ms cubic-bezier(0.86, 0, 0.07, 1);
    will-change: top;

    @media (min-width: $media-width-lg-min) {
      top: $header-scrolled-height;
    }
  }
}

.affix--static {
  position: static;
}
