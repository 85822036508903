@import '@telia-front/sass-tokens/index.scss';

$tabs-nav-link-icon-size: 32px;
$tabs-nav-link-icon-top-size: 40px;
$tabs-selected-border-color: var(--color-brand-primary-border);
$tabs-employee-border-color: var(--color-employee-text);
$tabs-huge-background-color: var(--color-neutral-bg-level-2);
$tabs-huge-background-color-current: var(--color-neutral-bg-level-1);
$tabs-huge-border-color: var(--color-neutral-border-subtle);
$tabs-huge-employee-border-color: var(--color-employee-border);
$tabs-light-color: var(--color-neutral-bg-level-1);

.tabs-navigation {
  --tabs-navigation-margin: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin-left: calc(var(--tabs-navigation-margin) * -1);
  margin-right: calc(var(--tabs-navigation-margin) * -1);
  width: calc(100% + (var(--tabs-navigation-margin) * 2));

  @media (min-width: $media-width-sm-min) {
    --tabs-navigation-margin: 15px;
  }
}

.tabs-navigation--bordered {
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: var(--tabs-navigation-margin);
    right: var(--tabs-navigation-margin);
    height: 2px;
    background-color: var(--color-neutral-border-subtlest);
  }
}

.tabs-navigation--background-light {
  --tabs-navigation-margin: 0;

  background-color: $tabs-light-color;
  border-radius: 3px 3px 0 0;
  padding: 2px 5px 0;

  @media (min-width: $media-width-sm-min) {
    padding-right: 40px;
    padding-left: 40px;
  }

  &::after {
    display: none;
  }
}

.tabs-navigation--huge {
  --tabs-navigation-margin: 0;

  white-space: normal;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0;

  &::after {
    display: block;
    height: 1px;
    background-color: $tabs-huge-border-color;
  }

  &.tabs-navigation--background-light {
    background-color: $tabs-huge-background-color;
  }
}

.tabs-navigation__scroll {
  width: 100%;
  z-index: 1;
}

.tabs-navigation__scroll-inner {
  // Needed to keep the animated underline in the correct position when scrollbar is visible.
  position: relative;

  .tabs-navigation--center & {
    display: flex;
  }
}

.tabs-navigation__inner {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 0;
  margin-right: 0;
  padding: 0;

  .tabs-navigation--center & {
    margin-left: auto;
    margin-right: auto;
  }

  .tabs-navigation--huge & {
    width: 100%;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 4px;
  }
}

.tabs-navigation__item {
  display: inline-flex;
  text-align: center;
  flex-shrink: 0;

  .tabs-navigation--icons-top & {
    align-items: flex-end;
  }

  .tabs-navigation--huge & {
    background-color: $tabs-huge-background-color;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    border-top: 2px solid transparent;
    border-radius: 4px 4px 0 0;
    transition: all 120ms cubic-bezier(0.2, 0, 0.31, 1);
    border-bottom: 1px solid $tabs-huge-border-color;

    &.is-current {
      background-color: $tabs-huge-background-color-current;
      border-top: 2px solid $tabs-selected-border-color;
      border-radius: 0;
      transition:
        border 1ms 290ms linear,
        background-color 1ms 290ms linear;
      border-right: 1px solid $tabs-huge-border-color;
      border-left: 1px solid $tabs-huge-border-color;
      border-bottom-color: transparent;

      &:first-child {
        border-left: 1px solid $tabs-huge-border-color;
      }

      &:last-child {
        border-right: 1px solid $tabs-huge-border-color;
      }
    }

    &.tabs-navigation__item--employee.is-current {
      border-top: 2px solid $tabs-huge-employee-border-color;
    }

    &.is-current + & {
      &::before {
        display: none;
      }
    }
  }

  .tabs-navigation--huge.tabs-navigation--full-width & {
    &.is-current {
      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .tabs-navigation--huge > .tabs-navigation__underline.is-animating ~ .tabs-navigation__inner > &.is-current {
    background-color: transparent;
  }

  .tabs-navigation--background-light & {
    background-color: $tabs-light-color;

    &.is-current {
      background-color: var(--color-neutral-bg-level-2);
    }
  }
}

.tabs-navigation__link {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 400;
  color: var(--color-neutral-text);
  z-index: 2;
  outline-offset: -3px;
  padding-bottom: 16px;
  padding-left: var(--tabs-navigation-margin);
  padding-right: var(--tabs-navigation-margin);
  gap: 4px;
  transition: color 0.15s ease;

  html[data-whatintent='mouse'] &:hover {
    color: var(--color-brand-primary-text-hover);
  }

  .tabs-navigation__item.is-current & {
    color: var(--color-brand-primary-text);
  }

  .tabs-navigation__item--employee & {
    color: var(--color-employee-text);

    html[data-whatintent='mouse'] &:hover {
      color: var(--color-employee-text-hover);
    }
  }

  .tabs-navigation__item--employee.is-current & {
    color: var(--color-employee-text);
  }

  .tabs-navigation--icons-top & {
    flex-direction: column;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: var(--tabs-navigation-margin);
    right: var(--tabs-navigation-margin);

    .tabs-navigation__item.is-current & {
      height: 2px;
      background: $tabs-selected-border-color;
    }

    .tabs-navigation__item--employee.is-current & {
      background: $tabs-employee-border-color;
    }

    .tabs-navigation__underline.is-animating ~ .tabs-navigation__inner > .tabs-navigation__item > & {
      opacity: 0;
    }
  }

  .tabs-navigation--huge.tabs-navigation--background-light .tabs-navigation__item &,
  .tabs-navigation--huge .tabs-navigation__item & {
    justify-content: center;
    border: none;
    padding: 15px 12px;
    margin: 0;
    width: 100%;
    font-size: inherit;
    font-weight: 400;
    transition: color 120ms cubic-bezier(0.2, 0, 0.31, 1);

    @media (min-width: $media-width-sm-min) {
      padding: 16px 18px 18px;
    }

    &::after {
      content: none;
    }
  }

  .tabs-navigation--background-light .tabs-navigation__item & {
    height: 100%;
    padding: 10px 14px 13px;
    margin: 0;
    font-size: 14px;
    line-height: 1.43;
    font-weight: 700;

    &::after {
      content: none;
    }
  }

  .tabs-navigation--background-light .tabs-navigation__item--icon & {
    padding-left: 15px;
  }

  @media (min-width: $media-width-sm-min) {
    gap: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: var(--type-h4-xl-font-size);
    line-height: var(--type-h4-xl-line-height);
  }
}

.tabs-navigation__item-icon {
  @include reset-icon();

  position: relative;
  font-size: $tabs-nav-link-icon-size;
  flex-shrink: 0;

  &.tabs-navigation__item-icon-image {
    width: $tabs-nav-link-icon-size;

    .tabs-navigation--icons-top & {
      width: $tabs-nav-link-icon-top-size;
    }
  }

  .tabs-navigation--icons-top & {
    font-size: $tabs-nav-link-icon-top-size;
    margin: 0 auto;
  }

  .tabs-navigation__link & {
    .tabs-navigation__item:not(.tabs-navigation__item--employee) & {
      color: var(--color-neutral-graphic);
    }

    .tabs-navigation__item:not(.tabs-navigation__item--employee).is-current & {
      color: inherit;
    }
  }

  .tabs-navigation__link:hover & {
    html[data-whatintent='mouse'] .tabs-navigation__item:not(.tabs-navigation__item--employee) &,
    html[data-whatintent='mouse'] .tabs-navigation__item:not(.tabs-navigation__item--employee).is-current & {
      color: inherit;
    }
  }
}

.tabs-navigation__underline {
  opacity: 0;
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 2px;
  max-width: 0;
  margin: 0 10px;
  background-color: $tabs-selected-border-color;
  transition:
    max-width 300ms cubic-bezier(0.2, 0, 0.31, 1),
    left 300ms cubic-bezier(0.2, 0, 0.31, 1),
    background-color 300ms cubic-bezier(0.2, 0, 0.31, 1);

  &.is-animating {
    opacity: 1;
  }

  .tabs-navigation--background-light & {
    display: none;
  }

  .tabs-navigation--huge.tabs-navigation--background-light & {
    background-color: $tabs-huge-background-color;
    display: unset;
  }

  .tabs-navigation--huge & {
    height: 100%;
    margin: 0;
    background-color: $tabs-huge-background-color-current;
    border-right: 1px solid $tabs-huge-border-color;
    border-left: 1px solid $tabs-huge-border-color;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 2px;
      background-color: $tabs-selected-border-color;
      pointer-events: none;
      content: '';
    }

    &.tabs-navigation__underline--type-employee::before {
      background-color: $tabs-huge-employee-border-color;
    }
  }

  @media (min-width: $media-width-sm-min) {
    margin: 0 15px;
  }
}

.tabs-navigation__underline--type-employee {
  background-color: $tabs-employee-border-color;
}

.tabs-navigation__item-content {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
}

.tabs-navigation__item-tag {
  margin-left: 4px;
}
