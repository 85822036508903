@import '@telia-front/sass-tokens/index.scss';

.skip-links {
  height: 0;
  overflow: hidden;
  padding: 0;

  &:focus-within {
    border-bottom: 1px solid var(--color-neutral-border-subtlest);
    background-color: var(--color-neutral-bg-level-2);
    height: auto;
    overflow: auto;
    padding: 10px 0;
  }
}

.skip-links__anchor {
  font-size: var(--type-tiny-font-size);
  line-height: var(--type-tiny-line-height);
}
