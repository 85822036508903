@use 'sass:map';

@import '@telia-front/sass-tokens/index.scss';

.filter__heading {
  margin-bottom: 16px;
}

.filter__footer {
  text-align: center;
  padding: 16px 16px 0;
  background: none;
}

.filter__save {
  @media (min-width: $media-width-md-min) {
    display: none;
  }
}

.filter__content {
  padding-bottom: 16px;
}
