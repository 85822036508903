@import '@telia-front/sass-tokens/index.scss';

$filefield-border-width: 1px;

.filefield {
  --filefield-border-color: var(--color-neutral-border);
  --filefield-shadow-size: 0px;
  --filefield-horizontal-padding: 12px;
  --filefield-horizontal-inner-padding: calc(var(--filefield-horizontal-padding) - #{$filefield-border-width});

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  width: 100%;
  max-width: 100%;

  &.is-focused {
    z-index: 2;
  }
}

.filefield--employee {
  --filefield-border-color: var(--color-employee-border);
}

.filefield.is-invalid {
  --filefield-shadow-size: 1px;
  --filefield-border-color: var(--color-error-border);
}

.filefield.is-disabled {
  --filefield-border-color: var(--color-disabled-border);

  color: var(--color-disabled-text);
}

.filefield__inner {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-end;
  flex-wrap: wrap;
}

.filefield__input {
  @include transition(
    (
      'animation': short,
      'properties': (
        border-color,
        box-shadow,
      ),
    )
  );

  position: relative;
  border: $filefield-border-width solid var(--filefield-border-color);
  border-radius: 4px;
  box-shadow: inset 0 0 0 var(--filefield-shadow-size) var(--filefield-border-color);
  display: block;
  font-size: var(--type-font-size);
  line-height: var(--type-line-height);
  font-weight: 400;
  margin: 0;
  padding: 10px var(--filefield-horizontal-inner-padding);
  flex-grow: 1;
  background: transparent;
  text-align: left;

  // Needed for when isFocused is controlled externally.
  .filefield.is-focused & {
    @include keyboard-focus();
  }

  .filefield.is-disabled & {
    background-color: transparent;
    color: var(--color-disabled-text);
    outline: none;
  }
}

.filefield__input-field {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;

  .filefield.is-disabled & {
    cursor: default;
  }
}

.filefield__label {
  @include transition(
    (
      'animation': short,
      'properties': color,
    )
  );

  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--color-neutral-text);
  font-size: var(--type-small-font-size);
  line-height: var(--type-small-line-height);
  flex-direction: row;
  text-align: left;

  .filefield.is-disabled & {
    color: var(--color-disabled-text);
  }

  .filefield--no-label-no-break & {
    @include visually-hidden();
  }

  .filefield--no-label-sm & {
    @media (min-width: $media-width-sm-min) {
      @include visually-hidden();
    }
  }

  .filefield--no-label-md & {
    @media (min-width: $media-width-md-min) {
      @include visually-hidden();
    }
  }
}

.filefield__label-icon {
  font-size: 1rem;
  color: var(--color-employee-graphic);
}

.filefield__files {
  pointer-events: none;
  min-height: calc(var(--type-font-size) * var(--type-line-height) + 4px);
}

.filefield__file {
  pointer-events: auto;
}
