@import '@telia-front/sass-tokens/index.scss';

.main {
  display: flex;
  flex-direction: column;
}

.main__inner {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main__header {
  padding-top: 100px;

  @media (min-width: $media-width-lg-min) {
    padding-top: 110px;
  }
}

.main__content {
  padding: 30px 0 100px;
  flex: 1 0 auto;
}

.main__page {
  flex: 1 0 auto;
}

.main__footer {
  flex-shrink: 0;
}

.main__page--with-background {
  position: relative;
}

.main__page--background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.main__page--background-image {
  display: block;
  width: 100%;
  height: 100%;
}

.main__page--background-picture {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
