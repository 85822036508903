@use 'sass:map';

@import '@telia-front/sass-tokens/index.scss';

.dropdown {
  position: absolute;
  max-width: calc(100vw - 20px);
  z-index: map.get($zindex, suggestion);
}

.dropdown--width-default {
  width: 320px;
}

.dropdown__suggestion {
  max-height: inherit;
  transform: translateY(0);
  opacity: 1;

  .dropdown.is-open &,
  .dropdown.is-close & {
    @include transition(
      (
        'animation': short,
        'properties': (
          transform,
          opacity,
        ),
      )
    );
  }

  .dropdown.is-initial &,
  .dropdown.is-close & {
    transform: translateY(-10px);
    opacity: 0;
  }
}
