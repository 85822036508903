@import '@telia-front/sass-tokens/index.scss';

$search-button-size: 1.5rem;

.search {
  position: relative;
}

.textfield__input.search__input {
  padding-right: calc(8px + $search-button-size + var(--textfield-horizontal-inner-padding));

  .textfield--with-icon & {
    padding-right: calc(8px + var(--textfield-icon-size) + $search-button-size + var(--textfield-horizontal-inner-padding));
  }
}

.search__textfield {
  .textfield__clear-btn {
    right: calc($search-button-size + var(--textfield-horizontal-padding) + 4px);

    .search--small & {
      right: calc($search-button-size + 4px);
    }
  }
}

.search__action {
  position: absolute;
  right: var(--textfield-horizontal-padding);
  bottom: 50%;
  transform: translateY(50%);
  width: $search-button-size;
  height: $search-button-size;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  z-index: 2;

  html[data-whatintent='mouse'] &:hover {
    color: var(--color-brand-primary-text-hover);
  }

  &:disabled {
    color: var(--color-disabled-text);
  }

  .search--small & {
    right: 4px;
  }
}

.search__action-icon {
  @include reset-icon();

  display: block;
  font-size: var(--textfield-icon-size);
}
