@use 'sass:map';

@import '@telia-front/sass-tokens/index.scss';

$suggestion-link-padding: 8px 16px;

.suggestion {
  @include list-unstyled();

  --suggestion-color: var(--color-neutral-text);
  --suggestion-hover-color: var(--color-brand-primary-text-hover);

  background: var(--color-neutral-bg-level-3);
  border-radius: 4px;
  box-shadow: $elevation-5;
  text-align: left;
  max-height: 400px; // height of 10 items
  overflow: auto;
  position: relative;
  z-index: map.get($zindex, suggestion);
}

.suggestion__item {
  position: relative;
  margin-top: -1px;

  &::after,
  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    margin-left: auto;
    margin-right: auto;
    left: 10px;
    right: 10px;
    background-color: var(--color-neutral-border-subtle);
    z-index: 0;
  }

  &::after {
    bottom: 0;
    display: none;
  }

  &::before {
    top: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:first-child::before {
    display: none;

    .suggestion__group-list & {
      display: block;
    }
  }

  &.suggestion__item--grouped {
    &:not(:first-child)::before {
      display: none;
    }
  }

  .suggestion__group-list > &::after {
    display: none;
  }

  &.suggestion__item--additional-content {
    padding-top: 10px;
  }
}

.suggestion__group-list {
  @include list-unstyled();
}

.suggestion__link {
  display: flex;
  align-items: center;
  padding: $suggestion-link-padding;
  color: var(--suggestion-color);
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-weight: 400;
  transition: color $transition-default;

  html[data-whatintent='mouse'] &:hover,
  &:focus-visible,
  .suggestion__item.is-focused &,
  .suggestion__item.is-focused.is-active & {
    color: var(--suggestion-hover-color);

    background-color: var(--color-neutral-bg-level-4);
  }

  &:focus-visible,
  .suggestion__item.is-focused & {
    @include keyboard-focus();

    outline-offset: -2px;
  }

  .suggestion__item.is-active & {
    --suggestion-color: var(--color-brand-primary-text);

    font-weight: 700;
  }

  .suggestion__item--employee &,
  .suggestion__item--employee.is-active & {
    --suggestion-color: var(--color-employee-text);
    --suggestion-hover-color: var(--color-employee-text-hover);
  }
}

.suggestion__icon {
  @include reset-icon();

  font-size: 1.5rem;
  margin-right: 8px;
  flex-shrink: 0;
}

.suggestion__image {
  width: 1.5rem;
  margin-right: 8px;
  flex-shrink: 0;
}

.suggestion__sub-label {
  display: block;
  font-size: var(--type-tiny-font-size);
  font-weight: 700;
  color: var(--color-neutral-text-subtle);
  line-height: var(--type-tiny-line-height);
}

.suggestion__spinner {
  padding: $suggestion-link-padding;

  & .spinner__circle {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.suggestion__label-mark {
  background: none;
  color: inherit;
  font-weight: 700;
}

.suggestion__group-label {
  padding: 16px 16px 4px;
  font-size: var(--type-tiny-font-size);
  color: var(--color-neutral-text-subtle);
  line-height: var(--type-tiny-line-height);
}
