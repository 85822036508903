@import '@telia-front/sass-tokens/index.scss';

.collapse-layer {
  *:not(.collapse-layer-grid__dropdown) > & {
    width: 0;
  }

  .collapse-layer-grid__dropdown > & {
    margin-top: 0;

    &.is-entered,
    &.is-entering {
      margin-bottom: $grid-gutter;
    }
  }
}

.collapse-layer-grid__dropdown {
  &.grid__col {
    margin-bottom: 0;
  }

  .collapse-layer-grid.grid--equalheight > & {
    display: block;
  }
}
