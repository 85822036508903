@import '@telia-front/sass-tokens/index.scss';

.product-detail-card-header {
  position: relative;
  z-index: 1;
  background: $white;
}

.product-detail-card-header__tags {
  margin-top: -35px;
  margin-bottom: -35px;

  @media (min-width: $media-width-sm-min) {
    margin-top: -40px;
    margin-bottom: -40px;
  }
}
